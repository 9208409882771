.grid {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 0;
    pointer-events: none;
    @include breakpoint(map-get($mediaqueries, small-down)) {
        display: none;
    }
}

.grid-background {
    height: 100%;
    width: calc((12.5% - 10px) * 3);
    position: absolute;
    overflow: hidden;
    &:nth-child(1) {
        left: calc((12.5% - 10px) * 1 + 40px);
    }
    &:nth-child(2) {
        left: calc((12.5% - 10px) * 3 + 40px);
    }
    &:nth-child(3) {
        left: calc((12.5% - 10px) * 5 + 40px);
    }
    @include breakpoint(map-get($mediaqueries, medium-down)) {
        width: calc((25% - 20px) * 2);
        &:nth-child(1) {
            left: 40px;
        }
        &:nth-child(2) {
            left: calc((25% - 20px) * 1 + 40px);
        }
        &:nth-child(3) {
            left: calc((25% - 20px) * 2 + 40px);
        }
    }
    > div {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-position: 0% 50%;
        background-size: cover;
        transform: translateX(-100%) scale(1);
        opacity: 0;
        clip-path: polygon(0% 0%, 0% 0%, 50% 100%, 0% 100%);
        transition: 1s ease-out;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: rgba(0,0,0,.5);
        }
    }
    &.active > div {
        background-position: 50% 50%;
        transform: translateX(0) scale(1.1);
        opacity: 1;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
}

.grid-line {
    height: 100%;
    display: inline-block;
    width: calc(12.5% - 10px);
    position: relative;
    border-left: 1px solid map-get($colors, "darkgrey");
    transition: border-color .25s ease-out;
    .light & {
        border-left: 1px solid map-get($colors, "lightgrey");
    }
    .color & {
        border-left: 1px solid map-get($colors, "corporate");
    }
    .grey & {
        border-left: 1px solid map-get($colors, "middlegrey");
    }
    @include breakpoint(map-get($mediaqueries, medium-down)) {
        width: calc(25% - 20px);
        &:nth-child(2n) {
            display: none;
        }
        &:last-child {
            display: inline-block;
        }
    }
}

.grid-line:first-child {
    width: 40px;
    border: none;
}

.grid-line:last-child {
    width: 40px;
}

.grid-info {
    @include navstyle();
    position: absolute;
    width: 150px;
    &.grid-info--middle {
        bottom: 28px;
        left: calc(50% - 75px);
        text-align: center;
        a {
            animation: bounce .6s ease-in-out infinite alternate;
        }
        &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: calc(100% + 3px);
            width: 1px;
            height: 25px;
            background: map-get($colors, "corporate");
        }
        .color & {
            &:after {
                background: map-get($colors, "darkgrey");
            }
        }
    }
    &.grid-info--right {
        bottom: 18px;
        right: 40px;
        width: auto;
        text-align: right;
        @include breakpoint(map-get($mediaqueries, small-down)) {
            right: 20px;
            bottom: auto;
            top: 20px;
        }
    }
    &.grid-info--left {
        bottom: 18px;
        left: 40px;
        width: auto;
        text-align: left;
        @include breakpoint(map-get($mediaqueries, small-down)) {
            left: 20px;
            bottom: auto;
            top: 20px;
        }
    }
    &.grid-info--title {
        top: 75px;
        right: 40px;
        text-align: right;
        transform-origin: right bottom;
        transform: rotate(-90deg);
        a[href] {
            color: map-get($colors, "corporate");
            .color & {
            color: map-get($colors, "darkgrey");
            }
        }
        @include breakpoint(map-get($mediaqueries, medium-down)) {
            right: 0;
        }
        @include breakpoint(map-get($mediaqueries, small-down)) {
            top: 50px;
            right: auto;
            left: 20px;
            transform: none;
            text-align: left;
        }
        @include breakpoint(map-get($mediaqueries, xlarge-up)) {
            top: 100px;
        }
    }
    a {
        display: inline-block;
        font-weight: normal;
        color: map-get($colors, "grey");
        transition: all .35s ease-out;
        .color & {
            color: map-get($colors, "darkgrey");
            font-weight: bold;
        }
        &:hover {
            color: white;
        }
    }
}

@keyframes bounce {
    from {transform: translateY(-10px);}
    to {transform: translateY(0);}
}