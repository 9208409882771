.u-flex-vcenter {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-around;
}
.u-flex-hcenter {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}
.u-flex-stretch {
    align-items: stretch;
}
.u-text-center {
	text-align: center;
}
.u-text-left {
	text-align: left;
}
.u-text-right {
	text-align: right;
}
.u-text-justify {
	text-align: justify;
	@include breakpoint(map-get($mediaqueries, medium-down)) {
		text-align: left;
	}
}
.u-nomargin {
	margin: 0 !important;
}
.u-nomargin-top {
	margin-top: 0 !important;
}
.u-nomargin-bottom {
	margin-bottom: 0 !important;
}
.u-nomargin-left {
	margin-left: 0 !important;
}
.u-nomargin-right {
	margin-right: 0 !important;
}
.u-nopadding {
	padding: 0 !important;
}
.u-nopadding-top {
	padding-top: 0 !important;
}
.u-nopadding-bottom {
	padding-bottom: 0 !important;
}
.u-nopadding-left {
	padding-left: 0 !important;
}
.u-nopadding-right {
	padding-right: 0 !important;
}
.u-video {
	position: relative;
	width: 100%;
    padding-bottom: 56.25%;
    iframe {
    	position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
    }
}

@each $key, $value in $breakpoints {
	.u-hide-#{$key} {		
		@include breakpoint(map-get($mediaqueries, #{$key}-only)) {
	        display: none;
		}
	}
	.u-hide-#{$key}-down {		
		@include breakpoint(map-get($mediaqueries, #{$key}-down)) {
	        display: none;
		}
	}
	.u-hide-#{$key}-up {		
		@include breakpoint(map-get($mediaqueries, #{$key}-up)) {
	        display: none;
		}
	}
}
