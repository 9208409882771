.doc {
    position: relative;
    width: 100%;
    max-width: calc(900px + (100% / 3));
    padding: 0 40px 50px calc((100% / 3) + 40px);
    margin: 0 auto;
    @include breakpoint(map-get($mediaqueries, small-down)) {
        padding: 50px 20px;
    }
    @include breakpoint(map-get($mediaqueries, xlarge-up)) {
        max-width: calc(900px + 600px);
        padding: 0 40px 50px calc(600px + 40px);
    }
    .logo {
        position: fixed;
        top: 20px;
        left: 20px;
        z-index: 9;
        display: none;
        width: 150px;
        height: 30px;
        @include breakpoint(map-get($mediaqueries, small-down)) {
            display: block;
        }
        .logo-primcolor {
            fill: map-get($colors, "corporate");
        }
        .logo-seccolor {
            fill: map-get($colors, "white");
        }
    }
    figure {
        margin: 50px 0;
        text-align: center;
        @include breakpoint(map-get($mediaqueries, medium-down)) {
            margin: 25px 0;
        }
    }
    img {
        display: inline-block;
        width: auto;
        max-width: auto;
        max-height: calc(80vh - 100px);
        box-shadow: 0 0 3px rgba(0,0,0,.5);
        @include breakpoint(map-get($mediaqueries, medium-down)) {
            max-height: calc(80vh - 70px);
        }
    }
    pre {
        width: 100%;
        overflow-x: auto;
        margin: 1em 0px;
        padding: 8px;
        background: map-get($colors, "lightgrey");
    }
    code {
        width: 100%;
        background: map-get($colors, "lightgrey");
        color: map-get($colors, "corporate");
        padding: 1px 4px;
        border-radius: 3px;
        font-family: "Roboto Mono", monospace;
        font-size: 16px;
    }
    .table-wrapper {
        width: 100%;
        margin: 1em 0px;
        overflow-x: auto;
    }
    table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid map-get($colors, "lightgrey");
    }
    th, td {
        padding: 8px;
        line-height: 22px;
        border: 1px solid map-get($colors, "lightgrey");
    }
    th {
        @include navstyle();
        background: map-get($colors, "corporate");
        color: white;
        text-align: left;
    }
    blockquote {
        background: map-get($colors, "corporate");
        color: white;
        margin: 1em 0px;
        padding: 25px;
    }
    h4 {
        margin-top: 2em;
    }
    h4 + blockquote {
        margin-top: -0.875rem;
    }
}
.video {
    position: relative;
    width: 100%;
    max-width: calc((80vh - 100px) / 0.5625);
    margin: 0 auto;
    background: rgba(0,0,0,.07);
    border: 10px solid map-get($colors, "corporate");
    .container-wrapper--color & {
        background: rgba(255,255,255,.5);
        border-color: white;
    }
    @include breakpoint(map-get($mediaqueries, medium-down)) {
        max-width: calc((80vh - 70px) / 0.5625);
    }
}
.video-wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    pointer-events: none;
    z-index: 2;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: all;
    }
}
.video-consent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    text-align: center;
    color: black;
    > div {
        margin: auto;
        max-height: 100%;
        overflow-y: auto;
        padding: 50px;
        @include breakpoint(map-get($mediaqueries, small-down)) {
            padding: 25px;
        }
    }
}
.doc-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 33%;
    z-index: 10;
    height: 100%;
    padding: 25px;
    overflow-y: auto;
    background-color: map-get($colors, "corporate");
    color: white;
    transition: .35s ease-out;
    @include breakpoint(map-get($mediaqueries, xlarge-up)) {
        padding: 50px;
        width: 600px;
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        width: calc(100% - 40px);
        transform: translateX(-100%);
        padding-bottom: 80px;
        &.doc-nav--opened {
            transform: translateX(0);
        }
    }
    .logo {
        display: block;
        width: 150px;
        height: 30px;
        .logo-primcolor {
            fill: map-get($colors, "darkgrey");
        }
        .logo-seccolor {
            fill: map-get($colors, "white");
        }
    }
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
            padding: 5px 0;
            span {
                color: map-get($colors, "darkgrey");
                font-weight: bold;
                font-size: 13px;
                font-family: "Roboto Mono", monospace;
            }
            a {
                text-decoration: none;
                color: white;
                font-weight: normal;
                &:hover {
                    text-decoration: underline;
                    span {
                        text-decoration: none;
                    }
                }
            }
        }
        ul {
            padding-left: 25px;
        }
    }
    > div > ul > li {
        padding-bottom: 15px;
        &:last-child {
            padding-bottom: 0;
        }
    }
}
.doc-nav-icon {
    position: fixed;
    bottom: 20px;
    left: 0;
    z-index: 10;
    padding: 10px 20px;
    background-color: map-get($colors, "corporate");
    transition: .35s ease-out;
    cursor: pointer;
    color: white;
    font-weight: bold;
    > * {
        vertical-align: middle;
    }
    @include breakpoint(map-get($mediaqueries, small-up)) {
        display: none;
    }
    .material-icons:nth-child(1) {
        display: none;
    }
    .doc-nav--opened + & {
        background-color: white;
        color: map-get($colors, "darkgrey");
        border: 1px solid map-get($colors, "darkgrey");
        .material-icons:nth-child(1) {
            display: inline;
        }
        .material-icons:nth-child(2) {
            display: none;
        }
    }
}
.doc-content {
    padding-top: 100px;
    &[data-depth="2"] {
        padding-top: 50px;
    }
    &[data-depth="3"],
    &[data-depth="4"],
    &[data-depth="5"] {
        padding-top: 25px;
    }
    @include breakpoint(map-get($mediaqueries, medium-down)) {
        padding-top: 70px;
        &[data-depth="2"] {
            padding-top: 25px;
        }
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        padding-top: 50px;
        &[data-depth="2"] {
            padding-top: 25px;
        }
    }
    ul {
        list-style-type: none;
        padding-left: 2em;
    }
    ul li:before {
        content: "\2022";
        color: map-get($colors, "corporate");
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        font-size: 1.25em;
        line-height: 1.12em;
    }
    h4 {
        font-size: 1rem;
    }
}