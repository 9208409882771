.blog-wrapper {
    @extend .fullpage-wrapper;
    padding: 50px 0;
    text-align: center;
    display: block;
    margin-right: calc(12.5% + 30px);
    width: calc(62.5% - 50px);
    @include breakpoint(map-get($mediaqueries, medium-down)) {
        margin-right: 40px;
        width: calc(75% - 60px);
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        margin-right: 20px;
        width: calc(100% - 40px);
    }
    @include breakpoint(map-get($mediaqueries, large-up)) {
        padding: 100px;
    }
}
.blogarticle-wrapper {
    @extend .fullpage-wrapper;
    padding: 50px 0;
    text-align: left;
    display: block;
    margin-right: calc(25% + 20px);
    width: calc(37.5% - 30px);
    @include breakpoint(map-get($mediaqueries, medium-down)) {
        margin-right: 40px;
        width: calc(75% - 60px);
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        margin-right: 20px;
        width: calc(100% - 40px);
    }
    @include breakpoint(map-get($mediaqueries, large-up)) {
        padding: 100px 0;
    }
}
.fullpage--blog .grid-info--right,
.fullpage--blog .grid-info--left {
    top: 22px;
    @include breakpoint(map-get($mediaqueries, medium-down)) {
        top: 32px;
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        top: 18px;
    }
    @include breakpoint(map-get($mediaqueries, xsmall-down)) {
        top: 21px;
    }
    .logo {
        position: relative;
        width: 200px;
        height: 40px;
        z-index: 15;
        @include breakpoint(map-get($mediaqueries, medium-down)) {
            width: 150px;
            height: 30px;
        }
        @include breakpoint(map-get($mediaqueries, xsmall-down)) {
            width: 120px;
            height: 24px;
        }
        &.fixed {
            position: fixed;
            top: 25px;
            animation-name: fade-from-top;
            animation-duration: .35s;
            animation-timing-function: ease-out;
            @include breakpoint(map-get($mediaqueries, small-down)) {
                top: 20px;
                right: 20px;
            }
        }
    }
}
.fullpage--blog .grid-info--left {
    top: 22px;
    @include breakpoint(map-get($mediaqueries, medium-down)) {
        top: 32px;
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        top: 18px;
        right: 20px;
        left: auto;
    }
    @include breakpoint(map-get($mediaqueries, xsmall-down)) {
        top: 21px;
    }
    .logo {
        position: relative;
        width: 200px;
        height: 40px;
        z-index: 15;
        @include breakpoint(map-get($mediaqueries, medium-down)) {
            width: 150px;
            height: 30px;
        }
        @include breakpoint(map-get($mediaqueries, xsmall-down)) {
            width: 120px;
            height: 24px;
        }
        &.fixed {
            position: fixed;
            top: 25px;
            left: 40px;
            animation-name: fade-from-top;
            animation-duration: .35s;
            animation-timing-function: ease-out;
            @include breakpoint(map-get($mediaqueries, small-down)) {
                top: 20px;
                right: 20px;
                left: auto;
            }
        }
    }
}