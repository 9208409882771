@each $color_name, $color_value in $colors {

	.u-color-#{$color_name} {
		color: $color_value;
	}

	.u-bgcolor-#{$color_name} {
		background-color: $color_value;
	}

}

.u-blend {
	mix-blend-mode: difference;
}
