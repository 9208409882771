.module-text {
    width: 100%;
}
.module-text-headline {
    width: 60%;
    b,strong {
        color: map-get($colors, "corporate");
    }
    @include breakpoint(map-get($mediaqueries, large-down)) {
        width: 80%;
    }
    @include breakpoint(map-get($mediaqueries, medium-down)) {
        width: 100%;
    }
}
.module-text-body {
    position: relative;
    width: 100%;
    color: map-get($colors, "grey");
    overflow: hidden;
    > div > :not(figure):first-child {
        margin-top: 0;
    }
    .module-text-headline + & {
        margin-top: 50px;
    }
    .color & {
        color: white;
        &:after {
            background: map-get($colors, "darkgrey");
        }
    }
    .grey & {
        color: map-get($colors, "darkgrey");
    }
    @include breakpoint(map-get($mediaqueries, medium-up)) {
        padding-left: 20%;
        &:after {
            content: "";
            position: absolute;
            top: 10px;
            left: 0;
            width: 15%;
            height: 1px;
            background: map-get($colors, "grey");
        }
    }
    @include breakpoint(map-get($mediaqueries, large-up)) {
        padding-right: 20%;
    }
    .module-text--1column &, .module-text--2column & {
        @include breakpoint(map-get($mediaqueries, medium-up)) {
            padding-left: 0;
            &:after {
                display: none;
            }
        }
    }
    .module-text--2column & {
        @include breakpoint(map-get($mediaqueries, medium-up)) {
            columns: 2;
            column-gap: 3.25%;
        }
        @include breakpoint(map-get($mediaqueries, large-up)) {
            padding-right: 22.5%;
        }
    }
    .onepage-wrapper & {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        padding-right: 0;
    }
    .creator-wrapper & {
        width: 100%;
        max-width: 100%;
        padding: 0;
        &:after {
            display: none;
        }
    }
}
.module-text-video {
    position: relative;
    width: 47.5%;
    float: left;
    min-height: 10px;
    margin-right: 5%;
    > div {
        width: 100%;
        padding-bottom: 56.25%;
    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    + div {
        width: 47.5%;
        float: left;
    }
    @include breakpoint(map-get($mediaqueries, medium-down)) {
        float: none;
        width: 100%;
        margin-right: 0;
        margin-bottom: 25px;
        + div {
            float: none;
            width: 100%;
        }
    }
}
.module-text-image {
    width: 120px;
    max-width: calc(33.3333% - 25px);
    float: left;
	margin: 0 25px 25px 0;
	@include breakpoint(map-get($mediaqueries, medium-up)) {
        max-width: calc(25% - 25px);
    }
    @include breakpoint(map-get($mediaqueries, large-up)) {
        max-width: calc(33.3333% - 25px);
    }
}
.module-text-image--300 {
    max-width: 300px;
    margin: 0;
}
.module-text-image--icon {
    max-width: 120px;
    margin: 0;
    margin-bottom: -25px;
}
.module-text-bigimage {
    width: 100%;
    margin: 1.75rem 0;
    text-align: center;
    img {
        max-width: 600px;
    }
}
.module-text-button {
    width: 100%;
    margin-top: 50px;
    padding-left: 20%;
    @include breakpoint(map-get($mediaqueries, medium-down)) {
        padding-left: 0;
    }
    .module-text--1column &, .module-text--2column & {
        padding-left: 0;
    }
    &.module-text-button--fullwidth {
        margin: 0;
        padding-right: 20%;
        @include breakpoint(map-get($mediaqueries, large-down)) {
            padding-right: 0;
        }
    }
}