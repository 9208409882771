html {
	height: 100%;
	width: 100%;
	font-size: 1rem;
	font-family: "Roboto", sans-serif;
	line-height: 1.4;
}

body {
	position: relative;
	height: 100%;
	width: 100%;
	overflow-x: hidden;
    transition: color .25s ease-out, background-color .25s ease-out;
	@include breakpoint(map-get($mediaqueries, small-down)) {
        &:after {
            content: "";
	        mix-blend-mode: hard-light;
            position: fixed;
            top: 0;
            left: 0;
            width: 300%;
            height: 100px;
            background-image: linear-gradient(black, transparent);
        }
    }
}

main {
	position: relative;
	height: 100%;
	width: 100%;
}

a {
	text-decoration: none;
	font-weight: 700;
	color: map-get($colors, "corporate");
	.color & {
		color: map-get($colors, "darkgrey");
	}
	& p, & h1, & h2, & h3, & h4, & h5, & h6 {
		color: inherit;
	}
}

p {
	margin-bottom: 0.875rem;
	:not(li) > &:last-child {
		margin-bottom: 0;
	}
}

ul, ol, li>ol, li>ul {
	margin-bottom: 0.875rem;
}

ul, ol {
	:not(li) > &:last-child {
		margin-bottom: 0;
	}
}

ul:last-child > li:last-child > p {
	margin-bottom: 0;
}

img, svg, iframe {
	width: 100%;
	max-width: 100%;
}

figure {
    position: relative;
    padding: 0;
    margin: 1.5rem 0;

    &.can-fullscreen {
        cursor: pointer;
    }

    &.can-fullscreen.is-fullscreen {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        background: rgba(0, 0, 0, .8);
        z-index: 999;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .figure-close {
            display: block;
        }

        img {
            width: 100%;
            height: auto;
            max-width: 100%;
            max-height: 100vh;
            object-fit: contain
        }
    }

    .figure-close {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        background: map-get($colors, "corporate");
        padding: 5px;
        font-weight: bold;
        line-height: 30px;
        width: 40px;
        font-size: 20px;
        text-align: center;
        cursor: pointer;
        color: map-get($colors, "white");
    }
}

figcaption {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    background: rgba(255, 255, 255, .5);
    padding: 5px;
    font-style: italic;
    color: map-get($colors, "darkgrey");
}
#CybotCookiebotDialog * { 
	font-family: "Roboto", sans-serif !important;
}

#CybotCookiebotDialogBodyContentTitle {
    font-family: 'Roboto Mono',monospace !important;
	font-weight: 400 !important;
    text-transform: uppercase !important;
    font-size: 13px !important;
    letter-spacing: 2px !important;
    color: #006AA6 !important;
}