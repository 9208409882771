.module-image {
    position: relative;
    padding: 25px 0;
    &:after {
        content: "";
        clear: both;
        display: table;
    }
    @include breakpoint(map-get($mediaqueries, large-up)) {
        &:last-child {
            min-height: 18vw;
        }
    }
}
.module-image-img {
    position: relative;
    width: calc(3 / 20 * 100%);
    margin-right: calc(1 / 20 * 100%);
    padding-bottom: 27%;
    float: left;
    overflow: hidden;
    filter: grayscale(50%);
    transition: filter .35s ease-out;
    &:hover {
        filter: grayscale(0);
    }
    .module-image:nth-of-type(2n) & {
        float: right;
        margin-left: calc(1 / 20 * 100%);
        margin-right: 0;
    }
    @include breakpoint(map-get($mediaqueries, medium-down)) {
        width: calc(3 / 12 * 100%);
        margin-right: calc(1 / 12 * 100%);
        padding-bottom: 45%;
        .module-image:nth-of-type(2n) & {
            margin-left: calc(1 / 12 * 100%);
            margin-right: 0;
        }
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        position: absolute;
        top: 0;
        left: -40px;
        width: calc(50% + 40px);
        padding-bottom: 120%;
        .module-image:nth-of-type(2n) & {
            left: auto;
            right: -40px;
        }
    }
    @include breakpoint(map-get($mediaqueries, large-up)) {
        position: absolute;
        top: 25px;
        left: 0;
        .module-image:nth-of-type(2n) & {
            left: auto;
            right: 0;
        }
    }
    svg {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}
.module-image-badges {
    overflow: hidden;
}
.module-image-badge {
    filter: grayscale(50%);
    transition: filter .35s ease-out;
    margin: 0 .875rem .875rem 0;
    float: left;
    height: auto;
    width: calc(25% - .875rem);
    max-width: 90px;
    object-fit: contain;
    @include breakpoint(map-get($mediaqueries, medium-down)) {
        width: calc(50% - .875rem);
    }
    .module-image:nth-of-type(2n) & {
        margin: 0 0 .875rem .875rem;
        float: right;
    }
    &:hover {
        filter: grayscale(0);
    }
}
.module-image-text {
    width: 80%;
    margin-top: 2.5%;
    float: left;
    .module-image:nth-of-type(2n) & {
        text-align: right;
        float: right;
    }
    @include breakpoint(map-get($mediaqueries, medium-down)) {
        width: calc(8 / 12 * 100%);
        margin-top: 5%;
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        float: none;
        width: 100%;
        margin-top: 105%;
    }
    @include breakpoint(map-get($mediaqueries, large-up)) {
        padding-left: 20%;
        .module-image:nth-of-type(2n) & {
            padding-left: 0;
            padding-right: 20%;
        }
    }
    p {
        color: grey;
    }
}