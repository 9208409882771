.wmBox_overlay{
	position:fixed;
	width:100%;
	height:100%;
	display:none;
	top:0;
	left:0;
	background:rgba(255,255,255,0.5);
	z-index: 999;
}
.wmBox_centerWrap{
	position:absolute;
	width:100%;
	height:100%;
}
.wmBox_centerer{
	width: calc(100% - 100px);
	height: calc(100% - 100px);
    max-width: 800px;
    margin: 50px auto 0;
    padding: 25px;
    position: relative;
    background: white;
    box-shadow: 0 0 5px black;
    @include breakpoint(map-get($mediaqueries, medium-down)) {
		width: calc(100% - 50px);
		height: calc(100% - 50px);
	    margin: 25px auto 0;
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
		width: 100%;
		height: 100%;
	    margin: 0;
    }
}
.wmBox_contentWrap{
	width: 100%;
	height: 100%;
	margin:0 auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}
.wmBox_scaleWrap{
	position:relative;
	height: 100%;
	width:100%;
	@media (hover: none) and (pointer: coarse) {
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}
}
.wmBox_centerer iframe{
	position:absolute;
	top:0;
	bottom: 0;
	right: 0;
	left:0;
	border:0;
	outline:0;
	background-color: white;
	height: 100%;
	width: 100%;
    overflow-y: auto;
}
.wmBox_closeBtn{
	position:absolute;
	top: 25px;
	right: 25px;
	z-index: 999;
    @include breakpoint(map-get($mediaqueries, small-down)) {
		top: 20px;
		right: 20px;
    }
}
.wmBox_closeBtn p {
	font-family: 'Roboto Mono',monospace;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 2px;
    display: inline-block;
	margin:0;
    padding: 0;
    min-width: 30px;
    text-align: center;
    line-height: 30px;
	color: white;
	background: map-get($colors, "corporate");
	border: 1px solid map-get($colors, "corporate");
	cursor: pointer;
	transition: 0.35s ease-out;
}
@media (hover: hover) and (pointer: fine) {
	.wmBox_closeBtn p:hover{
		background: white;
		border-color: map-get($colors, "darkgrey");
		color: map-get($colors, "darkgrey");
	}
}