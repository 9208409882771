.module-socialmedia {
    position: relative;
    width: 100%;
    padding: 25px 0;
    color: map-get($colors, "grey");
    overflow: hidden;
    .color & {
        color: white;
        &:after {
            background: map-get($colors, "darkgrey");
        }
    }
    .grey & {
        color: map-get($colors, "darkgrey");
    }
    @include breakpoint(map-get($mediaqueries, medium-up)) {
        padding-left: 20%;
        &:after {
            content: "";
            position: absolute;
            top: 37px;
            left: 0;
            width: 15%;
            height: 1px;
            background: map-get($colors, "grey");
        }
    }
    @include breakpoint(map-get($mediaqueries, large-up)) {
        padding-right: 20%;
    }
    .module-socialmedia--plain & {
        padding-left: 0;
        &:after {
            display: none;
        }
    }
    .module-socialmedia--plainextended & {
        &:after {
            display: none;
        }
    }
}
.module-socialmedia-icon {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: 10px;
    color: map-get($colors, "darkgrey");
    transition: color .35s ease-out;
    img, svg {
        display: block;
        width: 100%;
        height: 100%;
    }
    .dark & {
        color: white;
        &:hover {
            color: map-get($colors, "corporate");
        }
    }
    .grey & {
        color: white;
        &:hover {
            color: map-get($colors, "corporate");
        }
    }
    .color & {
        color: white;
        &:hover {
            color: map-get($colors, "darkgrey");
        }
    }
    @include breakpoint(map-get($mediaqueries, medium-down)) {
        width: 20px;
        height: 20px;
    }
}