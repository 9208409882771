.creator {
    width: 100%;
}
.creator-wrapper {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding: 0 25px 50px;
    text-align: left;
    @include breakpoint(map-get($mediaqueries, large-up)) {
        max-width: 850px;
        padding: 0 0 100px;
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        padding: 0 0 50px;
    }
}
.creator-bonus-wrapper {
    position: relative;
}
.creator-bonus-button {
    display: none;
}
.creator-bonus {
    position: relative;
    z-index: 2;
    border: 1px solid map-get($colors, "darkgrey");
    background: white;
    .creator-element-info {
        display: none;
    }
    .creator-heading + .creator-bonus-wrapper &:not(.fixed) {
        border-top: none;
    }
    &:not(.current):not(.fixed) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    &.current {
        z-index: 3;
    }
    &.fixed {
        position: fixed;
        z-index: 3;
        left: 0;
        bottom: 0;
        transform: translateY(100%);
        width: 100%;
        margin-bottom: 0;
        transition: transform .4s 1s ease-out, background .4s ease-out;        
        border: none;
        border-top: 1px solid map-get($colors, "darkgrey");
        .creator-element-info {
            display: inline-block;
        }
        .creator-bonus-button {
            border-top: 1px solid map-get($colors, "darkgrey");
            display: block;
            .button {
                display: block;
            }
        }
    }
    &.fixed.current {
        z-index: 4;
        transform: translateY(0);
    }
}
.creator-element {
    display: flex;
    line-height: 30px;
    transition: .4s ease-out;
    max-width: 850px;
    @include breakpoint(map-get($mediaqueries, small-up)) {
        &:nth-of-type(2n) {
            background: map-get($colors, "lightgrey");
        }
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        flex-direction: column;
    }
    s {
        display: inline;
        color: map-get($colors, "grey");
        text-decoration: none;
    }
    .creator-bonus & {
        margin: 0 auto;
        background: transparent;
    }
    .creator-bonus.active & {
        background: map-get($colors, "corporate");
        color: white;
        opacity: .75;
        s {
            display: none;
        }
    }
}
.creator-progress {
    position: relative;
    height: 25px;
    width: 100%;
    max-width: 850px;
    margin: 0 auto;
    overflow: hidden;
    background: map-get($colors, "lightgrey");
    border-bottom: 1px solid map-get($colors, "darkgrey");
    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: -25px;
        height: 25px;
        width: var(--progress);
        background: map-get($colors, "corporate");
        transform: skewX(-45deg);
        transform-origin: left bottom;
        transition: .4s ease-out;
    }
    &:after {
        @include navstyle();
        content: "Gesamtsumme " attr(data-current) "€";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 25px;
        width: 100%;
        padding: 5px;
        line-height: 15px;
        font-size: 1rem;
        color: white;
        text-shadow: 0 0 20px #006aa6;
    }
}
.creator-element-title {
    width: 50%;
    margin: 0;
    padding: 5px;
    @include breakpoint(map-get($mediaqueries, small-down)) {
        width: 100%;
    }
}
.creator-element-subtitle {
    @include navstyle();
    color: map-get($colors, "corporate");
    display: block;
    padding-top: 5px;
    line-height: 20px;
}
.creator-element-data {
    width: 50%;
    margin: 0;
    table {
        width: 100%;
        height: 100%;
        margin: 0;
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        width: 100%;
        background: map-get($colors, "lightgrey");
        .creator-bonus.fixed & {
            display: none;
        }
    }
    @include breakpoint(map-get($mediaqueries, medium-down)) {
        .creator-bonus & td:first-child {
            display: block;
            text-align: left;
            width: 100%;
            padding-bottom: 0;
            padding-top: 5px;
            line-height: 1.4;
        }
        .creator-bonus & td:last-child {
            display: block;
            text-align: left;
            width: 100%;
            padding-top: 0;
            padding-bottom: 5px;
            line-height: 1.4;
        }
    }
}
.creator-element-info {
    position: relative;
    @include navstyle();
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    padding-left: 2px;
    color: white;
    text-align: center;
    text-transform: none;
    font-size: 1rem;
    background-color: map-get($colors, "corporate");
    &:hover {
        box-shadow: 0 0 3px 1px map-get($colors, "corporate");
    }
    &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: map-get($colors, "corporate");
        transform-origin: right bottom;
        transform: skewY(-7.5deg);
    }
    span {
        position: relative;
    }
}
.creator-input {
    @include navstyle();
    width: 54px;
    height: 30px;
    line-height: 30px;
    font-weight: bold;
    padding: 0 0 0 4px;
    font-size: 1rem;
    border: 4px solid map-get($colors, "corporate");
}
.creator-number {
    @include navstyle();
    font-weight: bold;
    font-size: 1rem;
}
.creator-switch {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 30px;
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    .creator-switch-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: map-get($colors, "lightgrey");
        border: 4px solid map-get($colors, "middlegrey");
        transition: .4s;
        &:before {
            @include navstyle();
            position: absolute;
            content: "0";
            height: 22px;
            width: 22px;
            left: 0;
            bottom: 0;
            line-height: 22px;
            text-align: center;
            font-weight: bold;
            font-size: 1rem;
            color: map-get($colors, "lightgrey");
            background-color: white;
            transition: .4s;
        }
        &.shake {
          animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
          transform: translate3d(0, 0, 0);
          backface-visibility: hidden;
          perspective: 1000px;
        }
    }
    input:checked + .creator-switch-slider {
        background-color: map-get($colors, "corporate");
        border: 4px solid map-get($colors, "corporate");
    }
    input:focus + .creator-switch-slider {
        box-shadow: 0 0 1px map-get($colors, "corporate");
    }
    input:invalid + .creator-switch-slider {
        outline: 2px solid red;
        &:after {
            content: "required";
            position: absolute;
            bottom: 5px;
            left: 0;
            width: 100%;
            line-height: 13px;
            color: red;
            font-size: 13px;
            text-align: center;
        }
    }
    input:checked + .creator-switch-slider:before {
        content: "1";
        color: map-get($colors, "corporate");
        transform: translateX(22px);
    }
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
.creator-radio {
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
    width: 30px;
    height: 30px;
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    .creator-radio-button {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: map-get($colors, "lightgrey");
        border: 4px solid map-get($colors, "middlegrey");
        border-radius: 50%;
        transition: .35s ease-in;
    }
    input:checked + .creator-radio-button {
        background-color: map-get($colors, "green");
        border: 4px solid map-get($colors, "white");
    }
    .hover & .creator-radio-button {
        border: 4px solid map-get($colors, "corporate");
    }
}
.creator-heading {
    display: flex;
    transition: .4s ease-out;
    max-width: 850px;
    border-top: solid 1px map-get($colors, "darkgrey");
    border-bottom: solid 1px map-get($colors, "darkgrey");
    @include breakpoint(map-get($mediaqueries, small-down)) {
        flex-direction: column;
    }
    .creator-heading + &, .creator-bonus-wrapper + & {
        border-top: none;
    }
}
.creator-heading-title {
    @include navstyle();
    padding: 25px 0;
    font-size: 24px;
    font-weight: bold;
    line-height: 24px;
    width: 50%;
    span:nth-of-type(2) {
        color: map-get($colors, "corporate")
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        padding: 15px 5px;
    }
}
.creator-heading-data {
    font-weight: bold;
    display: flex;
    align-items: center;
    width: 50%;
    margin: 0;
    table {
        width: 100%;
        height: 100%;
        margin: 0;
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        width: 100%;
        table {
            height: 30px;
            line-height: 30px;
            border-top: 1px solid map-get($colors, "darkgrey");
        }
        p {
            padding: 0 5px 15px;
        }
    }
}
.creator-heading-subtitle {
    width: 100%;
    padding: 10px 0;
    color: map-get($colors, "corporate");
}
.creator-submit-wrapper {
    margin-top: 50px;
    text-align: center;
}
[data-activepackage="true"] {
    background: map-get($colors, "corporate");
    color: white;
}
[data-activepackage="false"] {
    cursor: pointer;
    transition: .35s ease-in;
}
[data-activepackage="false"].hover {
    background: map-get($colors, "green");
    color: white;
}
.creator-servicebox {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    z-index: 5;
    background: white;
    box-shadow: 0 -3px 3px rgba(0,0,0,.5);
    > * {
        width: 100%;
    }
}