#loader, .loader {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
    width: 100%;
    height: 100%;
	z-index: 9999;
    overflow: hidden;
	background: map-get($colors, "darkgrey");
}

.loader-wrapper {
	width: 300px;
	height: 100%;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	animation: progress-width 1s infinite alternate cubic-bezier(.33,.1,0,1);
}

.loader-particle {
	position: relative;
	width: 18px;
	height: 18px;
	&:first-child {
		transform-origin: bottom right;
		animation: particle-left 1s infinite alternate cubic-bezier(.33,.1,0,1);
	}
	&:last-child {
		transform-origin: bottom left;
		animation: particle-right 1s infinite alternate cubic-bezier(.33,.1,0,1);
	}
	&:after, &:before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		width: 18px;
		height: 100%;
		background: white;
	}
	&:after {
		right: 0;
		transform-origin: top right;
		transform: skewX(19deg);
	}
	&:before {
		left: 0;
		transform-origin: top left;
		transform: skewX(-19deg);
	}
	&:first-child:before {
		transform: skewX(-31deg);
	}
	&:last-child:after {
		transform: skewX(31deg);
	}
}

.loader-progress {
	position: relative;
	width: calc(100% - 60px);
	height: 18px;
	background: map-get($colors, "corporate");
	animation: progress-height 1s infinite alternate cubic-bezier(.33,.1,0,1);
	&:after, &:before {
		content: "";
		display: block;
		position: absolute;
		bottom: 0;
		width: 18px;
		height: 100%;
		background: map-get($colors, "corporate");
	}
	&:after {
		right: 0;
		transform-origin: bottom right;
		transform: skewX(-19deg);
		animation: progress-right 1s infinite alternate cubic-bezier(.33,.1,0,1);
	}
	&:before {
		left: 0;
		transform-origin: bottom left;
		transform: skewX(19deg);
		animation: progress-left 1s infinite alternate cubic-bezier(.33,.1,0,1);
	}
}

.loader-text {
	position: absolute;
	top: calc(50% + 15px);
	left: 0;
	width: 100%;
	text-align: center;
	transform-origin: center top;
	animation: text 1s infinite alternate cubic-bezier(.33,.1,0,1);
}

@keyframes particle-left {
  from {transform:rotate(19deg);opacity:.6;}
  to {transform:rotate(0);opacity:1;}
}

@keyframes particle-right {
  from {transform:rotate(-19deg);opacity:.6;}
  to {transform:rotate(0);opacity:1;}
}

@keyframes progress-left {
  from {transform: skewX(0)}
  to {transform: skewX(19deg)}
}

@keyframes progress-right {
  from {transform: skewX(0)}
  to {transform: skewX(-19deg)}
}

@keyframes progress-width {
  from {width:90px;}
  to {width:240px;}
}

@keyframes progress-height {
  from {height:21px;top:3px;opacity:.6;}
  to {height:18px;top:0;opacity:1;}
}

@keyframes text {
  from {transform:scale(1);opacity:.6;}
  to {transform:scale(1.1);opacity:1;}
}