.module-linklist {
    position: relative;
}
.module-linklist-link {
    display: block;
    position: relative;
    width: 100%;
    color: map-get($colors, "corporate");
    cursor: pointer;
    transition: all .5s;
    margin: 0;
    padding: 10px 0 10px 10px;
    .color & {
        color: white;
        &:after {
            background: map-get($colors, "darkgrey");
        }
    }
    &:after {
        content: "";
        position: absolute;
        top: 10px;
        left: 0;
        width: 1px;
        height: 25px;
        background: map-get($colors, "grey");
        transition: all .25s;
    }
    &:hover, &.active {
        color: map-get($colors, "darkgrey");
        padding-left: 20px;
        &:after {
            background: map-get($colors, "darkgrey");
            width: 3px;
        }
        .dark &, .color & {
            color: white;
            &:after {
                background: white;
            }
        }
    }
    @include breakpoint(map-get($mediaqueries, medium-up)) {
        padding-left: 20%;
        &:after {
            top: 19px;
            left: 0;
            width: 15%;
            height: 1px;
        }
        &:hover, &.active {
            padding-left: 22%;
            &:after {
                background: map-get($colors, "corporate");
                top: 18px;
                width: 15%;
                height: 3px;
            }
        }
    }
    @include breakpoint(map-get($mediaqueries, large-up)) {
        padding-right: 20%;
    }
}