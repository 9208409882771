.nav {
	position: absolute;
	top: 25px;
	bottom: 25px;
	left: 25px;
	width: 31px;
	z-index: 10;
	@include breakpoint(map-get($mediaqueries, small-up)) {
		&.fixed {
			position: fixed;
	        animation-name: fade-from-left;
	        animation-duration: .35s;
	        animation-timing-function: ease-out;
			&.nav--blend {
				mix-blend-mode: difference;
			}
		}
	}
	&.nav--background {
		display: none;
	}
	@include breakpoint(map-get($mediaqueries, small-down)) {
		position: fixed;
		animation: none;
		top: 20px;
		bottom: 20px;
		left: 20px;
		z-index: 4;
		&.nav--background + .nav--opened {
			mix-blend-mode: difference;
		}
		&.nav--background {
			display: block;
			top: 0;
			left: 0;
			bottom: 0;
			width: 200%;
			z-index: 3;
			background: white;
			transform: translateX(-150%) skewX(18deg);
			transition: transform .35s ease-out .35s;
			&.color:not(.fixed) {
				background: map-get($colors, "corporate");
			}
		}
		&.nav--background.nav--opened {
			transform: translateX(-60%) skewX(15deg);
			transition: transform .35s ease-out;
			z-index: 5;
		}
		&.nav--opened {
			z-index: 10;
		}
	}
}

.nav-wrapper {
	position: absolute;
	width: 100%;
	height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
	&.nav-wrapper--lines {
		padding: 15px 0;
	}
}

.nav-activearea {
	position: absolute;
	top: -25px;
	left: -25px;
	bottom: -25px;
	width: 150px;
	@include breakpoint(map-get($mediaqueries, small-down)) {
		display: none;
	}
}

a[href].nav-button {
	padding: 10px 10px 10px 40px;
    color: white;
}

.nav-el {
	width: 100%;
	height: 25px;
	text-align: center;
	font-family: 'Roboto Mono', monospace;
	font-weight: bold;
	line-height: 25px;
	transition: transform .35s ease-out;
	cursor: pointer;
	@include breakpoint(map-get($mediaqueries, small-down)) {
		transform: translateX(-100px);
		&:first-child, .nav--opened & {
			transform: translateX(0);
		}
	}
	.nav-logo {
		transition: transform .5s ease-in-out;
		svg {
			width: 31px;
			height: 25.5px;
			.logo-seccolor {
				fill: map-get($colors, "grey");
				@supports(mix-blend-mode:difference) {
					fill: white;
				}
			}
		}
		@include breakpoint(map-get($mediaqueries, small-down)) {
			.nav--opened & {
				transform: rotateZ(360deg);
				transform-origin: center 40%;
			}
		}
	}
	a {
		position: relative;
		display: inline-block;
		width: 100%;
		color: map-get($colors, "grey");
		transition: color .35s ease-out;
		padding: 8px 0;
		.color:not(.fixed) & {
			color: map-get($colors, "darkgrey");
		}
	}
	span:nth-child(1) {
		display: inline-block;
		width: 20px;
		svg {
			width: 20px;
			height: 16px;
		}
	}
	span:nth-child(2) {
		display: inline-block;
		position: absolute;
		left: 100%;
		top: -3px;
		padding: 8px 0;
		text-align: left;
	}
	span:nth-child(3) {
	    @include navstyle();
		display: inline-block;
		position: absolute;
		left: 100%;
		top: -3px;
		padding: 8px 0;
		width: 0;
		padding-left: 30px;
		overflow: hidden;
		text-align: left;
		transition: width 0s .35s, color .35s ease-out;
		color: map-get($colors, "grey");
		.color:not(.fixed) & {
			color: map-get($colors, "darkgrey");
		}
		> i {
			display: inline-block;
			font-style: normal;
			transition: opacity .35s ease-out, transform .35s ease-out;
			transform: translateX(-100%);
			opacity: 0;
		}
	}
	svg {
		fill: map-get($colors, "grey");
		.color:not(.fixed) & {
			fill: map-get($colors, "darkgrey");
		}
		transition: fill .35s ease-out;
	}
	.nav--blend &, .nav--background + .nav--opened &  {
		svg {
			fill: transparent;
		}
		&:hover, &.hover, &.active {
			svg {
				@supports(mix-blend-mode:difference) {
					fill: white;
				}
			}
		}
	}
	&:hover, &.hover {
		.nav-logo {
			transform: rotateY(360deg);
		}
	}
	&:hover, &.hover, &.active {
		a {
			color: map-get($colors, "corporate");
		}
		svg {
			fill: map-get($colors, "corporate");
		}
		.color:not(.fixed) & {
			a {
				color: white;
			}
			svg {
				fill: white;
			}
		}
		.nav--background + .dark:not(.fixed) & {
			svg {
				fill: white;
			}
		}
		.nav--background + .light:not(.fixed) & {
			svg {
				fill: map-get($colors, "darkgrey");
			}
		}
		.nav--background + .color:not(.fixed) & {
			svg {
				fill: map-get($colors, "darkgrey");
			}
		}
	}
	.nav--opened & {
		@include breakpoint(map-get($mediaqueries, small-down)) {
			span:nth-child(3) {
				transition: color .35s ease-out;
				width: 200px;
				> i {
					transform: translateX(0);
					opacity: 1;
				}
			}
		}
	}
	.nav--opened &.active {
		@include breakpoint(map-get($mediaqueries, small-down)) {
			span:nth-child(3) {
				color: map-get($colors, "corporate");
			}
		}
	}
	&.show {
		@include breakpoint(map-get($mediaqueries, small-up)) {
			span:nth-child(3) {
				transition: color .35s ease-out;
				width: 200px;
				> i {
					transform: translateX(0);
					opacity: 1;
				}
			}
		}
	}
	&:hover, &.hover, &.active {
		@include breakpoint(map-get($mediaqueries, small-up)) {
			span:nth-child(3) {
				transition: color .35s ease-out;
				width: 200px;
				> i {
					transform: translateX(0);
					opacity: 1;
				}
			}
		}
		span:nth-child(3) {
			color: map-get($colors, "corporate");
			.color:not(.fixed) & {
				color: white;
			}
		}
	}
	@include breakpoint(map-get($mediaqueries, small-down)) {
		& a {
			padding: 0.40625rem .05rem;
			&:after {
				top: 0.40625rem;
				left: .05rem;
			}
		}
	}
}

.nav-line {
	flex-grow: 1;
	width: 1px;
	transition: background .35s ease-out, flex-grow .25s linear, transform .35s ease-out, opacity .15s ease-out;
	background: map-get($colors, "grey");
	@supports(mix-blend-mode:difference) {
		background: white;
	}
	@for $i from 0 through 10 {
		  &[data-grow="#{$i}"] {
		    flex-grow: #{$i};
		}
	}
	&.inactive {
		background: transparent;
	}
	@include breakpoint(map-get($mediaqueries, small-down)) {
		transform: translateX(-100px);
		opacity: 0;
		.nav--opened & {
			transform: translateX(0);
			opacity: 1;
			transition: background .35s ease-out, flex-grow .25s linear, transform .35s ease-out, opacity .55s ease-out;
		}
		&.inactive {
			background: rgba(255,255,255,.2);
		}
	}
}

#mobilenav {
	display: none;
	@include breakpoint(map-get($mediaqueries, small-down)) {
		position: fixed;
	    z-index: 15;
	    display: block;
	    left: 20px;
	    top: 20px;
	    font-weight: bold;
	}
}