.module-thumbnails {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 0;
    @include breakpoint(map-get($mediaqueries, medium-up)) {
        margin-left: -20%;
        width: 140%;
    }
    @include breakpoint(map-get($mediaqueries, large-up)) {
        padding-bottom: 25px;
    }
    .fullpage--onepage & {
        padding-top: 0;
        margin-left: 0;
        width: 100%;
    }
    .module-text-headline {
        width: 100%;
        text-align: center;
    }
}
.module-thumbnail {
    text-align: center;
    width: calc( 100% * 1 / 4 );
    padding: 0 4% 25px;
    transition: filter .35s ease-out;
    img {
        max-width: 120px;
    }
    &.module-thumbnail--grayscale {
        filter: grayscale(1);
    }
    &.module-thumbnail--inverted {
        filter: grayscale(1) invert(1);
    }
    &:hover {
        filter: unset;
    }
    @include breakpoint(map-get($mediaqueries, medium-up)) {
        width: calc( 100% * 1 / 3 );
        padding: 0 5% 25px;
        .fullpage--onepage & {
            width: calc(100% * 1 / 8 - 10px);
            padding: 0 1%;
        }
    }
    @include breakpoint(map-get($mediaqueries, large-up)) {
        width: calc(100% * 1 / 7);
        padding: 0 1% 25px;
        .fullpage--onepage & {
            width: calc(100% * 1 / 8 - 10px);
            padding: 0 1%;
        }
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        width: calc( 100% * 1 / 3 );
        min-width: 100px;
        img {
            max-width: 90px;
            margin: 10px 0;
        }
    }
}