.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.slick-list:focus {
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
}
.slick-track:before, .slick-track:after {
    display: table;
    content: '';
}
.slick-track:after {
    clear: both;
}
.slick-loading .slick-track {
    visibility: hidden;
}
.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    outline: none;
}
[dir='rtl'] .slick-slide {
    float: right;
}
.slick-slide img {
    display: block;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

.module-doubleslider {
    position: relative;
    @include breakpoint(map-get($mediaqueries, small-down)) {
        margin: 0 -20px;
        width: calc(100% + 40px);
    }
    .module-text-headline {
        margin-bottom: 75px;
        @include breakpoint(map-get($mediaqueries, small-down)) {
            margin-left: 20px;
            margin-right: 20px;
            width: calc(100% - 40px);
        }
    }
    > div {
        position: relative;
    }
}
.module-doubleslider-innerwrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-items: flex-start;
    overflow: hidden;
}
.module-doubleslider-logowrapper {
    position: relative;
    margin-top: 25px;
    user-select: none;
    touch-action: pan-y;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    &[data-slidesToShow="2"] {
        @include breakpoint(map-get($mediaqueries, small-up)) {
            margin-right: calc(-1 / 3 * 100%);
        }
        @include breakpoint(map-get($mediaqueries, medium-up)) {
            margin-right: -20%;
        }
    }
    &[data-slidesToShow="3"] {
        @include breakpoint(map-get($mediaqueries, medium-up)) {
            margin-right: -20%;
        }
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        padding: 0 40px;
    }
    .slick-track {
        align-items: center;
        display: flex;
    }
}
.module-doubleslider-textwrapper {
    position: relative;
    margin-top: 25px;
    user-select: none;
    touch-action: pan-y;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    .module-doubleslider-logowrapper + & {
        margin-top: 50px;
        @include breakpoint(map-get($mediaqueries, small-down)) {
            margin-top: 25px;
        }
    }
}
.module-doubleslider-logo {
    img {
        max-height: 75px;
        object-fit: contain;
    }
    [data-slidesToShow="2"] & > div {
        padding-right: calc(1 / 3 * 100%);
        @include breakpoint(map-get($mediaqueries, medium-down)) {
            padding-right: 50%;
        }
        @include breakpoint(map-get($mediaqueries, small-down)) {
            padding: 0 40px;
        }
    }
    [data-slidesToShow="3"] & > div {
        padding-right: 50%;
        @include breakpoint(map-get($mediaqueries, medium-down)) {
            padding: 0 10%;
        }
        @include breakpoint(map-get($mediaqueries, small-down)) {
            padding: 0 20%;
        }
    }
}
.module-doubleslider-logo-image {
    filter: grayscale(100%);
    opacity: .7;
    transition: .5s ease-out;
    cursor: pointer;
    .slick-current &, .module-doubleslider-logo:hover & {
        filter: grayscale(0);
        opacity: 1;
    }
}
.module-doubleslider-text {
    transition: transform .5s ease-out;
    float: left;
    width: 100%;
    transform: scale(.9);
    .slick-current & {
        transform: scale(1);
        transition-delay: .75s;
    }
    > div {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-items: flex-start;
        width: 100%;
        @include breakpoint(map-get($mediaqueries, small-down)) {
            flex-direction: column;
        }
    }
}
.module-doubleslider-arrow {
    position: absolute;
    top: 0;
    z-index: 4;
    height: 100%;
    width: 40px;
    cursor: pointer;
    transition: top .35s ease-in-out;
    &.right {
        right: -40px;
    }
    &.left {
        left: -40px;
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        &.right {
            right: 15px;
        }
        &.left {
            left: 15px;
        }
    }
    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        width: 24px;
        height: 24px;
    }
    &.right:after {
        right: 0;
        border-top: 1px solid map-get($colors, "grey");
        border-right: 1px solid map-get($colors, "grey");
        transform: rotate(45deg);
        transform-origin: right top;
    }
    &.left:after {
        left: 0;
        border-top: 1px solid map-get($colors, "grey");
        border-left: 1px solid map-get($colors, "grey");
        transform: rotate(-45deg);
        transform-origin: left top;
    }
    &.middle:after {
        top: 50%;
    }
}
.module-doubleslider-text-image {
    position: relative;
    z-index: 2;
    width: calc(3 / 20 * 100%);
    margin-right: calc(1 / 20 * 100%);
    transition: .5s ease-out .75s;
    filter: grayscale(100%);
    .slick-current & {
        filter: grayscale(0);
        transition-delay: .75s;
    }
    @include breakpoint(map-get($mediaqueries, medium-down)) {
        width: calc(3 / 12 * 100%);
        margin-right: calc(1 / 12 * 100%);
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        width: calc(1 / 4 * 100%);
        margin-right: 0;
        margin-top: -75px;
        order: 2;
    }
}
.module-doubleslider-text-text {
    position: relative;
    width: 100%;
    padding-left: calc(1 / 5 * 100%);
    &:after {
        content: "";
        position: absolute;
        top: 10px;
        left: 0;
        width: calc(1 / 10 * 100%);
        height: 1px;
        background: map-get($colors, "grey");
    }
    p {
        color: map-get($colors, "grey");
        b, strong {
            color: map-get($colors, "darkgrey");
            .dark & {
                color: white;
            }
        }
        .grey & {
            color: map-get($colors, "darkgrey");
        }
    }
    h3 {
        position: relative;
        font-size: inherit;
    }
    .button {
        margin-top: 1.5rem;
    }
    @include breakpoint(map-get($mediaqueries, medium-down)) {
        padding-left: calc(1 / 3 * 100%);
        &:after {
            width: calc(1 / 6 * 100%);
        }
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        padding: 0 20px;
        &:after {
            display: none;
        }
        h3 {
            padding-left: calc(3 / 20 * 100%);
            &:after {
                content: "";
                position: absolute;
                top: 10px;
                left: 0;
                width: calc(1 / 10 * 100%);
                height: 1px;
                background: map-get($colors, "grey");
            }
        }
        h4 {
            padding-left: calc(3 / 20 * 100%);
        }
        .button {
            margin-top: 20px;
        }
    }
    @include breakpoint(map-get($mediaqueries, large-up)) {
        padding-right: 20%;
    }
    .module-doubleslider-text-image + & {
        width: calc(4 / 5 * 100%);
        margin-top: calc(1 / 40 * 100%);
        padding-left: 0;
        &:after {
            display: none;
        }
        h3 {
            padding-left: calc(3 / 20 * 100%);
            &:after {
                content: "";
                position: absolute;
                top: 10px;
                left: 0;
                width: calc(1 / 10 * 100%);
                height: 1px;
                background: map-get($colors, "grey");
            }
        }
        h4 {
            padding-left: calc(3 / 20 * 100%);
        }
        @include breakpoint(map-get($mediaqueries, medium-down)) {
            width: calc(8 / 12 * 100%);
            margin-top: calc(1 / 25 * 100%);
        }
        @include breakpoint(map-get($mediaqueries, small-down)) {
            width: 100%;
            margin-top: 0;
            margin-bottom: 20px;
            padding: 0 20px;
            h3 {
                padding-left: calc(1 / 4 * 100% + 20px);
                transform: translateY(10px);
            }
            h4 {
                padding-left: calc(1 / 4 * 100% + 20px);
                height: 15px;
                transform: translateY(10px);
                word-wrap: break-word;
                hyphens: auto;
            }
            .button {
                position: absolute;
                bottom: -35px;
                left: calc(1 / 4 * (100% + 40px) + 20px);
                max-width: calc(3 / 4 * (100% - 40px) - 20px);
                transform: translateY(100%);
                word-wrap: break-word;
                hyphens: auto;
            }
        }
    }
}