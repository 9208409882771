.module-button {
    margin: 0;
    padding: 0 0 25px;
}
.module-button--centered {
    text-align: center;
    @include breakpoint(map-get($mediaqueries, large-up)) {
    	width: 80%;
    }
}
.module-button--multiple {
    @include breakpoint(map-get($mediaqueries, medium-up)) {
	    & .button {
	    	margin-top: 10px;
	    	margin-bottom: 10px;
	    }
	}
}