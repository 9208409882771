.module-textlist {
    width: 100%;
    counter-reset: number;
}
.module-textlist-text {
    margin: 25px 0;
    color: map-get($colors, "grey");
    overflow: hidden;
    .grey & {
        color: map-get($colors, "darkgrey");
    }
    @include breakpoint(map-get($mediaqueries, large-up)) {
        padding-right: 20%;
    }
    .onepage-wrapper & {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        padding-right: 0;
    }
}
.module-textlist-list {
    position: relative;
    width: 100%;
    margin: 50px 0;
    color: map-get($colors, "grey");
    .grey & {
        color: map-get($colors, "darkgrey");
    }
}
.module-textlist-head {
    position: relative;
    width: 100%;
    color: map-get($colors, "darkgrey");
    cursor: pointer;
    transition: color .35s ease-out;
    counter-increment: number;
    margin: 0;
    padding: 25px 0 10px 10px;
    @include breakpoint(map-get($mediaqueries, medium-up)) {
        padding-left: 20%;
    }
    @include breakpoint(map-get($mediaqueries, large-up)) {
        padding-right: 20%;
    }
    .dark & {
        color: white;
    }
    @media (any-hover: hover) {
        &:hover {
            color: map-get($colors, "corporate");
        }
    }
    &.active {
        color: map-get($colors, "corporate");
    }
    .module-textlist--unordered & {
        &:after {
            content: "";
            position: absolute;
            top: 25px;
            left: 0;
            width: 1px;
            height: 29px;
            background: map-get($colors, "grey");
            transition: width .35s ease-out, height .35s ease-out, opacity .35s ease-out;
        }
        @media (any-hover: hover) {
            &:hover {
                &:after {
                    background: map-get($colors, "corporate");
                    width: 3px;
                    height: 29px;
                }
            }
        }
        &.active {
            &:after {
                background: map-get($colors, "corporate");
                width: 3px;
                height: 29px;
            }
        }
        @include breakpoint(map-get($mediaqueries, medium-up)) {
            &:after {
                top: 39px;
                left: 0;
                width: 10%;
                height: 1px;
            }
            &.active {
                &:after {
                    background: map-get($colors, "corporate");
                    top: 38px;
                    width: 15%;
                    height: 3px;
                }
            }        
            @media (any-hover: hover) {
                &:hover {
                    &:after {
                        background: map-get($colors, "corporate");
                        top: 38px;
                        width: 15%;
                        height: 3px;
                    }
                } 
            }
        }
    }
    .module-textlist--ordered & {
        &:after {
            content: counters(number, ".", decimal-leading-zero);
            position: absolute;
            top: 36px;
            left: 70px;
            width: 0;
            height: 3px;
            text-indent: -70px;
            font-family: 'Roboto Mono', monospace;
            letter-spacing: 2px;
            font-size: 3rem;
            font-weight: normal;
            line-height: 0;
            opacity: .35;
            background: map-get($colors, "corporate");
            transition: width .35s ease-out, height .35s ease-out, opacity .35s ease-out, font-size .35s ease-out, line-height .35s ease-out;
        }
        @include breakpoint(map-get($mediaqueries, medium-down)) {
            padding-left: 35px;
            &:after {
                left: 40px;
            }
            &.active {
                &:after {
                    font-size: 6rem;
                    top: 56px;
                }
            }
        }
        @include breakpoint(map-get($mediaqueries, medium-up)) {
            @media (any-hover: hover) {
                &:hover {
                    &:after {
                        width: calc(15% - 60px);
                        opacity: 1;
                    }
                }
            }
            &.active {
                &:after {
                    width: calc(15% - 60px);
                    opacity: 1;
                }
            }
        }
    }
    .module-textlist--icons & {
        @include breakpoint(map-get($mediaqueries, medium-down)) {
            padding-left: 35px;
        }
    }
    .module-textlist--imgs & {
        @include breakpoint(map-get($mediaqueries, medium-down)) {
            padding-left: 50px;
        }
    }
}
.module-textlist-img {
    position: absolute;
    top: 25px;
    left: 0;
    width: 45px;
    @include breakpoint(map-get($mediaqueries, medium-up)) {
        width: 15%;
    }
    @include breakpoint(map-get($mediaqueries, large-up)) {
        width: 15%;
    }
    @include breakpoint(map-get($mediaqueries, medium-up)) {
        top: 16px;
        img {
            max-height: 50px;
            width: auto;
            transition: transform .35s ease-out;
            transform-origin: left top;
            filter: grayscale(100%);
        }
        @media (any-hover: hover) {
            .module-textlist-head:hover & {
                img {
                    transform: scale(1.15);
                    filter: none;
                }
            }
        }
        .module-textlist-head.active & {
            img {
                transform: scale(1.15);
                filter: none;
            }
        }
    }
}
.module-textlist-icon {
    position: absolute;
    top: 25px;
    left: 0;
    @include breakpoint(map-get($mediaqueries, medium-up)) {
        top: 16px;
        .material-icons {
            font-size: 42px;
            line-height: 42px;
            transition: transform .35s ease-out;
            transform-origin: left center;
        }
        @media (any-hover: hover) {
            .module-textlist-head:hover & {
                .material-icons {
                    transform: scale(1.2);
                }
            }
        }
        .module-textlist-head.active & {
            .material-icons {
                transform: scale(1.2);
            }
        }
    }
}
.module-textlist-body {
    display: none;
    transition: line-height .35s ease-out, opacity .5s ease-out;
    overflow: hidden;
    color: map-get($colors, "grey");
    @include breakpoint(map-get($mediaqueries, medium-up)) {
        padding-left: 20%;
    }
    @include breakpoint(map-get($mediaqueries, large-up)) {
        padding-right: 20%;
    }
    .module-textlist--allactive & {
        display: block;
    }
    .grey & {
        color: map-get($colors, "darkgrey");
    }
}
// .module-textlist-button {
//     padding-top: 5px;
//     padding-bottom: 15px;
//     transition: padding .35s ease-out;
//     @include breakpoint(map-get($mediaqueries, medium-up)) {
//         padding-left: 20%;
//     }
//     @include breakpoint(map-get($mediaqueries, large-up)) {
//         padding-right: 20%;
//     }
//     .module-textlist-body.active + & {
//         padding-top: 20px;
//     }
// }