@mixin navstyle() {  
    font-family: 'Roboto Mono', monospace;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.875rem;
  margin-top: 0.875rem;
}

h1 {
  font-size: 32px;
  font-weight: bold;
  line-height: 1.2;
  margin: 50px 0px;
  @include breakpoint(map-get($mediaqueries, medium-down)) {
    font-size: 32px;
  }
  @include breakpoint(map-get($mediaqueries, small-down)) {
    font-size: 26px;
    margin: 25px 0px;
  }
}

h2 {
  font-size: 32px;
  font-weight: bold;
  line-height: 1.2;
  margin: 25px 0px;
  @include breakpoint(map-get($mediaqueries, medium-down)) {
    font-size: 32px;
  }
  @include breakpoint(map-get($mediaqueries, small-down)) {
    font-size: 26px;
  }
  &:first-child {
    margin-top: 0;
  }
}

h3 {
  font-size: 21px;
  font-weight: bold;
  margin-top: 25px;
  &:first-child {
    margin-top: 0;
  }
  h4 + & {
    margin-top: -0.875rem;
  }
}

h4 {
  font-size: 13px;
  font-weight: normal;
  font-family: 'Roboto Mono',monospace;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1.2;
  color: map-get($colors, "corporate");
  h3 + & {
    margin-top: -0.875rem;
  }
  & + h1 {
    margin-top: -0.5rem;
  }
  .color & {
      color: map-get($colors, "darkgrey");
      font-weight: 700;
  }
}

h5 {
  font-weight: bold;
  font-size: 1rem;
}

h6 {
  font-weight: normal;
  font-size: 1rem;
}