.home-wrapper {
    @extend .fullpage-wrapper;
    .logo {
        width: 100%;
        margin-bottom: 50px;
        padding-bottom: 28.83%;        
        @include breakpoint(map-get($mediaqueries, large-up)) {
            width: 80%;
            padding-bottom: 23.06%;
        }
        @include breakpoint(map-get($mediaqueries, small-up)) {
            transition: opacity 4s ease-out;
            &.transparent {
                opacity: 0;
                transition: opacity 1s ease-out;
            }
        }
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        padding-top: 100px;
    }
}
.home-badge {
    position: absolute;
    top: 25px;
    right: 40px;
    max-width: 220px;
    @include breakpoint(map-get($mediaqueries, small-down)) {
        position: relative;
        top: auto;
        bottom: auto;
        right: auto;
        left: 20px;
        max-width: 160px;
        padding-bottom: 20px;
        .fullpage--aligncenter & {
            left: 50%;
            transform: translateX(-50%);
        }
    }
    @include breakpoint(map-get($mediaqueries, xlarge-up)) {
        max-width: 260px;
    }
}
.home-headline {
    width: 60%;
    @include breakpoint(map-get($mediaqueries, large-down)) {
        width: 80%;
    }
    @include breakpoint(map-get($mediaqueries, medium-down)) {
        width: 100%;
    }
    > h1 {
        font-size: 2vw;
        @include breakpoint(map-get($mediaqueries, medium-down)) {
            font-size: 2.75vw;
        }
        @include breakpoint(map-get($mediaqueries, small-down)) {
            font-size: 4vw;
        }
        @include breakpoint(map-get($mediaqueries, xlarge-up)) {
            font-size: 1.6666vw;
        }
        b,strong {
            color: map-get($colors, "corporate");
        }
    }
}
.home-subline {
    font-style: italic;
    font-size: 1.5vw;
    @include breakpoint(map-get($mediaqueries, medium-down)) {
        font-size: 2vw;
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        font-size: 3.25vw;
    }
    @include breakpoint(map-get($mediaqueries, large-up)) {
        font-size: 1.25vw;
    }
    @include breakpoint(map-get($mediaqueries, xlarge-up)) {
        font-size: 1vw;
    }
    b,strong {
        text-decoration: underline;
        font-weight: normal;
    }
    > span {
        position: relative;
        display: inline-block;
        overflow: hidden;
        padding: 0 2px;
        > span {
            display: inline-block;
            transform: translateY(-100%);
            transition: .5s ease-out;
            &.active {
                transform: translateY(0);
            }
            &.transition {
                position: absolute;
                top: 0;
                left: 0;
                transform: translateY(100%);
            }
        }
    }
}