.module-cards {
    position: relative;
    overflow: hidden;
}
.module-cards-wrapper {
    overflow: hidden;
    margin: 0 -25px;
    @supports (flex-wrap: wrap) {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .module-cards--equal & {
            justify-content: space-around;
            text-align: center;
        }
    }
    @include breakpoint(map-get($mediaqueries, medium-down)) {
        margin: 0 -13px;
        @supports (flex-wrap: wrap) {
            .module-cards--equal & {
                justify-content: space-around;
            }
        }
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        margin: 0;
    }
}
.module-cards-element {
    position: relative;
    width: calc(25% - 50px);
    margin: 25px;
    float: left;
    .module-cards:not(.module-cards--withoutcards) & {
        overflow: hidden;
        background-color: map-get($colors, "corporate");
        color: white;
    }
    .module-cards--equal & {
        width: calc(1 / 3 * 100% - 50px);
    }
    @include breakpoint(map-get($mediaqueries, large-down)) {
        width: calc(50% - 50px);
        .module-cards--equal & {
            width: calc(1 / 3 * 100% - 50px);
        }
    }
    @include breakpoint(map-get($mediaqueries, medium-down)) {
        width: calc(50% - 26px);
        margin: 13px;
        .module-cards--big & {
            width: calc(100% - 26px);
        }
        .module-cards--equal & {
            width: calc(50% - 26px);
        }
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        width: 100%;
        margin: 25px 0 0;
        .module-cards--equal & {
            width: 100%;
        }
    }
    @include breakpoint(map-get($mediaqueries, medium-up)) {
        .module-cards--big & {
            width: calc(50% - 50px);
        }
    }
    @include breakpoint(map-get($mediaqueries, xlarge-up)) {
        .module-cards--equal & {
            width: calc(25% - 50px);
        }
    }
    @supports (flex-wrap: wrap) {
        float: none;
    }
}
.module-cards-text {
    padding: 25px 0;
    transition: .35s cubic-bezier(.57,.26,.28,1);
    font-weight: initial;
    .module-cards.module-cards--withoutcards & {
        .light & {
            color: map-get($colors, "grey");
            h3 {
                color: map-get($colors, "darkgrey");
            }
        }
        .grey & {
            color: map-get($colors, "darkgrey");
        }
        .color & {
            color: white;
        }
    }
    .module-cards:not(.module-cards--withoutcards) & {
        padding: 25px;
        color: white;
    }
    .module-cards-element:hover & {
        .module-cards:not(.module-cards--withoutcards) & {
            padding-top: 15px;
            padding-bottom: 35px;
        }
    }        
    @media (any-hover: none) {
        .module-cards:not(.module-cards--withoutcards) & {
            padding-top: 15px;
            padding-bottom: 35px;
        }
    }
    .module-cards:not(.module-cards--withoutcards) & h3 {
        @media (max-width: 800px) {
            word-break: break-all;
            hyphens: auto;
        }
    }
}
.module-cards-img {
    position: relative;
    transition: .35s cubic-bezier(.57,.26,.28,1);
    img {
        display: block;
    }
    .module-cards:not(.module-cards--withoutcards) & {
        clip-path: polygon(0% 0%, 100% 0%, 100% 81.612%, 0% 100%);
        &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            transform-origin: center center;
            transition: .35s cubic-bezier(.57,.26,.28,1);
        }
    }
    .module-cards-element:hover & {
        .module-cards:not(.module-cards--withoutcards) & {
            clip-path: polygon(0% 30%, 100% 0%, 100% 70%, 0% 100%);
            img {
                transform: translateY(10px) scale(1.1);
                opacity: .75;
            }
        }
    }
    @media (any-hover: none) {
        .module-cards:not(.module-cards--withoutcards) & {
            clip-path: polygon(0% 30%, 100% 0%, 100% 70%, 0% 100%);
            img {
                transform: translateY(10px) scale(1.1);
                opacity: .75;
            }
        }
    }
}
.module-cards-button {
    position: absolute;
    top: 0;
    left: -2.5%;
    width: 105%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-origin: center;
    transform: rotate(-16deg);
    &:after {
        content: "";
        display: block;
        padding-bottom: calc(50%);
    }
    .button, .button:hover {
        padding: 10px;
        color: map-get($colors, "corporate");
        transition: .35s cubic-bezier(.57,.26,.28,1);
        transform: translateX(-100%);
        width: 100%;
        opacity: 0;
        overflow: visible;
        .module-cards-element:hover & {
            transform: translateX(0);
            opacity: 1;
        }
        @media (any-hover: none) {
            transform: translateX(0);
            opacity: 1;
        }
        &:before {
            display: none;
        }
        &:after {
            right: 0;
            transform-origin: center;
            transform: skewX(-16deg);
            width: 100%;
            border: none;
            background: white;
        }
    }
    .module-cards-img + & {
        left: 25px;
        width: calc(100% - 50px);
        &:after {
            padding-bottom: calc(100% + 50px);
        }
        .button, .button:hover {
            color: white;
            width: auto;
            &:after {
                background: map-get($colors, "corporate");
            } 
        }
    }
    &:first-child {
        @media (any-hover: none) {
            transform: none;
            bottom: 0;
            left: 0;
            top: auto;
            padding: 10px;
            width: 100%;
            &:after {
                padding-bottom: 0;
            }
            .button, .button:hover {
                &:after {
                    transform: none;
                } 
            }
        }
    }
}
.module-cards.module-cards--withoutcards {
    .module-cards-element {
        .module-cards-img {
            transition: .35s cubic-bezier(.57,.26,.28,1);
            transition-delay: .1s;
            max-width: 300px;
            padding: 0 25px;
            margin: 0 auto;
        }
        .module-cards-text {
            transition: .35s cubic-bezier(.57,.26,.28,1);
            transition-delay: .05s;
            max-width: 300px;
            margin: 0 auto;
        }
        .module-cards-button2 {
            // transition: .35s cubic-bezier(.57,.26,.28,1);
            // transition-delay: 0s;
        }
    }
    .module-cards-element:hover {
        .module-cards-img {
            transition-delay: 0s;
            transform: translateY(-10px);
        }
        .module-cards-text {
            transition-delay: .05s;
            transform: translateY(-10px);
        }
        .module-cards-button2 {
            // transition-delay: .1s;
            // transform: translateY(-10px);
        }
    }
}