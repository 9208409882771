.simplenav {
	position: fixed;
	bottom: 100px;
	left: 25px;
	width: 31px;
	z-index: 10;
	transform: translateY(-100%);
	opacity: 0;
	transition: transform .35s ease-out, opacity .35s ease-out;
	pointer-events: none;
	&.simplenav--opened {
		pointer-events: all;
		transform: translateY(0);
		opacity: 1;
		transition: transform .35s ease-out .35s, opacity .35s ease-out .35s;
	}
	@include breakpoint(map-get($mediaqueries, small-down)) {
		bottom: 20px;
		left: 20px;
	}
}

.simplenav-background {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: 350px;
	z-index: 5;
	background: white;
	transform: translateX(-100%) skewX(25deg);
	transform-origin: right bottom;
	transition: transform .35s ease-out .35s;
	box-shadow: 1px 0 5px rgba(0,0,0,.5);
	&.simplenav--opened {
		transform: translateX(0) skewX(15deg);
		transition: transform .35s ease-out;
	}
	@include breakpoint(map-get($mediaqueries, small-down)) {
		width: 95vw;
	}
}

.simplenav-line {
	position: fixed;
	bottom: 0;
	left: 40px;
	height: 100vh;
	width: 1px;
	z-index: 6;
	background: #dddddd;
	transform: translateY(-100%);
	opacity: 0;
	transition: transform .35s ease-out, opacity .35s ease-out;
	&.simplenav--opened {
		transform: translateY(0);
		opacity: 1;
		transition: transform .35s ease-out .35s, opacity .35s ease-out .35s;
	}
	@include breakpoint(map-get($mediaqueries, small-down)) {
		left: 35px;
	}
}

.simplenav-button-background {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 4;
	width: 360px;
	height: 180px;
    background: linear-gradient(22.5deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 50%);
    pointer-events: none;
	@include breakpoint(map-get($mediaqueries, small-down)) {
		display: none;
	}
}

.simplenav-button {
	position: fixed;
	left: 0;
	bottom: 0;
	z-index: 6;
	padding: 25px;
    color: white;
	transition: color .5s ease-in-out;
    @include navstyle();
    line-height: 25.5px;
    font-weight: bold;
	@include breakpoint(map-get($mediaqueries, small-down)) {
		bottom: auto;
		top: 20px;
		left: 20px;
		padding: 0;
	}
	&.simplenav--opened {
		color: map-get($colors, "darkgrey");
	}
}

.simplenav-logo {
	transition: transform .5s ease-in-out;
	margin-right: 9px;
	float: left;
	svg {
		width: 31px;
		height: 25.5px;
		.logo-seccolor {
			fill: white;
			transition: fill .5s ease-in-out;
		}
	}
	.simplenav--opened & {
		transform: rotate(360deg);
		svg {
			.logo-seccolor {
				fill: map-get($colors, "darkgrey");
			}
		}
	}
}

.simplenav-wrapper {
	position: relative;
	width: 100%;
	padding-bottom: 10px;
}

.simplenav-el {
    @include navstyle();
	width: 100%;
	height: 50px;
	font-weight: bold;
	line-height: 50px;
	transition: transform .35s ease-out;
	text-align: center;
	cursor: pointer;
	a {
		position: relative;
		display: inline-block;
		width: 100%;
		color: map-get($colors, "grey");
		transition: color .35s ease-out;
		padding: 8px 0;
		.color:not(.fixed) & {
			color: map-get($colors, "darkgrey");
		}
	}
	span:nth-child(1) {
		display: inline-block;
		width: 20px;
		svg {
			width: 20px;
			height: 16px;
		}
	}
	span:nth-child(2) {
		display: inline-block;
		position: absolute;
		left: 100%;
		top: -3px;
		padding: 8px 0;
		text-align: left;
	}
	svg {
		.seccolor {
			fill: map-get($colors, "grey");
			transition: fill .35s ease-out;
		}
		.primcolor {
			fill: map-get($colors, "grey");
			transition: fill .35s ease-out;
		}
	}
	&:hover, &.hover, &.active {
		a {
			color: map-get($colors, "corporate");
		}
		svg {
			.seccolor {
				fill: map-get($colors, "darkgrey");
			}
			.primcolor {
				fill: map-get($colors, "corporate");
			}
		}
	}
	.simplenav--opened & {
		@include breakpoint(map-get($mediaqueries, small-down)) {
			span:nth-child(3) {
				transition: color .35s ease-out;
				width: 200px;
				> i {
					transform: translateX(0);
					opacity: 1;
				}
			}
		}
	}
	.simplenav--opened &.active {
		@include breakpoint(map-get($mediaqueries, small-down)) {
			span:nth-child(3) {
				color: map-get($colors, "corporate");
			}
		}
	}
	@include breakpoint(map-get($mediaqueries, small-down)) {
		& a {
			padding: 0.40625rem .05rem;
			&:after {
				top: 0.40625rem;
				left: .05rem;
			}
		}
	}
}
.fullpage--blog .logo {
	transition: .35s ease-out .35s;
}
.logo.simplenav--opened {
	transition: .35s ease-out;
	@include breakpoint(map-get($mediaqueries, small-up)) {
		transform: translateX(calc(350px - 26.79vh));
	}
}