.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    opacity: 0;
    transform: translateX(-100%);
    pointer-events: none;
    &.popup--opened {
        opacity: 1;
        pointer-events: all;
        transform: translateX(0);
        animation-name: popup-fadeIn;
        animation-duration: .5s;
        animation-iteration-count: 1;
        animation-timing-function: ease-out;
    }
    &.popup--closed {
        animation-name: popup-fadeOut;
        animation-duration: .5s;
        animation-iteration-count: 1;
        animation-timing-function: ease-out;
    }
}

@keyframes popup-fadeIn {
  from {opacity: 0;transform: translateX(0)}
  to {opacity: 1;transform: translateX(0)}
}

@keyframes popup-fadeOut {
  from {opacity: 1;transform: translateX(0)}
  to {opacity: 0;transform: translateX(0)}
}

.popup-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: map-get($colors, "darkgrey");
    opacity: .75;
}

.popup-outerwrapper {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-width: 660px;
    padding: 20px;
}

.popup-innerwrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
    background: white;
    overflow: auto;
    transform: scale(.75);
    transition: transform .35s ease-out;
    .popup--opened & {
        transform: scale(1);
    }
}

.popup-closebutton {
    position: absolute;
    right: 0;
    top: 0;
    width: 25px;
    height: 25px;
    background: white;
    cursor: pointer;
    z-index: 2;
    color:  map-get($colors, "darkgrey");
    &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        transform-origin: right bottom;
        transform: skewY(-7.5deg);
    }
    i {
        position: relative;
    }
}

.popup-opened {
    & > nav, & > main {
        filter: blur(4px);
    }
}

.popup-image {
    position: relative;
    overflow: hidden;
    clip-path: polygon(0% 0%, 100% 0%, 100% 73.8%, 0% 100%);
    width: 100%;
    &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 100%;
        background: white;
        transform-origin: left top;
        transform: skewY(-7.5deg);
    }
    img {
        display: block;
    }
}

.popup-text {
    padding: 25px;
}