.button {
    @include navstyle();
    display: inline-block;
    position: relative;
    z-index: 2;
    padding: 10px;
    min-width: 150px;
    text-align: center;
    line-height: 18px;
    transition: all .35s ease-out;
    overflow: hidden;
    border: none;
    background: transparent;
    cursor: pointer;
    .color & {
        color: white;
    }
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: calc(100% - 1px);
        z-index: -1;
        border: 1px solid map-get($colors, "corporate");
        border-right: none;
        transition: all .35s ease-out;
        transform-origin: right bottom;
        .color & {
            border-color: white;
        }
    }
    &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 50px;
        z-index: -2;
        border: 1px solid map-get($colors, "corporate");
        border-left: none;
        transition: all .35s ease-out;
        transform-origin: right bottom;
        .color & {
            border-color: white;
        }
    }
    &.button--filled {
        color: white;
        &:after, &:before {
            background: map-get($colors, "corporate");
            .color & {
                background: map-get($colors, "darkgrey");
            }
        }
    }
    &.button--white {
        color: white;
        &:after, &:before {
            border-color: white;
        }
    }
    &.button--corporate {
        color: white;
        &:before, &:after {
            background: map-get($colors, "corporate");
            border-color: map-get($colors, "corporate");
        }
    }
    &.button--green {
        color: white;
        &:before, &:after {
            background: map-get($colors, "green");
            border-color: map-get($colors, "green");
        }
    }
    &.button--red {
        color: white;
        &:before, &:after {
            background: map-get($colors, "red");
            border-color: map-get($colors, "red");
        }
    }
    &.button--orange {
        color: white;
        &:before, &:after {
            background: map-get($colors, "orange");
            border-color: map-get($colors, "orange");
        }
    }
    &.fixed {
        position: fixed;
        z-index: 15;
        top: 25px;
        right: 25px;
        animation-name: fade-from-top;
        animation-duration: .35s;
        animation-timing-function: ease-out;
        color: white;
        &:after, &:before {
            background: map-get($colors, "corporate");
            .color & {
                background: map-get($colors, "darkgrey");
            }
        }
        @include breakpoint(map-get($mediaqueries, small-down)) {
            top: 20px;
            right: 20px;
        }
    }
    &.button--fullwidth {
        width: calc(100% + 21px);
        &.button--simple {
            width: 100%;
        }
        @include breakpoint(map-get($mediaqueries, small-down)) {
            width: 100%;
        }
    }
    &.button--isfixed {
        position: fixed;
        top: 0;
        left: 0;
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        &:after {
            width: 100%;
        }
    }
    @include breakpoint(map-get($mediaqueries, small-up)) {
        padding-right: 30px;
        &:before {
            width: calc(100% - 21px);
        }
        &:after {
            right: 20px;
        }
        &:hover, &.hover {
            padding-left: 25px;
            color: white;
            &:after {
                transform: skewX(-25deg);
                width: 120%;
                background: map-get($colors, "corporate");
                .color & {
                    background: map-get($colors, "darkgrey");
                }
            }
        }
        &.button--centered {
            padding: 10px;
            &:before, &:after {
                width: 100%;
            }
            &:after {
                right: 0;
            }
        }
        &.button--centered:hover, &.button--centered.hover {
            padding: 10px 30px;
            &:before {
                transform: none;
            }
            &:after {
                transform: none;
            }
        }
        &.button--white {
            color: map-get($colors, "corporate") !important;
            &:before, &:after {
                background: white !important;
            }
        }
        &.button--corporate {
            color: white !important;
            &:before, &:after {
                background: map-get($colors, "corporate") !important;
            }
        }
        &.button--green {
            color: white !important;
            &:before, &:after {
                background: map-get($colors, "green") !important;
            }
        }
        &.button--red {
            color: white !important;
            &:before, &:after {
                background: map-get($colors, "red") !important;
            }
        }
        &.button--orange {
            color: white !important;
            &:before, &:after {
                background: map-get($colors, "orange") !important;
            }
        }
        &.button--simple, &.button--simple:hover, &.button--simple.hover {
            padding-left: 10px;
            padding-right: 10px;
            &:before {
                transform: none;
            }
            &:after {
                right: 0;
                transform: skewX(0);
            }
        }
        &.button--inverse {
            padding-left: 30px;
            padding-right: 10px;
            &:after {
                right: auto;
                left: 20px;
                border: 1px solid map-get($colors, "corporate");
                border-right: none;
            }
            &:before {
                right: 0;
                left: 20px;
                border: 1px solid map-get($colors, "corporate");
                border-left: none;
            }
            &:hover, &.hover {
                padding-left: 20px;
                padding-right: 20px;
                color: white;
                &:after {
                    transform: skewX(25deg);
                }
            }
        }
    }
}

.button-icon {
    position: absolute;
    top: calc(50% - 12px);
    left: 5px;
    display: inline-block;
    height: 24px;
    float: left;
    transition: left .35s ease-out, right .35s ease-out;
    + .button-text {
        display: inline-block;
        padding-left: 25px;
        text-align: left;
        max-width: 100%;
        .button--fullwidth & {
            text-align: center;
        }
    }
    .button.button--inverse & {
        left: auto;
        right: 5px;
        float: right;
        + .button-text {
            padding-left: 5px;
            padding-right: 20px;
        }
    }
    @include breakpoint(map-get($mediaqueries, small-up)) {
        .button:not(.button--simple):not(.button--inverse):hover & {
            left: 18px;
        }
        .button.button--inverse:hover & {
            right: 15px;
        }
    }
}
.button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
    .button {
        margin: 15px;
    }
}