.module-iframe {
    position: relative;
    overflow: hidden;
    iframe {
        width: 100%;
        min-width: 100%;
    }
}
.module-iframe-video {
	position: relative;
	width: 100%;
	max-width: 70vh;
	margin: 0 auto;
    > div {
        width: 100%;
        padding-bottom: 56.25%;
    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}