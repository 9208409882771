.module-graphic {
    position: relative;
    overflow: hidden;
}
.module-graphic-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-items: flex-start;
    width: 200%;
    overflow: hidden;
    @include breakpoint(map-get($mediaqueries, small-down)) {
        width: 400%;
    }
    &.animate {
        transform: translateX(-20%);
        transition: transform .75s ease-out;
        @include breakpoint(map-get($mediaqueries, medium-down)) {
            transform: translateX(-16.6666%);
        }
        @include breakpoint(map-get($mediaqueries, small-down)) {
            transform: translateX(-25%);
        }
    }
}
.module-graphic-element {
    width: calc(1 / 10 * 100%);
    margin-right: calc(1 / 10 * 100%);
    &:last-child {
        margin-right: 0;
    }
    @include breakpoint(map-get($mediaqueries, medium-down)) {
        width: calc(1 / 9 * 100%);
        margin-right: calc(1 / 18 * 100%);
        &:first-child {
            margin-left: calc(1 / 36 * 100%);
        }
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        width: calc(1 / 6 * 100%);
        margin-right: calc(1 / 12 * 100%);
        &:first-child {
            margin-left: calc(1 / 24 * 100%);
        }
    }
}
.module-graphic-element-head {
    display: block;
    text-align: center;
    opacity: .8;
    transition: .75s ease-out;
    h3 {
        margin-top: 0;
        text-align: center;
    }
    h4 {
        margin-bottom: 0;
        transition: color .75s ease-out;
        color: map-get($colors, "grey");
    }
    .module-graphic-element.active &, .module-graphic-element:hover & {
        opacity: 1;
        h4 {
            color: map-get($colors, "corporate");
        }
    }
    @include breakpoint(map-get($mediaqueries, medium-down)) {
        margin-left: -25%;
        margin-right: -25%;
    }
}
.module-graphic-element-line {
    position: relative;
    display: block;
    width: 100%;
    &:before {
        content: "";
        position: absolute;
        top: -1px;
        left: 0;
        display: block;
        width: 200%;
        border-top: 1px solid map-get($colors, "lightgrey");
        @include breakpoint(map-get($mediaqueries, medium-down)) {
            left: -25%;
            width: 150%;
        }
        @include breakpoint(map-get($mediaqueries, small-down)) {
            left: -25%;
            width: 150%;
        }
    }
    &:after {
        content: "";
        position: absolute;
        z-index: 2;
        top: -1px;
        right: -150%;
        display: block;
        width: 0%;
        border-top: 1px solid map-get($colors, "darkgrey");
        opacity:0;
        transition: width 5s ease-out, opacity .75s ease-out;
        @include breakpoint(map-get($mediaqueries, medium-down)) {
            right: -100%;
        }
    }
    .module-graphic-element.active & {
        &:after {
            left: 50%;
            width: 200%;
            opacity:1;
            transition: width 5.5s ease-out;
            @include breakpoint(map-get($mediaqueries, medium-down)) {
                width: 150%;
            }
        }
    }
}
.module-graphic-element-point {
    position: relative;
    display: block;
    width: 22px;
    height: 18px;
    left: 50%;
    z-index: 2;
    svg {
        width: 20px;
        height: 16px;
        transform: translate(-50%, -50%);
        fill: map-get($colors, "grey");
        transition: all .35s ease-out;
        stroke: transparent;
        stroke-width: 1px;
    }
    .module-graphic-element.active &, .module-graphic-element:hover & {
        svg {
            width: 22px;
            height: 18px;
            fill: map-get($colors, "corporate");
            stroke: map-get($colors, "darkgrey");
        }
    }
}
.module-graphic-element-icon {
    display: block;
    margin-top: -20px;
    svg {
        stroke: map-get($colors, "grey");
        stroke-width: 1px;
        fill:transparent;
        stroke-linecap:round;
        stroke-linejoin:round;
        stroke-miterlimit:10;
        transition: all .35s ease-out;
        .fillit {
            transition: all .5s ease-out;
        }
        .module-graphic-element.active & {
            stroke: map-get($colors, "darkgrey");
            .fillit {
                fill: map-get($colors, "corporate");
            }
        }
    }
}