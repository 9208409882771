.onepage-wrapper {
    @extend .fullpage-wrapper;
    padding: 100px 0;
    .fullpage--autoheight & {
        padding: 100px 0 50px;
    }
    @include breakpoint(map-get($mediaqueries, large-down)) {
        margin-right: calc(12.5% + 30px);
        width: calc(62.5% - 50px);
        .fullpage--aligncenter & {
            margin-right: calc(12.5% + 30px);
            width: calc(75% - 60px);
        }
    }
    @include breakpoint(map-get($mediaqueries, medium-down)) {
        margin-right: 40px;
        width: calc(75% - 60px);
        .fullpage--aligncenter & {
            margin-right: 40px;
            width: calc(100% - 80px);
        }
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        margin-right: 20px;
        width: calc(100% - 40px);
        padding: 75px 0;
        .fullpage--aligncenter & {
            margin-right: 20px;
            width: calc(100% - 40px);
        }
        .fullpage--autoheight & {
            padding: 75px 0 25px;
        }
    }
    @include breakpoint(map-get($mediaqueries, large-up)) {
        margin-right: calc(25% + 20px);
        width: calc(50% - 40px);
    }
    @include breakpoint(map-get($mediaqueries, xlarge-up)) {
        padding: 150px 0;
        .fullpage--autoheight & {
            padding: 150px 0 50px;
        }
    }
}
.fullpage--onepage .grid-info--right,
.fullpage--onepage .grid-info--left {
    top: 22px;
    @include breakpoint(map-get($mediaqueries, medium-down)) {
        top: 32px;
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        top: 18px;
    }
    @include breakpoint(map-get($mediaqueries, xsmall-down)) {
        top: 21px;
    }
    .logo {
        position: relative;
        width: 200px;
        height: 40px;
        z-index: 15;
        @include breakpoint(map-get($mediaqueries, medium-down)) {
            width: 150px;
            height: 30px;
        }
        @include breakpoint(map-get($mediaqueries, xsmall-down)) {
            width: 120px;
            height: 24px;
        }
        &.fixed {
            position: fixed;
            top: 25px;
            animation-name: fade-from-top;
            animation-duration: .35s;
            animation-timing-function: ease-out;
            @include breakpoint(map-get($mediaqueries, small-down)) {
                top: 20px;
            }
        }
    }
}
@include breakpoint(map-get($mediaqueries, small-down)) {
    .fullpage--aligncenter .grid-info--left {
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    }
}
.fullpage--onepage .grid-info--right {
    .logo.fixed {
        right: 40px;
        @include breakpoint(map-get($mediaqueries, small-down)) {
            right: 20px;
        }
    }
}
.fullpage--onepage .grid-info--left {
    .logo.fixed {
        left: 40px;
        @include breakpoint(map-get($mediaqueries, small-down)) {
            left: 20px;
        }
    }
}
.onepage-headline {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    b,strong {
        color: map-get($colors, "corporate");
        .color & {
            color: map-get($colors, "darkgrey");
        }
    }
    & > :first-child {
        margin-top: 0;
    }
    & > :last-child {
        margin-bottom: 0;
    }
    & > .button {
        margin-top: 25px;
    }
}
.onepage-video {
    position: relative;
    margin-bottom: 50px;
    @include breakpoint(map-get($mediaqueries, small-down)) {
        margin-bottom: 25px;
    }
    .fullpage--alignleft & {
        width: 100%;
        @include breakpoint(map-get($mediaqueries, xlarge-up)) {
            width: 75%;
        }  
    }
    .fullpage--aligncenter & {
        @include breakpoint(map-get($mediaqueries, small-only)) {
            width: 100%;
            margin-left: 0;
        }
        @include breakpoint(map-get($mediaqueries, medium-only)) {
            width: 50%;
            margin-left: 25%;
        }
        @include breakpoint(map-get($mediaqueries, large-only)) {
            width: 66.6666%;
            margin-left: 16.6666%;
        }
        @include breakpoint(map-get($mediaqueries, xlarge-only)) {
            width: 100%;
            margin-left: 0;
        }
        @include breakpoint(map-get($mediaqueries, xlarge-up)) {
            width: 50%;
            margin-left: 25%;
        }        
    }
    > div {
        width: 100%;
        padding-bottom: 56.25%;
    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}