.logo {
	position: relative;
    svg {
    	position: absolute;
    	width: 100%;
    	height: 100%;
        top: 0;
        left: 0;
    }
}
.logo-primcolor {
    fill: map-get($colors, "corporate");
    .color & {
        fill: map-get($colors, "darkgrey");
    }
    .fixed & {
        fill: map-get($colors, "corporate");
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        .color & {
            fill: map-get($colors, "corporate");
        }
    }
}
.logo-seccolor {
    fill: map-get($colors, "white");
    transition: fill .25s ease-out;
    .light & {
        fill: map-get($colors, "darkgrey");
    }
}
.logo-terccolor {
    fill: map-get($colors, "grey");
}
.grid-info.grid-info--blend {
    .logo {
        mix-blend-mode: difference;
    }
    .logo-primcolor {
        display: none;
    }
    .logo-seccolor {
        fill: white;
    }
    @include breakpoint(map-get($mediaqueries, small-down)) {
        .logo {
            mix-blend-mode: unset;
        }
    }
}
.grid-info.grid-info--blend + .grid-info {
    .logo-seccolor {
        display: none;
    }
}