.fullpage {
	position: relative;
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
	background-color: map-get($colors, "darkgrey");
	color: map-get($colors, "white");
	&.light {
		color: map-get($colors, "darkgrey");
		background-color: map-get($colors, "white");
	}
	&.grey {
		color: map-get($colors, "darkgrey");
		background-color: map-get($colors, "lightgrey");
	}
	&.color {
		color: map-get($colors, "white");
		background-color: map-get($colors, "corporate");
	}
	&.fullpage--autoheight {
		min-height: auto;
	}
    &.fullpage--aligncenter {
        text-align: center;
    }
    &.fullpage--home, &.fullpage--onepage.fullpage--fullheight {
        padding-bottom: 50px;
    }
}

.fullpage-wrapper {
	position: relative;
    width: calc(62.5% - 50px);
    margin: 0 calc(12.5% + 30px) 0 auto;
    padding: 50px 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
	@include breakpoint(map-get($mediaqueries, medium-down)) {
        justify-content: space-around;
        width: calc(75% - 60px);
        margin: 0 40px 0 auto;
	}
	@include breakpoint(map-get($mediaqueries, small-down)) {
        justify-content: space-around;
        width: calc(100% - 40px);
        margin: 0 20px;
	}
    @include breakpoint(map-get($mediaqueries, xlarge-up)) {
	    padding: 75px 0;
    }
}

.fullpage-background {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
    background-position: 50% 50%;
    background-size: cover;
	background-attachment: fixed;
	opacity: .2;
	object-fit: cover;
    object-position: center;
	@media (hover: none) and (pointer: coarse) {
		background-attachment: scroll; 
	}
	.fullpage:first-of-type & {
		background-attachment: initial;
	}
	.dark & {
		@supports(mix-blend-mode:multiply) {
			mix-blend-mode: multiply;
			opacity: 1;
		}
	}
	.light & {
		opacity: .1;
		@supports(mix-blend-mode:luminosity) {
			mix-blend-mode: luminosity;
			opacity: .1;
		}
	}
	.color & {
		opacity: .35;
		@supports(mix-blend-mode:multiply) {
			mix-blend-mode: multiply;
			opacity: .35;
		}
	}
	.grey & {
		opacity: .1;
		@supports(mix-blend-mode:luminosity) {
			mix-blend-mode: luminosity;
			opacity: .1;
		}
	}
}