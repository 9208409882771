html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

figcaption, figure, main {
  display: block;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: inherit;
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  color: #000;
  background-color: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
  display: inline-block;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details, menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template, [hidden] {
  display: none;
}

body, h1, h2, h3, h4, h5, h6, blockquote, p, pre, dl, dd, ol, ul, figure, hr, fieldset, legend {
  margin: 0;
  padding: 0;
}

li > ol, li > ul {
  margin-bottom: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset {
  border: 0;
  min-width: 0;
}

address, h1, h2, h3, h4, h5, h6, blockquote, p, pre, dl, ol, ul, figure, hr, table, fieldset {
  margin-bottom: 24px;
}

dd, ol, ul {
  margin-left: 24px;
}

@font-face {
  font-family: Material Icons;
  font-style: normal;
  font-weight: 400;
  src: url("material-icons.5d000324.woff2") format("woff2"), url("material-icons.130ad2eb.woff") format("woff");
}

.material-icons {
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  font-feature-settings: "liga";
  direction: ltr;
  font-family: Material Icons;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("roboto-v30-latin-regular.57844c26.woff2") format("woff2"), url("roboto-v30-latin-regular.32450484.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 400;
  src: local(""), url("roboto-v30-latin-italic.b9b4832d.woff2") format("woff2"), url("roboto-v30-latin-italic.3dee9232.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  src: local(""), url("roboto-v30-latin-900.5750b1bb.woff2") format("woff2"), url("roboto-v30-latin-900.42bebf18.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 900;
  src: local(""), url("roboto-v30-latin-900italic.21206037.woff2") format("woff2"), url("roboto-v30-latin-900italic.9d2492de.woff") format("woff");
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("roboto-mono-v22-latin-regular.7b49cb08.woff2") format("woff2"), url("roboto-mono-v22-latin-regular.b633e8c7.woff") format("woff");
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("roboto-mono-v22-latin-700.1d8e874e.woff2") format("woff2"), url("roboto-mono-v22-latin-700.7c2329eb.woff") format("woff");
}

html {
  width: 100%;
  height: 100%;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  line-height: 1.4;
}

body {
  width: 100%;
  height: 100%;
  transition: color .25s ease-out, background-color .25s ease-out;
  position: relative;
  overflow-x: hidden;
}

@media (width <= 640px) {
  body:after {
    content: "";
    mix-blend-mode: hard-light;
    background-image: linear-gradient(#000, #0000);
    width: 300%;
    height: 100px;
    position: fixed;
    top: 0;
    left: 0;
  }
}

main {
  width: 100%;
  height: 100%;
  position: relative;
}

a {
  color: #006aa6;
  font-weight: 700;
  text-decoration: none;
}

.color a {
  color: #282d30;
}

a p, a h1, a h2, a h3, a h4, a h5, a h6 {
  color: inherit;
}

p {
  margin-bottom: .875rem;
}

:not(li) > p:last-child {
  margin-bottom: 0;
}

ul, ol, li > ol, li > ul {
  margin-bottom: .875rem;
}

:not(li) > ul:last-child, :not(li) > ol:last-child, ul:last-child > li:last-child > p {
  margin-bottom: 0;
}

img, svg, iframe {
  width: 100%;
  max-width: 100%;
}

figure {
  margin: 1.5rem 0;
  padding: 0;
  position: relative;
}

figure.can-fullscreen {
  cursor: pointer;
}

figure.can-fullscreen.is-fullscreen {
  z-index: 999;
  background: #000c;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

figure.can-fullscreen.is-fullscreen .figure-close {
  display: block;
}

figure.can-fullscreen.is-fullscreen img {
  object-fit: contain;
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 100vh;
}

figure .figure-close {
  text-align: center;
  cursor: pointer;
  color: #fff;
  background: #006aa6;
  width: 40px;
  padding: 5px;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
}

figcaption {
  color: #282d30;
  background: #ffffff80;
  padding: 5px;
  font-style: italic;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

#CybotCookiebotDialog * {
  font-family: Roboto, sans-serif !important;
}

#CybotCookiebotDialogBodyContentTitle {
  text-transform: uppercase !important;
  letter-spacing: 2px !important;
  color: #006aa6 !important;
  font-family: Roboto Mono, monospace !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: .875rem;
  margin-bottom: .875rem;
}

h1 {
  margin: 50px 0;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.2;
}

@media (width <= 1024px) {
  h1 {
    font-size: 32px;
  }
}

@media (width <= 640px) {
  h1 {
    margin: 25px 0;
    font-size: 26px;
  }
}

h2 {
  margin: 25px 0;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.2;
}

@media (width <= 1024px) {
  h2 {
    font-size: 32px;
  }
}

@media (width <= 640px) {
  h2 {
    font-size: 26px;
  }
}

h2:first-child {
  margin-top: 0;
}

h3 {
  margin-top: 25px;
  font-size: 21px;
  font-weight: bold;
}

h3:first-child {
  margin-top: 0;
}

h4 + h3 {
  margin-top: -.875rem;
}

h4 {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #006aa6;
  font-family: Roboto Mono, monospace;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.2;
}

h3 + h4 {
  margin-top: -.875rem;
}

h4 + h1 {
  margin-top: -.5rem;
}

.color h4 {
  color: #282d30;
  font-weight: 700;
}

h5 {
  font-size: 1rem;
  font-weight: bold;
}

h6 {
  font-size: 1rem;
  font-weight: normal;
}

@keyframes fade-from-top {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-from-left {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.o-wrapper {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.o-wrapper:after {
  content: "" !important;
  clear: both !important;
  display: block !important;
}

.o-layout {
  margin: 0 0 0 -24px;
  padding: 0;
  font-size: 0;
  list-style: none;
  display: block;
}

.o-layout__item {
  box-sizing: border-box;
  vertical-align: top;
  width: 100%;
  padding-left: 24px;
  font-size: 1rem;
  display: inline-block;
}

.o-layout--flush {
  margin-left: 0;
}

.o-layout--flush > .o-layout__item {
  padding-left: 0;
}

.o-layout--tiny {
  margin-left: -6px;
}

.o-layout--tiny > .o-layout__item {
  padding-left: 6px;
}

.o-layout--small {
  margin-left: -12px;
}

.o-layout--small > .o-layout__item {
  padding-left: 12px;
}

.o-layout--large {
  margin-left: -48px;
}

.o-layout--large > .o-layout__item {
  padding-left: 48px;
}

.o-layout--huge {
  margin-left: -96px;
}

.o-layout--huge > .o-layout__item {
  padding-left: 96px;
}

.o-layout--middle > .o-layout__item {
  vertical-align: middle;
}

.o-layout--bottom > .o-layout__item {
  vertical-align: bottom;
}

.o-layout--stretch {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -ms-flexbox;
  display: flex;
}

.o-layout--stretch > .o-layout__item {
  display: -ms-flexbox;
  display: flex;
}

.o-layout--stretch.o-layout--center {
  justify-content: center;
}

.o-layout--stretch.o-layout--right {
  justify-content: flex-end;
}

.o-layout--stretch.o-layout--left {
  justify-content: flex-start;
}

.o-layout--center {
  text-align: center;
}

.o-layout--center > .o-layout__item {
  text-align: left;
}

.o-layout--right {
  text-align: right;
}

.o-layout--right > .o-layout__item, .o-layout--left, .o-layout--left > .o-layout__item {
  text-align: left;
}

.o-layout--reverse {
  direction: rtl;
}

.o-layout--reverse > .o-layout__item {
  direction: ltr;
}

.o-layout--auto > .o-layout__item {
  width: auto;
}

.o-media {
  display: block;
}

.o-media:after {
  content: "" !important;
  clear: both !important;
  display: block !important;
}

.o-media__img {
  float: left;
  margin-right: 24px;
}

.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}

.o-media__body, .o-media__body > :last-child {
  margin-bottom: 0;
}

.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

.o-media--tiny > .o-media__img {
  margin-right: 6px;
}

.o-media--tiny.o-media--reverse > .o-media__img {
  margin-left: 6px;
  margin-right: 0;
}

.o-media--small > .o-media__img {
  margin-right: 12px;
}

.o-media--small.o-media--reverse > .o-media__img {
  margin-left: 12px;
  margin-right: 0;
}

.o-media--large > .o-media__img {
  margin-right: 48px;
}

.o-media--large.o-media--reverse > .o-media__img {
  margin-left: 48px;
  margin-right: 0;
}

.o-media--huge > .o-media__img {
  margin-right: 96px;
}

.o-media--huge.o-media--reverse > .o-media__img {
  margin-left: 96px;
  margin-right: 0;
}

.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 24px;
  margin-right: 0;
}

.o-flag {
  border-spacing: 0;
  width: 100%;
  display: table;
}

.o-flag__img, .o-flag__body {
  vertical-align: middle;
  display: table-cell;
}

.o-flag__img {
  width: 1px;
  padding-right: 24px;
}

.o-flag__img > img {
  max-width: none;
}

.o-flag__body {
  width: auto;
}

.o-flag__body, .o-flag__body > :last-child {
  margin-bottom: 0;
}

.o-flag--flush > .o-flag__img {
  padding-left: 0;
  padding-right: 0;
}

.o-flag--tiny > .o-flag__img {
  padding-right: 6px;
}

.o-flag--tiny.o-flag--reverse > .o-flag__img {
  padding-left: 6px;
  padding-right: 0;
}

.o-flag--small > .o-flag__img {
  padding-right: 12px;
}

.o-flag--small.o-flag--reverse > .o-flag__img {
  padding-left: 12px;
  padding-right: 0;
}

.o-flag--large > .o-flag__img {
  padding-right: 48px;
}

.o-flag--large.o-flag--reverse > .o-flag__img {
  padding-left: 48px;
  padding-right: 0;
}

.o-flag--huge > .o-flag__img {
  padding-right: 96px;
}

.o-flag--huge.o-flag--reverse > .o-flag__img {
  padding-left: 96px;
  padding-right: 0;
}

.o-flag--reverse {
  direction: rtl;
}

.o-flag--reverse > .o-flag__img, .o-flag--reverse > .o-flag__body {
  direction: ltr;
}

.o-flag--reverse > .o-flag__img {
  padding-left: 24px;
  padding-right: 0;
}

.o-flag--top > .o-flag__img, .o-flag--top > .o-flag__body {
  vertical-align: top;
}

.o-flag--bottom > .o-flag__img, .o-flag--bottom > .o-flag__body {
  vertical-align: bottom;
}

.o-list-bare {
  margin-left: 0;
  list-style: none;
}

.o-list-bare__item {
  margin-left: 0;
}

.o-list-inline {
  margin-left: 0;
  list-style: none;
}

.o-list-inline__item {
  display: inline-block;
}

.o-box {
  padding: 24px;
  display: block;
}

.o-box:after {
  content: "" !important;
  clear: both !important;
  display: block !important;
}

.o-box > :last-child {
  margin-bottom: 0;
}

.o-box--flush {
  padding: 0;
}

.o-box--tiny {
  padding: 6px;
}

.o-box--small {
  padding: 12px;
}

.o-box--large {
  padding: 48px;
}

.o-box--huge {
  padding: 96px;
}

.o-block {
  text-align: center;
  display: block;
}

.o-block__img {
  margin-bottom: 24px;
}

.o-block--flush > .o-block__img {
  margin-bottom: 0;
}

.o-block--tiny > .o-block__img {
  margin-bottom: 6px;
}

.o-block--small > .o-block__img {
  margin-bottom: 12px;
}

.o-block--large > .o-block__img {
  margin-bottom: 48px;
}

.o-block--huge > .o-block__img {
  margin-bottom: 96px;
}

.o-block__body {
  display: block;
}

.o-block--right {
  text-align: right;
}

.o-block--left {
  text-align: left;
}

.o-ratio {
  display: block;
  position: relative;
}

.o-ratio:before {
  content: "";
  width: 100%;
  padding-bottom: 100%;
  display: block;
}

.o-ratio__content, .o-ratio > iframe, .o-ratio > embed, .o-ratio > object {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.o-ratio--2\:1:before {
  padding-bottom: 50%;
}

.o-ratio--4\:3:before {
  padding-bottom: 75%;
}

.o-ratio--16\:9:before {
  padding-bottom: 56.25%;
}

.o-ratio--img-contain > .o-ratio__content:before {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  margin: auto;
}

.o-crop {
  display: block;
  position: relative;
  overflow: hidden;
}

.o-crop__content {
  max-width: none;
  position: absolute;
  top: 0;
  left: 0;
}

.o-crop__content--left-top {
  left: 0;
}

.o-crop__content--left-center {
  top: 50%;
  transform: translateY(-50%);
}

.o-crop__content--left-bottom {
  top: auto;
  bottom: 0;
}

.o-crop__content--right-top {
  left: auto;
  right: 0;
}

.o-crop__content--right-center {
  top: 50%;
  left: auto;
  right: 0;
  transform: translateY(-50%);
}

.o-crop__content--right-bottom {
  inset: auto 0 0 auto;
}

.o-crop__content--center-top {
  left: 50%;
  transform: translateX(-50%);
}

.o-crop__content--center, .o-crop__content--center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.o-crop__content--center-bottom {
  top: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.o-crop--2\:1 {
  padding-bottom: 50%;
}

.o-crop--4\:3 {
  padding-bottom: 75%;
}

.o-crop--16\:9 {
  padding-bottom: 56.25%;
}

.o-crop--fill > .o-crop__content {
  min-width: 100%;
  min-height: 100%;
}

.grid {
  pointer-events: none;
  font-size: 0;
  position: absolute;
  inset: 0;
}

@media (width <= 640px) {
  .grid {
    display: none;
  }
}

.grid-background {
  width: calc(37.5% - 30px);
  height: 100%;
  position: absolute;
  overflow: hidden;
}

.grid-background:first-child {
  left: calc(12.5% + 30px);
}

.grid-background:nth-child(2) {
  left: calc(37.5% + 10px);
}

.grid-background:nth-child(3) {
  left: calc(62.5% - 10px);
}

@media (width <= 1024px) {
  .grid-background {
    width: calc(50% - 40px);
  }

  .grid-background:first-child {
    left: 40px;
  }

  .grid-background:nth-child(2) {
    left: calc(25% + 20px);
  }

  .grid-background:nth-child(3) {
    left: 50%;
  }
}

.grid-background > div {
  opacity: 0;
  clip-path: polygon(0% 0%, 0% 0%, 50% 100%, 0% 100%);
  background-position: 0%;
  background-size: cover;
  transition: all 1s ease-out;
  position: absolute;
  inset: 0;
  transform: translateX(-100%)scale(1);
}

.grid-background > div:after {
  content: "";
  background-color: #00000080;
  position: absolute;
  inset: 0;
}

.grid-background.active > div {
  opacity: 1;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  background-position: 50%;
  transform: translateX(0)scale(1.1);
}

.grid-line {
  border-left: 1px solid #282d30;
  width: calc(12.5% - 10px);
  height: 100%;
  transition: border-color .25s ease-out;
  display: inline-block;
  position: relative;
}

.light .grid-line {
  border-left: 1px solid #ddd;
}

.color .grid-line {
  border-left: 1px solid #006aa6;
}

.grey .grid-line {
  border-left: 1px solid #bbb;
}

@media (width <= 1024px) {
  .grid-line {
    width: calc(25% - 20px);
  }

  .grid-line:nth-child(2n) {
    display: none;
  }

  .grid-line:last-child {
    display: inline-block;
  }
}

.grid-line:first-child {
  border: none;
  width: 40px;
}

.grid-line:last-child {
  width: 40px;
}

.grid-info {
  text-transform: uppercase;
  letter-spacing: 2px;
  width: 150px;
  font-family: Roboto Mono, monospace;
  font-size: 13px;
  font-weight: normal;
  position: absolute;
}

.grid-info.grid-info--middle {
  text-align: center;
  bottom: 28px;
  left: calc(50% - 75px);
}

.grid-info.grid-info--middle a {
  animation: .6s ease-in-out infinite alternate bounce;
}

.grid-info.grid-info--middle:after {
  content: "";
  background: #006aa6;
  width: 1px;
  height: 25px;
  position: absolute;
  top: calc(100% + 3px);
  left: 50%;
}

.color .grid-info.grid-info--middle:after {
  background: #282d30;
}

.grid-info.grid-info--right {
  text-align: right;
  width: auto;
  bottom: 18px;
  right: 40px;
}

@media (width <= 640px) {
  .grid-info.grid-info--right {
    top: 20px;
    bottom: auto;
    right: 20px;
  }
}

.grid-info.grid-info--left {
  text-align: left;
  width: auto;
  bottom: 18px;
  left: 40px;
}

@media (width <= 640px) {
  .grid-info.grid-info--left {
    top: 20px;
    bottom: auto;
    left: 20px;
  }
}

.grid-info.grid-info--title {
  text-align: right;
  transform-origin: 100% 100%;
  top: 75px;
  right: 40px;
  transform: rotate(-90deg);
}

.grid-info.grid-info--title a[href] {
  color: #006aa6;
}

.color .grid-info.grid-info--title a[href] {
  color: #282d30;
}

@media (width <= 1024px) {
  .grid-info.grid-info--title {
    right: 0;
  }
}

@media (width <= 640px) {
  .grid-info.grid-info--title {
    text-align: left;
    top: 50px;
    left: 20px;
    right: auto;
    transform: none;
  }
}

@media (width >= 2001px) {
  .grid-info.grid-info--title {
    top: 100px;
  }
}

.grid-info a {
  color: gray;
  font-weight: normal;
  transition: all .35s ease-out;
  display: inline-block;
}

.color .grid-info a {
  color: #282d30;
  font-weight: bold;
}

.grid-info a:hover {
  color: #fff;
}

@keyframes bounce {
  from {
    transform: translateY(-10px);
  }

  to {
    transform: translateY(0);
  }
}

#loader, .loader {
  z-index: 9999;
  background: #282d30;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.loader-wrapper {
  justify-content: space-between;
  align-items: center;
  width: 300px;
  height: 100%;
  margin: 0 auto;
  animation: 1s cubic-bezier(.33, .1, 0, 1) infinite alternate progress-width;
  display: -ms-flexbox;
  display: flex;
}

.loader-particle {
  width: 18px;
  height: 18px;
  position: relative;
}

.loader-particle:first-child {
  transform-origin: 100% 100%;
  animation: 1s cubic-bezier(.33, .1, 0, 1) infinite alternate particle-left;
}

.loader-particle:last-child {
  transform-origin: 0 100%;
  animation: 1s cubic-bezier(.33, .1, 0, 1) infinite alternate particle-right;
}

.loader-particle:after, .loader-particle:before {
  content: "";
  background: #fff;
  width: 18px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
}

.loader-particle:after {
  transform-origin: 100% 0;
  right: 0;
  transform: skewX(19deg);
}

.loader-particle:before {
  transform-origin: 0 0;
  left: 0;
  transform: skewX(-19deg);
}

.loader-particle:first-child:before {
  transform: skewX(-31deg);
}

.loader-particle:last-child:after {
  transform: skewX(31deg);
}

.loader-progress {
  background: #006aa6;
  width: calc(100% - 60px);
  height: 18px;
  animation: 1s cubic-bezier(.33, .1, 0, 1) infinite alternate progress-height;
  position: relative;
}

.loader-progress:after, .loader-progress:before {
  content: "";
  background: #006aa6;
  width: 18px;
  height: 100%;
  display: block;
  position: absolute;
  bottom: 0;
}

.loader-progress:after {
  transform-origin: 100% 100%;
  animation: 1s cubic-bezier(.33, .1, 0, 1) infinite alternate progress-right;
  right: 0;
  transform: skewX(-19deg);
}

.loader-progress:before {
  transform-origin: 0 100%;
  animation: 1s cubic-bezier(.33, .1, 0, 1) infinite alternate progress-left;
  left: 0;
  transform: skewX(19deg);
}

.loader-text {
  text-align: center;
  transform-origin: top;
  width: 100%;
  animation: 1s cubic-bezier(.33, .1, 0, 1) infinite alternate text;
  position: absolute;
  top: calc(50% + 15px);
  left: 0;
}

@keyframes particle-left {
  from {
    opacity: .6;
    transform: rotate(19deg);
  }

  to {
    opacity: 1;
    transform: rotate(0);
  }
}

@keyframes particle-right {
  from {
    opacity: .6;
    transform: rotate(-19deg);
  }

  to {
    opacity: 1;
    transform: rotate(0);
  }
}

@keyframes progress-left {
  from {
    transform: skewX(0);
  }

  to {
    transform: skewX(19deg);
  }
}

@keyframes progress-right {
  from {
    transform: skewX(0);
  }

  to {
    transform: skewX(-19deg);
  }
}

@keyframes progress-width {
  from {
    width: 90px;
  }

  to {
    width: 240px;
  }
}

@keyframes progress-height {
  from {
    opacity: .6;
    height: 21px;
    top: 3px;
  }

  to {
    opacity: 1;
    height: 18px;
    top: 0;
  }
}

@keyframes text {
  from {
    opacity: .6;
    transform: scale(1);
  }

  to {
    opacity: 1;
    transform: scale(1.1);
  }
}

.button {
  text-transform: uppercase;
  letter-spacing: 2px;
  z-index: 2;
  text-align: center;
  cursor: pointer;
  background: none;
  border: none;
  min-width: 150px;
  padding: 10px;
  font-family: Roboto Mono, monospace;
  font-size: 13px;
  font-weight: normal;
  line-height: 18px;
  transition: all .35s ease-out;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.color .button {
  color: #fff;
}

.button:before {
  content: "";
  z-index: -1;
  transform-origin: 100% 100%;
  border: 1px solid #006aa6;
  border-right: none;
  width: calc(100% - 1px);
  transition: all .35s ease-out;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.color .button:before {
  border-color: #fff;
}

.button:after {
  content: "";
  z-index: -2;
  transform-origin: 100% 100%;
  border: 1px solid #006aa6;
  border-left: none;
  width: 50px;
  transition: all .35s ease-out;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.color .button:after {
  border-color: #fff;
}

.button.button--filled {
  color: #fff;
}

.button.button--filled:after, .button.button--filled:before {
  background: #006aa6;
}

.color .button.button--filled:after, .color .button.button--filled:before {
  background: #282d30;
}

.button.button--white {
  color: #fff;
}

.button.button--white:after, .button.button--white:before {
  border-color: #fff;
}

.button.button--corporate {
  color: #fff;
}

.button.button--corporate:before, .button.button--corporate:after {
  background: #006aa6;
  border-color: #006aa6;
}

.button.button--green {
  color: #fff;
}

.button.button--green:before, .button.button--green:after {
  background: #69a700;
  border-color: #69a700;
}

.button.button--red {
  color: #fff;
}

.button.button--red:before, .button.button--red:after {
  background: #a72b00;
  border-color: #a72b00;
}

.button.button--orange {
  color: #fff;
}

.button.button--orange:before, .button.button--orange:after {
  background: #a7a700;
  border-color: #a7a700;
}

.button.fixed {
  z-index: 15;
  color: #fff;
  animation-name: fade-from-top;
  animation-duration: .35s;
  animation-timing-function: ease-out;
  position: fixed;
  top: 25px;
  right: 25px;
}

.button.fixed:after, .button.fixed:before {
  background: #006aa6;
}

.color .button.fixed:after, .color .button.fixed:before {
  background: #282d30;
}

@media (width <= 640px) {
  .button.fixed {
    top: 20px;
    right: 20px;
  }
}

.button.button--fullwidth {
  width: calc(100% + 21px);
}

.button.button--fullwidth.button--simple {
  width: 100%;
}

@media (width <= 640px) {
  .button.button--fullwidth {
    width: 100%;
  }
}

.button.button--isfixed {
  position: fixed;
  top: 0;
  left: 0;
}

@media (width <= 640px) {
  .button:after {
    width: 100%;
  }
}

@media (width >= 641px) {
  .button {
    padding-right: 30px;
  }

  .button:before {
    width: calc(100% - 21px);
  }

  .button:after {
    right: 20px;
  }

  .button:hover, .button.hover {
    color: #fff;
    padding-left: 25px;
  }

  .button:hover:after, .button.hover:after {
    background: #006aa6;
    width: 120%;
    transform: skewX(-25deg);
  }

  .color .button:hover:after, .color .button.hover:after {
    background: #282d30;
  }

  .button.button--centered {
    padding: 10px;
  }

  .button.button--centered:before, .button.button--centered:after {
    width: 100%;
  }

  .button.button--centered:after {
    right: 0;
  }

  .button.button--centered:hover, .button.button--centered.hover {
    padding: 10px 30px;
  }

  .button.button--centered:hover:before, .button.button--centered.hover:before, .button.button--centered:hover:after, .button.button--centered.hover:after {
    transform: none;
  }

  .button.button--white {
    color: #006aa6 !important;
  }

  .button.button--white:before, .button.button--white:after {
    background: #fff !important;
  }

  .button.button--corporate {
    color: #fff !important;
  }

  .button.button--corporate:before, .button.button--corporate:after {
    background: #006aa6 !important;
  }

  .button.button--green {
    color: #fff !important;
  }

  .button.button--green:before, .button.button--green:after {
    background: #69a700 !important;
  }

  .button.button--red {
    color: #fff !important;
  }

  .button.button--red:before, .button.button--red:after {
    background: #a72b00 !important;
  }

  .button.button--orange {
    color: #fff !important;
  }

  .button.button--orange:before, .button.button--orange:after {
    background: #a7a700 !important;
  }

  .button.button--simple, .button.button--simple:hover, .button.button--simple.hover {
    padding-left: 10px;
    padding-right: 10px;
  }

  .button.button--simple:before, .button.button--simple:hover:before, .button.button--simple.hover:before {
    transform: none;
  }

  .button.button--simple:after, .button.button--simple:hover:after, .button.button--simple.hover:after {
    right: 0;
    transform: skewX(0);
  }

  .button.button--inverse {
    padding-left: 30px;
    padding-right: 10px;
  }

  .button.button--inverse:after {
    border: 1px solid #006aa6;
    border-right: none;
    left: 20px;
    right: auto;
  }

  .button.button--inverse:before {
    border: 1px solid #006aa6;
    border-left: none;
    left: 20px;
    right: 0;
  }

  .button.button--inverse:hover, .button.button--inverse.hover {
    color: #fff;
    padding-left: 20px;
    padding-right: 20px;
  }

  .button.button--inverse:hover:after, .button.button--inverse.hover:after {
    transform: skewX(25deg);
  }
}

.button-icon {
  float: left;
  height: 24px;
  transition: left .35s ease-out, right .35s ease-out;
  display: inline-block;
  position: absolute;
  top: calc(50% - 12px);
  left: 5px;
}

.button-icon + .button-text {
  text-align: left;
  max-width: 100%;
  padding-left: 25px;
  display: inline-block;
}

.button--fullwidth .button-icon + .button-text {
  text-align: center;
}

.button.button--inverse .button-icon {
  float: right;
  left: auto;
  right: 5px;
}

.button.button--inverse .button-icon + .button-text {
  padding-left: 5px;
  padding-right: 20px;
}

@media (width >= 641px) {
  .button:not(.button--simple):not(.button--inverse):hover .button-icon {
    left: 18px;
  }

  .button.button--inverse:hover .button-icon {
    right: 15px;
  }
}

.button-wrapper {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  display: -ms-flexbox;
  display: flex;
}

.button-wrapper .button {
  margin: 15px;
}

.nav {
  z-index: 10;
  width: 31px;
  position: absolute;
  top: 25px;
  bottom: 25px;
  left: 25px;
}

@media (width >= 641px) {
  .nav.fixed {
    animation-name: fade-from-left;
    animation-duration: .35s;
    animation-timing-function: ease-out;
    position: fixed;
  }

  .nav.fixed.nav--blend {
    mix-blend-mode: difference;
  }
}

.nav.nav--background {
  display: none;
}

@media (width <= 640px) {
  .nav {
    z-index: 4;
    animation: none;
    position: fixed;
    top: 20px;
    bottom: 20px;
    left: 20px;
  }

  .nav.nav--background + .nav--opened {
    mix-blend-mode: difference;
  }

  .nav.nav--background {
    z-index: 3;
    background: #fff;
    width: 200%;
    transition: transform .35s ease-out .35s;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-150%)skewX(18deg);
  }

  .nav.nav--background.color:not(.fixed) {
    background: #006aa6;
  }

  .nav.nav--background.nav--opened {
    z-index: 5;
    transition: transform .35s ease-out;
    transform: translateX(-60%)skewX(15deg);
  }

  .nav.nav--opened {
    z-index: 10;
  }
}

.nav-wrapper {
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
}

.nav-wrapper.nav-wrapper--lines {
  padding: 15px 0;
}

.nav-activearea {
  width: 150px;
  position: absolute;
  top: -25px;
  bottom: -25px;
  left: -25px;
}

@media (width <= 640px) {
  .nav-activearea {
    display: none;
  }
}

a[href].nav-button {
  color: #fff;
  padding: 10px 10px 10px 40px;
}

.nav-el {
  text-align: center;
  cursor: pointer;
  width: 100%;
  height: 25px;
  font-family: Roboto Mono, monospace;
  font-weight: bold;
  line-height: 25px;
  transition: transform .35s ease-out;
}

@media (width <= 640px) {
  .nav-el {
    transform: translateX(-100px);
  }

  .nav-el:first-child, .nav--opened .nav-el {
    transform: translateX(0);
  }
}

.nav-el .nav-logo {
  transition: transform .5s ease-in-out;
}

.nav-el .nav-logo svg {
  width: 31px;
  height: 25.5px;
}

.nav-el .nav-logo svg .logo-seccolor {
  fill: gray;
}

@supports (mix-blend-mode: difference) {
  .nav-el .nav-logo svg .logo-seccolor {
    fill: #fff;
  }
}

@media (width <= 640px) {
  .nav--opened .nav-el .nav-logo {
    transform-origin: 50% 40%;
    transform: rotateZ(360deg);
  }
}

.nav-el a {
  color: gray;
  width: 100%;
  padding: 8px 0;
  transition: color .35s ease-out;
  display: inline-block;
  position: relative;
}

.color:not(.fixed) .nav-el a {
  color: #282d30;
}

.nav-el span:first-child {
  width: 20px;
  display: inline-block;
}

.nav-el span:first-child svg {
  width: 20px;
  height: 16px;
}

.nav-el span:nth-child(2) {
  text-align: left;
  padding: 8px 0;
  display: inline-block;
  position: absolute;
  top: -3px;
  left: 100%;
}

.nav-el span:nth-child(3) {
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: left;
  color: gray;
  width: 0;
  padding: 8px 0 8px 30px;
  font-family: Roboto Mono, monospace;
  font-size: 13px;
  font-weight: normal;
  transition: width 0s .35s, color .35s ease-out;
  display: inline-block;
  position: absolute;
  top: -3px;
  left: 100%;
  overflow: hidden;
}

.color:not(.fixed) .nav-el span:nth-child(3) {
  color: #282d30;
}

.nav-el span:nth-child(3) > i {
  opacity: 0;
  font-style: normal;
  transition: opacity .35s ease-out, transform .35s ease-out;
  display: inline-block;
  transform: translateX(-100%);
}

.nav-el svg {
  fill: gray;
  transition: fill .35s ease-out;
}

.color:not(.fixed) .nav-el svg {
  fill: #282d30;
}

.nav--blend .nav-el svg, .nav--background + .nav--opened .nav-el svg {
  fill: #0000;
}

@supports (mix-blend-mode: difference) {
  .nav--blend .nav-el:hover svg, .nav--blend .nav-el.hover svg, .nav--blend .nav-el.active svg, .nav--background + .nav--opened .nav-el:hover svg, .nav--background + .nav--opened .nav-el.hover svg, .nav--background + .nav--opened .nav-el.active svg {
    fill: #fff;
  }
}

.nav-el:hover .nav-logo, .nav-el.hover .nav-logo {
  transform: rotateY(360deg);
}

.nav-el:hover a, .nav-el.hover a, .nav-el.active a {
  color: #006aa6;
}

.nav-el:hover svg, .nav-el.hover svg, .nav-el.active svg {
  fill: #006aa6;
}

.color:not(.fixed) .nav-el:hover a, .color:not(.fixed) .nav-el.hover a, .color:not(.fixed) .nav-el.active a {
  color: #fff;
}

.color:not(.fixed) .nav-el:hover svg, .color:not(.fixed) .nav-el.hover svg, .color:not(.fixed) .nav-el.active svg, .nav--background + .dark:not(.fixed) .nav-el:hover svg, .nav--background + .dark:not(.fixed) .nav-el.hover svg, .nav--background + .dark:not(.fixed) .nav-el.active svg {
  fill: #fff;
}

.nav--background + .light:not(.fixed) .nav-el:hover svg, .nav--background + .light:not(.fixed) .nav-el.hover svg, .nav--background + .light:not(.fixed) .nav-el.active svg, .nav--background + .color:not(.fixed) .nav-el:hover svg, .nav--background + .color:not(.fixed) .nav-el.hover svg, .nav--background + .color:not(.fixed) .nav-el.active svg {
  fill: #282d30;
}

@media (width <= 640px) {
  .nav--opened .nav-el span:nth-child(3) {
    width: 200px;
    transition: color .35s ease-out;
  }

  .nav--opened .nav-el span:nth-child(3) > i {
    opacity: 1;
    transform: translateX(0);
  }

  .nav--opened .nav-el.active span:nth-child(3) {
    color: #006aa6;
  }
}

@media (width >= 641px) {
  .nav-el.show span:nth-child(3) {
    width: 200px;
    transition: color .35s ease-out;
  }

  .nav-el.show span:nth-child(3) > i {
    opacity: 1;
    transform: translateX(0);
  }

  .nav-el:hover span:nth-child(3), .nav-el.hover span:nth-child(3), .nav-el.active span:nth-child(3) {
    width: 200px;
    transition: color .35s ease-out;
  }

  .nav-el:hover span:nth-child(3) > i, .nav-el.hover span:nth-child(3) > i, .nav-el.active span:nth-child(3) > i {
    opacity: 1;
    transform: translateX(0);
  }
}

.nav-el:hover span:nth-child(3), .nav-el.hover span:nth-child(3), .nav-el.active span:nth-child(3) {
  color: #006aa6;
}

.color:not(.fixed) .nav-el:hover span:nth-child(3), .color:not(.fixed) .nav-el.hover span:nth-child(3), .color:not(.fixed) .nav-el.active span:nth-child(3) {
  color: #fff;
}

@media (width <= 640px) {
  .nav-el a {
    padding: .40625rem .05rem;
  }

  .nav-el a:after {
    top: .40625rem;
    left: .05rem;
  }
}

.nav-line {
  background: gray;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 1px;
  transition: background .35s ease-out, -ms-flex-grow .25s linear, flex-grow .25s linear, transform .35s ease-out, opacity .15s ease-out;
}

@supports (mix-blend-mode: difference) {
  .nav-line {
    background: #fff;
  }
}

.nav-line[data-grow="0"] {
  -ms-flex-positive: 0;
  flex-grow: 0;
}

.nav-line[data-grow="1"] {
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.nav-line[data-grow="2"] {
  -ms-flex-positive: 2;
  flex-grow: 2;
}

.nav-line[data-grow="3"] {
  -ms-flex-positive: 3;
  flex-grow: 3;
}

.nav-line[data-grow="4"] {
  -ms-flex-positive: 4;
  flex-grow: 4;
}

.nav-line[data-grow="5"] {
  -ms-flex-positive: 5;
  flex-grow: 5;
}

.nav-line[data-grow="6"] {
  -ms-flex-positive: 6;
  flex-grow: 6;
}

.nav-line[data-grow="7"] {
  -ms-flex-positive: 7;
  flex-grow: 7;
}

.nav-line[data-grow="8"] {
  -ms-flex-positive: 8;
  flex-grow: 8;
}

.nav-line[data-grow="9"] {
  -ms-flex-positive: 9;
  flex-grow: 9;
}

.nav-line[data-grow="10"] {
  -ms-flex-positive: 10;
  flex-grow: 10;
}

.nav-line.inactive {
  background: none;
}

@media (width <= 640px) {
  .nav-line {
    opacity: 0;
    transform: translateX(-100px);
  }

  .nav--opened .nav-line {
    opacity: 1;
    transition: background .35s ease-out, -ms-flex-grow .25s linear, flex-grow .25s linear, transform .35s ease-out, opacity .55s ease-out;
    transform: translateX(0);
  }

  .nav-line.inactive {
    background: #fff3;
  }
}

#mobilenav {
  display: none;
}

@media (width <= 640px) {
  #mobilenav {
    z-index: 15;
    font-weight: bold;
    display: block;
    position: fixed;
    top: 20px;
    left: 20px;
  }
}

.simplenav {
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  width: 31px;
  transition: transform .35s ease-out, opacity .35s ease-out;
  position: fixed;
  bottom: 100px;
  left: 25px;
  transform: translateY(-100%);
}

.simplenav.simplenav--opened {
  pointer-events: all;
  opacity: 1;
  transition: transform .35s ease-out .35s, opacity .35s ease-out .35s;
  transform: translateY(0);
}

@media (width <= 640px) {
  .simplenav {
    bottom: 20px;
    left: 20px;
  }
}

.simplenav-background {
  z-index: 5;
  transform-origin: 100% 100%;
  background: #fff;
  width: 350px;
  transition: transform .35s ease-out .35s;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%)skewX(25deg);
  box-shadow: 1px 0 5px #00000080;
}

.simplenav-background.simplenav--opened {
  transition: transform .35s ease-out;
  transform: translateX(0)skewX(15deg);
}

@media (width <= 640px) {
  .simplenav-background {
    width: 95vw;
  }
}

.simplenav-line {
  z-index: 6;
  opacity: 0;
  background: #ddd;
  width: 1px;
  height: 100vh;
  transition: transform .35s ease-out, opacity .35s ease-out;
  position: fixed;
  bottom: 0;
  left: 40px;
  transform: translateY(-100%);
}

.simplenav-line.simplenav--opened {
  opacity: 1;
  transition: transform .35s ease-out .35s, opacity .35s ease-out .35s;
  transform: translateY(0);
}

@media (width <= 640px) {
  .simplenav-line {
    left: 35px;
  }
}

.simplenav-button-background {
  z-index: 4;
  pointer-events: none;
  background: linear-gradient(22.5deg, #000 0%, #fff0 50%);
  width: 360px;
  height: 180px;
  position: fixed;
  bottom: 0;
  left: 0;
}

@media (width <= 640px) {
  .simplenav-button-background {
    display: none;
  }
}

.simplenav-button {
  z-index: 6;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 25px;
  font-family: Roboto Mono, monospace;
  font-size: 13px;
  font-weight: bold;
  line-height: 25.5px;
  transition: color .5s ease-in-out;
  position: fixed;
  bottom: 0;
  left: 0;
}

@media (width <= 640px) {
  .simplenav-button {
    padding: 0;
    top: 20px;
    bottom: auto;
    left: 20px;
  }
}

.simplenav-button.simplenav--opened {
  color: #282d30;
}

.simplenav-logo {
  float: left;
  margin-right: 9px;
  transition: transform .5s ease-in-out;
}

.simplenav-logo svg {
  width: 31px;
  height: 25.5px;
}

.simplenav-logo svg .logo-seccolor {
  fill: #fff;
  transition: fill .5s ease-in-out;
}

.simplenav--opened .simplenav-logo {
  transform: rotate(360deg);
}

.simplenav--opened .simplenav-logo svg .logo-seccolor {
  fill: #282d30;
}

.simplenav-wrapper {
  width: 100%;
  padding-bottom: 10px;
  position: relative;
}

.simplenav-el {
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  cursor: pointer;
  width: 100%;
  height: 50px;
  font-family: Roboto Mono, monospace;
  font-size: 13px;
  font-weight: bold;
  line-height: 50px;
  transition: transform .35s ease-out;
}

.simplenav-el a {
  color: gray;
  width: 100%;
  padding: 8px 0;
  transition: color .35s ease-out;
  display: inline-block;
  position: relative;
}

.color:not(.fixed) .simplenav-el a {
  color: #282d30;
}

.simplenav-el span:first-child {
  width: 20px;
  display: inline-block;
}

.simplenav-el span:first-child svg {
  width: 20px;
  height: 16px;
}

.simplenav-el span:nth-child(2) {
  text-align: left;
  padding: 8px 0;
  display: inline-block;
  position: absolute;
  top: -3px;
  left: 100%;
}

.simplenav-el svg .seccolor, .simplenav-el svg .primcolor {
  fill: gray;
  transition: fill .35s ease-out;
}

.simplenav-el:hover a, .simplenav-el.hover a, .simplenav-el.active a {
  color: #006aa6;
}

.simplenav-el:hover svg .seccolor, .simplenav-el.hover svg .seccolor, .simplenav-el.active svg .seccolor {
  fill: #282d30;
}

.simplenav-el:hover svg .primcolor, .simplenav-el.hover svg .primcolor, .simplenav-el.active svg .primcolor {
  fill: #006aa6;
}

@media (width <= 640px) {
  .simplenav--opened .simplenav-el span:nth-child(3) {
    width: 200px;
    transition: color .35s ease-out;
  }

  .simplenav--opened .simplenav-el span:nth-child(3) > i {
    opacity: 1;
    transform: translateX(0);
  }

  .simplenav--opened .simplenav-el.active span:nth-child(3) {
    color: #006aa6;
  }

  .simplenav-el a {
    padding: .40625rem .05rem;
  }

  .simplenav-el a:after {
    top: .40625rem;
    left: .05rem;
  }
}

.fullpage--blog .logo {
  transition: all .35s ease-out .35s;
}

.logo.simplenav--opened {
  transition: all .35s ease-out;
}

@media (width >= 641px) {
  .logo.simplenav--opened {
    transform: translateX(calc(350px - 26.79vh));
  }
}

.fullpage {
  color: #fff;
  background-color: #282d30;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  overflow: hidden;
}

.fullpage.light {
  color: #282d30;
  background-color: #fff;
}

.fullpage.grey {
  color: #282d30;
  background-color: #ddd;
}

.fullpage.color {
  color: #fff;
  background-color: #006aa6;
}

.fullpage.fullpage--autoheight {
  min-height: auto;
}

.fullpage.fullpage--aligncenter {
  text-align: center;
}

.fullpage.fullpage--home, .fullpage.fullpage--onepage.fullpage--fullheight {
  padding-bottom: 50px;
}

.fullpage-wrapper, .blogarticle-wrapper, .blog-wrapper, .onepage-wrapper, .home-wrapper {
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: calc(62.5% - 50px);
  margin: 0 calc(12.5% + 30px) 0 auto;
  padding: 50px 0;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

@media (width <= 1024px) {
  .fullpage-wrapper, .blogarticle-wrapper, .blog-wrapper, .onepage-wrapper, .home-wrapper {
    justify-content: space-around;
    width: calc(75% - 60px);
    margin: 0 40px 0 auto;
  }
}

@media (width <= 640px) {
  .fullpage-wrapper, .blogarticle-wrapper, .blog-wrapper, .onepage-wrapper, .home-wrapper {
    justify-content: space-around;
    width: calc(100% - 40px);
    margin: 0 20px;
  }
}

@media (width >= 2001px) {
  .fullpage-wrapper, .blogarticle-wrapper, .blog-wrapper, .onepage-wrapper, .home-wrapper {
    padding: 75px 0;
  }
}

.fullpage-background {
  opacity: .2;
  object-fit: cover;
  object-position: center;
  background-position: 50%;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

@media (hover: none) and (pointer: coarse) {
  .fullpage-background {
    background-attachment: scroll;
  }
}

.fullpage:first-of-type .fullpage-background {
  background-attachment: initial;
}

@supports (mix-blend-mode: multiply) {
  .dark .fullpage-background {
    mix-blend-mode: multiply;
    opacity: 1;
  }
}

.light .fullpage-background {
  opacity: .1;
}

@supports (mix-blend-mode: luminosity) {
  .light .fullpage-background {
    mix-blend-mode: luminosity;
    opacity: .1;
  }
}

.color .fullpage-background {
  opacity: .35;
}

@supports (mix-blend-mode: multiply) {
  .color .fullpage-background {
    mix-blend-mode: multiply;
    opacity: .35;
  }
}

.grey .fullpage-background {
  opacity: .1;
}

@supports (mix-blend-mode: luminosity) {
  .grey .fullpage-background {
    mix-blend-mode: luminosity;
    opacity: .1;
  }
}

.home-wrapper .logo {
  width: 100%;
  margin-bottom: 50px;
  padding-bottom: 28.83%;
}

@media (width >= 1601px) {
  .home-wrapper .logo {
    width: 80%;
    padding-bottom: 23.06%;
  }
}

@media (width >= 641px) {
  .home-wrapper .logo {
    transition: opacity 4s ease-out;
  }

  .home-wrapper .logo.transparent {
    opacity: 0;
    transition: opacity 1s ease-out;
  }
}

@media (width <= 640px) {
  .home-wrapper {
    padding-top: 100px;
  }
}

.home-badge {
  max-width: 220px;
  position: absolute;
  top: 25px;
  right: 40px;
}

@media (width <= 640px) {
  .home-badge {
    max-width: 160px;
    padding-bottom: 20px;
    position: relative;
    inset: auto auto auto 20px;
  }

  .fullpage--aligncenter .home-badge {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (width >= 2001px) {
  .home-badge {
    max-width: 260px;
  }
}

.home-headline {
  width: 60%;
}

@media (width <= 1600px) {
  .home-headline {
    width: 80%;
  }
}

@media (width <= 1024px) {
  .home-headline {
    width: 100%;
  }
}

.home-headline > h1 {
  font-size: 2vw;
}

@media (width <= 1024px) {
  .home-headline > h1 {
    font-size: 2.75vw;
  }
}

@media (width <= 640px) {
  .home-headline > h1 {
    font-size: 4vw;
  }
}

@media (width >= 2001px) {
  .home-headline > h1 {
    font-size: 1.6666vw;
  }
}

.home-headline > h1 b, .home-headline > h1 strong {
  color: #006aa6;
}

.home-subline {
  font-size: 1.5vw;
  font-style: italic;
}

@media (width <= 1024px) {
  .home-subline {
    font-size: 2vw;
  }
}

@media (width <= 640px) {
  .home-subline {
    font-size: 3.25vw;
  }
}

@media (width >= 1601px) {
  .home-subline {
    font-size: 1.25vw;
  }
}

@media (width >= 2001px) {
  .home-subline {
    font-size: 1vw;
  }
}

.home-subline b, .home-subline strong {
  font-weight: normal;
  text-decoration: underline;
}

.home-subline > span {
  padding: 0 2px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.home-subline > span > span {
  transition: all .5s ease-out;
  display: inline-block;
  transform: translateY(-100%);
}

.home-subline > span > span.active {
  transform: translateY(0);
}

.home-subline > span > span.transition {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(100%);
}

.onepage-wrapper {
  padding: 100px 0;
}

.fullpage--autoheight .onepage-wrapper {
  padding: 100px 0 50px;
}

@media (width <= 1600px) {
  .onepage-wrapper {
    width: calc(62.5% - 50px);
    margin-right: calc(12.5% + 30px);
  }

  .fullpage--aligncenter .onepage-wrapper {
    width: calc(75% - 60px);
    margin-right: calc(12.5% + 30px);
  }
}

@media (width <= 1024px) {
  .onepage-wrapper {
    width: calc(75% - 60px);
    margin-right: 40px;
  }

  .fullpage--aligncenter .onepage-wrapper {
    width: calc(100% - 80px);
    margin-right: 40px;
  }
}

@media (width <= 640px) {
  .onepage-wrapper {
    width: calc(100% - 40px);
    margin-right: 20px;
    padding: 75px 0;
  }

  .fullpage--aligncenter .onepage-wrapper {
    width: calc(100% - 40px);
    margin-right: 20px;
  }

  .fullpage--autoheight .onepage-wrapper {
    padding: 75px 0 25px;
  }
}

@media (width >= 1601px) {
  .onepage-wrapper {
    width: calc(50% - 40px);
    margin-right: calc(25% + 20px);
  }
}

@media (width >= 2001px) {
  .onepage-wrapper {
    padding: 150px 0;
  }

  .fullpage--autoheight .onepage-wrapper {
    padding: 150px 0 50px;
  }
}

.fullpage--onepage .grid-info--right, .fullpage--onepage .grid-info--left {
  top: 22px;
}

@media (width <= 1024px) {
  .fullpage--onepage .grid-info--right, .fullpage--onepage .grid-info--left {
    top: 32px;
  }
}

@media (width <= 640px) {
  .fullpage--onepage .grid-info--right, .fullpage--onepage .grid-info--left {
    top: 18px;
  }
}

@media (width <= 420px) {
  .fullpage--onepage .grid-info--right, .fullpage--onepage .grid-info--left {
    top: 21px;
  }
}

.fullpage--onepage .grid-info--right .logo, .fullpage--onepage .grid-info--left .logo {
  z-index: 15;
  width: 200px;
  height: 40px;
  position: relative;
}

@media (width <= 1024px) {
  .fullpage--onepage .grid-info--right .logo, .fullpage--onepage .grid-info--left .logo {
    width: 150px;
    height: 30px;
  }
}

@media (width <= 420px) {
  .fullpage--onepage .grid-info--right .logo, .fullpage--onepage .grid-info--left .logo {
    width: 120px;
    height: 24px;
  }
}

.fullpage--onepage .grid-info--right .logo.fixed, .fullpage--onepage .grid-info--left .logo.fixed {
  animation-name: fade-from-top;
  animation-duration: .35s;
  animation-timing-function: ease-out;
  position: fixed;
  top: 25px;
}

@media (width <= 640px) {
  .fullpage--onepage .grid-info--right .logo.fixed, .fullpage--onepage .grid-info--left .logo.fixed {
    top: 20px;
  }

  .fullpage--aligncenter .grid-info--left {
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
  }
}

.fullpage--onepage .grid-info--right .logo.fixed {
  right: 40px;
}

@media (width <= 640px) {
  .fullpage--onepage .grid-info--right .logo.fixed {
    right: 20px;
  }
}

.fullpage--onepage .grid-info--left .logo.fixed {
  left: 40px;
}

@media (width <= 640px) {
  .fullpage--onepage .grid-info--left .logo.fixed {
    left: 20px;
  }
}

.onepage-headline {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.onepage-headline b, .onepage-headline strong {
  color: #006aa6;
}

.color .onepage-headline b, .color .onepage-headline strong {
  color: #282d30;
}

.onepage-headline > :first-child {
  margin-top: 0;
}

.onepage-headline > :last-child {
  margin-bottom: 0;
}

.onepage-headline > .button {
  margin-top: 25px;
}

.onepage-video {
  margin-bottom: 50px;
  position: relative;
}

@media (width <= 640px) {
  .onepage-video {
    margin-bottom: 25px;
  }
}

.fullpage--alignleft .onepage-video {
  width: 100%;
}

@media (width >= 2001px) {
  .fullpage--alignleft .onepage-video {
    width: 75%;
  }
}

@media (width >= 420px) and (width <= 640px) {
  .fullpage--aligncenter .onepage-video {
    width: 100%;
    margin-left: 0;
  }
}

@media (width >= 640px) and (width <= 1024px) {
  .fullpage--aligncenter .onepage-video {
    width: 50%;
    margin-left: 25%;
  }
}

@media (width >= 1024px) and (width <= 1600px) {
  .fullpage--aligncenter .onepage-video {
    width: 66.6666%;
    margin-left: 16.6666%;
  }
}

@media (width >= 1600px) and (width <= 2000px) {
  .fullpage--aligncenter .onepage-video {
    width: 100%;
    margin-left: 0;
  }
}

@media (width >= 2001px) {
  .fullpage--aligncenter .onepage-video {
    width: 50%;
    margin-left: 25%;
  }
}

.onepage-video > div {
  width: 100%;
  padding-bottom: 56.25%;
}

.onepage-video iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-wrapper {
  text-align: center;
  width: calc(62.5% - 50px);
  margin-right: calc(12.5% + 30px);
  padding: 50px 0;
  display: block;
}

@media (width <= 1024px) {
  .blog-wrapper {
    width: calc(75% - 60px);
    margin-right: 40px;
  }
}

@media (width <= 640px) {
  .blog-wrapper {
    width: calc(100% - 40px);
    margin-right: 20px;
  }
}

@media (width >= 1601px) {
  .blog-wrapper {
    padding: 100px;
  }
}

.blogarticle-wrapper {
  text-align: left;
  width: calc(37.5% - 30px);
  margin-right: calc(25% + 20px);
  padding: 50px 0;
  display: block;
}

@media (width <= 1024px) {
  .blogarticle-wrapper {
    width: calc(75% - 60px);
    margin-right: 40px;
  }
}

@media (width <= 640px) {
  .blogarticle-wrapper {
    width: calc(100% - 40px);
    margin-right: 20px;
  }
}

@media (width >= 1601px) {
  .blogarticle-wrapper {
    padding: 100px 0;
  }
}

.fullpage--blog .grid-info--right, .fullpage--blog .grid-info--left {
  top: 22px;
}

@media (width <= 1024px) {
  .fullpage--blog .grid-info--right, .fullpage--blog .grid-info--left {
    top: 32px;
  }
}

@media (width <= 640px) {
  .fullpage--blog .grid-info--right, .fullpage--blog .grid-info--left {
    top: 18px;
  }
}

@media (width <= 420px) {
  .fullpage--blog .grid-info--right, .fullpage--blog .grid-info--left {
    top: 21px;
  }
}

.fullpage--blog .grid-info--right .logo, .fullpage--blog .grid-info--left .logo {
  z-index: 15;
  width: 200px;
  height: 40px;
  position: relative;
}

@media (width <= 1024px) {
  .fullpage--blog .grid-info--right .logo, .fullpage--blog .grid-info--left .logo {
    width: 150px;
    height: 30px;
  }
}

@media (width <= 420px) {
  .fullpage--blog .grid-info--right .logo, .fullpage--blog .grid-info--left .logo {
    width: 120px;
    height: 24px;
  }
}

.fullpage--blog .grid-info--right .logo.fixed, .fullpage--blog .grid-info--left .logo.fixed {
  animation-name: fade-from-top;
  animation-duration: .35s;
  animation-timing-function: ease-out;
  position: fixed;
  top: 25px;
}

@media (width <= 640px) {
  .fullpage--blog .grid-info--right .logo.fixed, .fullpage--blog .grid-info--left .logo.fixed {
    top: 20px;
    right: 20px;
  }
}

.fullpage--blog .grid-info--left {
  top: 22px;
}

@media (width <= 1024px) {
  .fullpage--blog .grid-info--left {
    top: 32px;
  }
}

@media (width <= 640px) {
  .fullpage--blog .grid-info--left {
    top: 18px;
    left: auto;
    right: 20px;
  }
}

@media (width <= 420px) {
  .fullpage--blog .grid-info--left {
    top: 21px;
  }
}

.fullpage--blog .grid-info--left .logo {
  z-index: 15;
  width: 200px;
  height: 40px;
  position: relative;
}

@media (width <= 1024px) {
  .fullpage--blog .grid-info--left .logo {
    width: 150px;
    height: 30px;
  }
}

@media (width <= 420px) {
  .fullpage--blog .grid-info--left .logo {
    width: 120px;
    height: 24px;
  }
}

.fullpage--blog .grid-info--left .logo.fixed {
  animation-name: fade-from-top;
  animation-duration: .35s;
  animation-timing-function: ease-out;
  position: fixed;
  top: 25px;
  left: 40px;
}

@media (width <= 640px) {
  .fullpage--blog .grid-info--left .logo.fixed {
    top: 20px;
    left: auto;
    right: 20px;
  }
}

.logo {
  position: relative;
}

.logo svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.logo-primcolor {
  fill: #006aa6;
}

.color .logo-primcolor {
  fill: #282d30;
}

.fixed .logo-primcolor {
  fill: #006aa6;
}

@media (width <= 640px) {
  .color .logo-primcolor {
    fill: #006aa6;
  }
}

.logo-seccolor {
  fill: #fff;
  transition: fill .25s ease-out;
}

.light .logo-seccolor {
  fill: #282d30;
}

.logo-terccolor {
  fill: gray;
}

.grid-info.grid-info--blend .logo {
  mix-blend-mode: difference;
}

.grid-info.grid-info--blend .logo-primcolor {
  display: none;
}

.grid-info.grid-info--blend .logo-seccolor {
  fill: #fff;
}

@media (width <= 640px) {
  .grid-info.grid-info--blend .logo {
    mix-blend-mode: unset;
  }
}

.grid-info.grid-info--blend + .grid-info .logo-seccolor {
  display: none;
}

.module {
  width: 100%;
  padding: 25px 0;
  position: relative;
}

@media (width >= 1601px) {
  .module {
    padding: 50px 0;
  }
}

.slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  outline: none;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  border: 1px solid #0000;
  height: auto;
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

.module-doubleslider {
  position: relative;
}

@media (width <= 640px) {
  .module-doubleslider {
    width: calc(100% + 40px);
    margin: 0 -20px;
  }
}

.module-doubleslider .module-text-headline {
  margin-bottom: 75px;
}

@media (width <= 640px) {
  .module-doubleslider .module-text-headline {
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
  }
}

.module-doubleslider > div {
  position: relative;
}

.module-doubleslider-innerwrapper {
  -ms-flex-direction: row;
  flex-direction: row;
  place-items: flex-start;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

.module-doubleslider-logowrapper {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  margin-top: 25px;
  position: relative;
}

@media (width >= 641px) {
  .module-doubleslider-logowrapper[data-slidesToShow="2"] {
    margin-right: -33.3333%;
  }
}

@media (width >= 1025px) {
  .module-doubleslider-logowrapper[data-slidesToShow="2"], .module-doubleslider-logowrapper[data-slidesToShow="3"] {
    margin-right: -20%;
  }
}

@media (width <= 640px) {
  .module-doubleslider-logowrapper {
    padding: 0 40px;
  }
}

.module-doubleslider-logowrapper .slick-track {
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.module-doubleslider-textwrapper {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  margin-top: 25px;
  position: relative;
}

.module-doubleslider-logowrapper + .module-doubleslider-textwrapper {
  margin-top: 50px;
}

@media (width <= 640px) {
  .module-doubleslider-logowrapper + .module-doubleslider-textwrapper {
    margin-top: 25px;
  }
}

.module-doubleslider-logo img {
  object-fit: contain;
  max-height: 75px;
}

[data-slidesToShow="2"] .module-doubleslider-logo > div {
  padding-right: 33.3333%;
}

@media (width <= 1024px) {
  [data-slidesToShow="2"] .module-doubleslider-logo > div {
    padding-right: 50%;
  }
}

@media (width <= 640px) {
  [data-slidesToShow="2"] .module-doubleslider-logo > div {
    padding: 0 40px;
  }
}

[data-slidesToShow="3"] .module-doubleslider-logo > div {
  padding-right: 50%;
}

@media (width <= 1024px) {
  [data-slidesToShow="3"] .module-doubleslider-logo > div {
    padding: 0 10%;
  }
}

@media (width <= 640px) {
  [data-slidesToShow="3"] .module-doubleslider-logo > div {
    padding: 0 20%;
  }
}

.module-doubleslider-logo-image {
  filter: grayscale();
  opacity: .7;
  cursor: pointer;
  transition: all .5s ease-out;
}

.slick-current .module-doubleslider-logo-image, .module-doubleslider-logo:hover .module-doubleslider-logo-image {
  filter: grayscale(0);
  opacity: 1;
}

.module-doubleslider-text {
  float: left;
  width: 100%;
  transition: transform .5s ease-out;
  transform: scale(.9);
}

.slick-current .module-doubleslider-text {
  transition-delay: .75s;
  transform: scale(1);
}

.module-doubleslider-text > div {
  -ms-flex-direction: row;
  flex-direction: row;
  place-items: flex-start;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
}

@media (width <= 640px) {
  .module-doubleslider-text > div {
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.module-doubleslider-arrow {
  z-index: 4;
  cursor: pointer;
  width: 40px;
  height: 100%;
  transition: top .35s ease-in-out;
  position: absolute;
  top: 0;
}

.module-doubleslider-arrow.right {
  right: -40px;
}

.module-doubleslider-arrow.left {
  left: -40px;
}

@media (width <= 640px) {
  .module-doubleslider-arrow.right {
    right: 15px;
  }

  .module-doubleslider-arrow.left {
    left: 15px;
  }
}

.module-doubleslider-arrow:after {
  content: "";
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  top: 0;
}

.module-doubleslider-arrow.right:after {
  transform-origin: 100% 0;
  border-top: 1px solid gray;
  border-right: 1px solid gray;
  right: 0;
  transform: rotate(45deg);
}

.module-doubleslider-arrow.left:after {
  transform-origin: 0 0;
  border-top: 1px solid gray;
  border-left: 1px solid gray;
  left: 0;
  transform: rotate(-45deg);
}

.module-doubleslider-arrow.middle:after {
  top: 50%;
}

.module-doubleslider-text-image {
  z-index: 2;
  filter: grayscale();
  width: 15%;
  margin-right: 5%;
  transition: all .5s ease-out .75s;
  position: relative;
}

.slick-current .module-doubleslider-text-image {
  filter: grayscale(0);
  transition-delay: .75s;
}

@media (width <= 1024px) {
  .module-doubleslider-text-image {
    width: 25%;
    margin-right: 8.33333%;
  }
}

@media (width <= 640px) {
  .module-doubleslider-text-image {
    -ms-flex-order: 2;
    order: 2;
    width: 25%;
    margin-top: -75px;
    margin-right: 0;
  }
}

.module-doubleslider-text-text {
  width: 100%;
  padding-left: 20%;
  position: relative;
}

.module-doubleslider-text-text:after {
  content: "";
  background: gray;
  width: 10%;
  height: 1px;
  position: absolute;
  top: 10px;
  left: 0;
}

.module-doubleslider-text-text p {
  color: gray;
}

.module-doubleslider-text-text p b, .module-doubleslider-text-text p strong {
  color: #282d30;
}

.dark .module-doubleslider-text-text p b, .dark .module-doubleslider-text-text p strong {
  color: #fff;
}

.grey .module-doubleslider-text-text p {
  color: #282d30;
}

.module-doubleslider-text-text h3 {
  font-size: inherit;
  position: relative;
}

.module-doubleslider-text-text .button {
  margin-top: 1.5rem;
}

@media (width <= 1024px) {
  .module-doubleslider-text-text {
    padding-left: 33.3333%;
  }

  .module-doubleslider-text-text:after {
    width: 16.6667%;
  }
}

@media (width <= 640px) {
  .module-doubleslider-text-text {
    padding: 0 20px;
  }

  .module-doubleslider-text-text:after {
    display: none;
  }

  .module-doubleslider-text-text h3 {
    padding-left: 15%;
  }

  .module-doubleslider-text-text h3:after {
    content: "";
    background: gray;
    width: 10%;
    height: 1px;
    position: absolute;
    top: 10px;
    left: 0;
  }

  .module-doubleslider-text-text h4 {
    padding-left: 15%;
  }

  .module-doubleslider-text-text .button {
    margin-top: 20px;
  }
}

@media (width >= 1601px) {
  .module-doubleslider-text-text {
    padding-right: 20%;
  }
}

.module-doubleslider-text-image + .module-doubleslider-text-text {
  width: 80%;
  margin-top: 2.5%;
  padding-left: 0;
}

.module-doubleslider-text-image + .module-doubleslider-text-text:after {
  display: none;
}

.module-doubleslider-text-image + .module-doubleslider-text-text h3 {
  padding-left: 15%;
}

.module-doubleslider-text-image + .module-doubleslider-text-text h3:after {
  content: "";
  background: gray;
  width: 10%;
  height: 1px;
  position: absolute;
  top: 10px;
  left: 0;
}

.module-doubleslider-text-image + .module-doubleslider-text-text h4 {
  padding-left: 15%;
}

@media (width <= 1024px) {
  .module-doubleslider-text-image + .module-doubleslider-text-text {
    width: 66.6667%;
    margin-top: 4%;
  }
}

@media (width <= 640px) {
  .module-doubleslider-text-image + .module-doubleslider-text-text {
    width: 100%;
    margin-top: 0;
    margin-bottom: 20px;
    padding: 0 20px;
  }

  .module-doubleslider-text-image + .module-doubleslider-text-text h3 {
    padding-left: calc(25% + 20px);
    transform: translateY(10px);
  }

  .module-doubleslider-text-image + .module-doubleslider-text-text h4 {
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    height: 15px;
    padding-left: calc(25% + 20px);
    transform: translateY(10px);
  }

  .module-doubleslider-text-image + .module-doubleslider-text-text .button {
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    max-width: calc(75% - 50px);
    position: absolute;
    bottom: -35px;
    left: calc(25% + 30px);
    transform: translateY(100%);
  }
}

.module-graphic {
  position: relative;
  overflow: hidden;
}

.module-graphic-wrapper {
  -ms-flex-direction: row;
  flex-direction: row;
  place-items: flex-start;
  width: 200%;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

@media (width <= 640px) {
  .module-graphic-wrapper {
    width: 400%;
  }
}

.module-graphic-wrapper.animate {
  transition: transform .75s ease-out;
  transform: translateX(-20%);
}

@media (width <= 1024px) {
  .module-graphic-wrapper.animate {
    transform: translateX(-16.6666%);
  }
}

@media (width <= 640px) {
  .module-graphic-wrapper.animate {
    transform: translateX(-25%);
  }
}

.module-graphic-element {
  width: 10%;
  margin-right: 10%;
}

.module-graphic-element:last-child {
  margin-right: 0;
}

@media (width <= 1024px) {
  .module-graphic-element {
    width: 11.1111%;
    margin-right: 5.55556%;
  }

  .module-graphic-element:first-child {
    margin-left: 2.77778%;
  }
}

@media (width <= 640px) {
  .module-graphic-element {
    width: 16.6667%;
    margin-right: 8.33333%;
  }

  .module-graphic-element:first-child {
    margin-left: 4.16667%;
  }
}

.module-graphic-element-head {
  text-align: center;
  opacity: .8;
  transition: all .75s ease-out;
  display: block;
}

.module-graphic-element-head h3 {
  text-align: center;
  margin-top: 0;
}

.module-graphic-element-head h4 {
  color: gray;
  margin-bottom: 0;
  transition: color .75s ease-out;
}

.module-graphic-element.active .module-graphic-element-head, .module-graphic-element:hover .module-graphic-element-head {
  opacity: 1;
}

.module-graphic-element.active .module-graphic-element-head h4, .module-graphic-element:hover .module-graphic-element-head h4 {
  color: #006aa6;
}

@media (width <= 1024px) {
  .module-graphic-element-head {
    margin-left: -25%;
    margin-right: -25%;
  }
}

.module-graphic-element-line {
  width: 100%;
  display: block;
  position: relative;
}

.module-graphic-element-line:before {
  content: "";
  border-top: 1px solid #ddd;
  width: 200%;
  display: block;
  position: absolute;
  top: -1px;
  left: 0;
}

@media (width <= 1024px) {
  .module-graphic-element-line:before {
    width: 150%;
    left: -25%;
  }
}

@media (width <= 640px) {
  .module-graphic-element-line:before {
    width: 150%;
    left: -25%;
  }
}

.module-graphic-element-line:after {
  content: "";
  z-index: 2;
  opacity: 0;
  border-top: 1px solid #282d30;
  width: 0%;
  transition: width 5s ease-out, opacity .75s ease-out;
  display: block;
  position: absolute;
  top: -1px;
  right: -150%;
}

@media (width <= 1024px) {
  .module-graphic-element-line:after {
    right: -100%;
  }
}

.module-graphic-element.active .module-graphic-element-line:after {
  opacity: 1;
  width: 200%;
  transition: width 5.5s ease-out;
  left: 50%;
}

@media (width <= 1024px) {
  .module-graphic-element.active .module-graphic-element-line:after {
    width: 150%;
  }
}

.module-graphic-element-point {
  z-index: 2;
  width: 22px;
  height: 18px;
  display: block;
  position: relative;
  left: 50%;
}

.module-graphic-element-point svg {
  fill: gray;
  stroke: #0000;
  stroke-width: 1px;
  width: 20px;
  height: 16px;
  transition: all .35s ease-out;
  transform: translate(-50%, -50%);
}

.module-graphic-element.active .module-graphic-element-point svg, .module-graphic-element:hover .module-graphic-element-point svg {
  fill: #006aa6;
  stroke: #282d30;
  width: 22px;
  height: 18px;
}

.module-graphic-element-icon {
  margin-top: -20px;
  display: block;
}

.module-graphic-element-icon svg {
  stroke: gray;
  stroke-width: 1px;
  fill: #0000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  transition: all .35s ease-out;
}

.module-graphic-element-icon svg .fillit {
  transition: all .5s ease-out;
}

.module-graphic-element.active .module-graphic-element-icon svg {
  stroke: #282d30;
}

.module-graphic-element.active .module-graphic-element-icon svg .fillit {
  fill: #006aa6;
}

.module-image {
  padding: 25px 0;
  position: relative;
}

.module-image:after {
  content: "";
  clear: both;
  display: table;
}

@media (width >= 1601px) {
  .module-image:last-child {
    min-height: 18vw;
  }
}

.module-image-img {
  float: left;
  filter: grayscale(50%);
  width: 15%;
  margin-right: 5%;
  padding-bottom: 27%;
  transition: filter .35s ease-out;
  position: relative;
  overflow: hidden;
}

.module-image-img:hover {
  filter: grayscale(0);
}

.module-image:nth-of-type(2n) .module-image-img {
  float: right;
  margin-left: 5%;
  margin-right: 0;
}

@media (width <= 1024px) {
  .module-image-img {
    width: 25%;
    margin-right: 8.33333%;
    padding-bottom: 45%;
  }

  .module-image:nth-of-type(2n) .module-image-img {
    margin-left: 8.33333%;
    margin-right: 0;
  }
}

@media (width <= 640px) {
  .module-image-img {
    width: calc(50% + 40px);
    padding-bottom: 120%;
    position: absolute;
    top: 0;
    left: -40px;
  }

  .module-image:nth-of-type(2n) .module-image-img {
    left: auto;
    right: -40px;
  }
}

@media (width >= 1601px) {
  .module-image-img {
    position: absolute;
    top: 25px;
    left: 0;
  }

  .module-image:nth-of-type(2n) .module-image-img {
    left: auto;
    right: 0;
  }
}

.module-image-img svg {
  width: 100%;
  height: 100%;
  position: absolute;
}

.module-image-badges {
  overflow: hidden;
}

.module-image-badge {
  filter: grayscale(50%);
  float: left;
  object-fit: contain;
  width: calc(25% - .875rem);
  max-width: 90px;
  height: auto;
  margin: 0 .875rem .875rem 0;
  transition: filter .35s ease-out;
}

@media (width <= 1024px) {
  .module-image-badge {
    width: calc(50% - .875rem);
  }
}

.module-image:nth-of-type(2n) .module-image-badge {
  float: right;
  margin: 0 0 .875rem .875rem;
}

.module-image-badge:hover {
  filter: grayscale(0);
}

.module-image-text {
  float: left;
  width: 80%;
  margin-top: 2.5%;
}

.module-image:nth-of-type(2n) .module-image-text {
  text-align: right;
  float: right;
}

@media (width <= 1024px) {
  .module-image-text {
    width: 66.6667%;
    margin-top: 5%;
  }
}

@media (width <= 640px) {
  .module-image-text {
    float: none;
    width: 100%;
    margin-top: 105%;
  }
}

@media (width >= 1601px) {
  .module-image-text {
    padding-left: 20%;
  }

  .module-image:nth-of-type(2n) .module-image-text {
    padding-left: 0;
    padding-right: 20%;
  }
}

.module-image-text p {
  color: gray;
}

.module-linklist {
  position: relative;
}

.module-linklist-link {
  color: #006aa6;
  cursor: pointer;
  width: 100%;
  margin: 0;
  padding: 10px 0 10px 10px;
  transition: all .5s;
  display: block;
  position: relative;
}

.color .module-linklist-link {
  color: #fff;
}

.color .module-linklist-link:after {
  background: #282d30;
}

.module-linklist-link:after {
  content: "";
  background: gray;
  width: 1px;
  height: 25px;
  transition: all .25s;
  position: absolute;
  top: 10px;
  left: 0;
}

.module-linklist-link:hover, .module-linklist-link.active {
  color: #282d30;
  padding-left: 20px;
}

.module-linklist-link:hover:after, .module-linklist-link.active:after {
  background: #282d30;
  width: 3px;
}

.dark .module-linklist-link:hover, .color .module-linklist-link:hover, .dark .module-linklist-link.active, .color .module-linklist-link.active {
  color: #fff;
}

.dark .module-linklist-link:hover:after, .color .module-linklist-link:hover:after, .dark .module-linklist-link.active:after, .color .module-linklist-link.active:after {
  background: #fff;
}

@media (width >= 1025px) {
  .module-linklist-link {
    padding-left: 20%;
  }

  .module-linklist-link:after {
    width: 15%;
    height: 1px;
    top: 19px;
    left: 0;
  }

  .module-linklist-link:hover, .module-linklist-link.active {
    padding-left: 22%;
  }

  .module-linklist-link:hover:after, .module-linklist-link.active:after {
    background: #006aa6;
    width: 15%;
    height: 3px;
    top: 18px;
  }
}

@media (width >= 1601px) {
  .module-linklist-link {
    padding-right: 20%;
  }
}

.module-text {
  width: 100%;
}

.module-text-headline {
  width: 60%;
}

.module-text-headline b, .module-text-headline strong {
  color: #006aa6;
}

@media (width <= 1600px) {
  .module-text-headline {
    width: 80%;
  }
}

@media (width <= 1024px) {
  .module-text-headline {
    width: 100%;
  }
}

.module-text-body {
  color: gray;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.module-text-body > div > :not(figure):first-child {
  margin-top: 0;
}

.module-text-headline + .module-text-body {
  margin-top: 50px;
}

.color .module-text-body {
  color: #fff;
}

.color .module-text-body:after {
  background: #282d30;
}

.grey .module-text-body {
  color: #282d30;
}

@media (width >= 1025px) {
  .module-text-body {
    padding-left: 20%;
  }

  .module-text-body:after {
    content: "";
    background: gray;
    width: 15%;
    height: 1px;
    position: absolute;
    top: 10px;
    left: 0;
  }
}

@media (width >= 1601px) {
  .module-text-body {
    padding-right: 20%;
  }
}

@media (width >= 1025px) {
  .module-text--1column .module-text-body, .module-text--2column .module-text-body {
    padding-left: 0;
  }

  .module-text--1column .module-text-body:after, .module-text--2column .module-text-body:after {
    display: none;
  }

  .module-text--2column .module-text-body {
    columns: 2;
    column-gap: 3.25%;
  }
}

@media (width >= 1601px) {
  .module-text--2column .module-text-body {
    padding-right: 22.5%;
  }
}

.onepage-wrapper .module-text-body {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

.creator-wrapper .module-text-body {
  width: 100%;
  max-width: 100%;
  padding: 0;
}

.creator-wrapper .module-text-body:after {
  display: none;
}

.module-text-video {
  float: left;
  width: 47.5%;
  min-height: 10px;
  margin-right: 5%;
  position: relative;
}

.module-text-video > div {
  width: 100%;
  padding-bottom: 56.25%;
}

.module-text-video iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.module-text-video + div {
  float: left;
  width: 47.5%;
}

@media (width <= 1024px) {
  .module-text-video {
    float: none;
    width: 100%;
    margin-bottom: 25px;
    margin-right: 0;
  }

  .module-text-video + div {
    float: none;
    width: 100%;
  }
}

.module-text-image {
  float: left;
  width: 120px;
  max-width: calc(33.3333% - 25px);
  margin: 0 25px 25px 0;
}

@media (width >= 1025px) {
  .module-text-image {
    max-width: calc(25% - 25px);
  }
}

@media (width >= 1601px) {
  .module-text-image {
    max-width: calc(33.3333% - 25px);
  }
}

.module-text-image--300 {
  max-width: 300px;
  margin: 0;
}

.module-text-image--icon {
  max-width: 120px;
  margin: 0 0 -25px;
}

.module-text-bigimage {
  text-align: center;
  width: 100%;
  margin: 1.75rem 0;
}

.module-text-bigimage img {
  max-width: 600px;
}

.module-text-button {
  width: 100%;
  margin-top: 50px;
  padding-left: 20%;
}

@media (width <= 1024px) {
  .module-text-button {
    padding-left: 0;
  }
}

.module-text--1column .module-text-button, .module-text--2column .module-text-button {
  padding-left: 0;
}

.module-text-button.module-text-button--fullwidth {
  margin: 0;
  padding-right: 20%;
}

@media (width <= 1600px) {
  .module-text-button.module-text-button--fullwidth {
    padding-right: 0;
  }
}

.module-textlist {
  counter-reset: number;
  width: 100%;
}

.module-textlist-text {
  color: gray;
  margin: 25px 0;
  overflow: hidden;
}

.grey .module-textlist-text {
  color: #282d30;
}

@media (width >= 1601px) {
  .module-textlist-text {
    padding-right: 20%;
  }
}

.onepage-wrapper .module-textlist-text {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

.module-textlist-list {
  color: gray;
  width: 100%;
  margin: 50px 0;
  position: relative;
}

.grey .module-textlist-list {
  color: #282d30;
}

.module-textlist-head {
  color: #282d30;
  cursor: pointer;
  counter-increment: number;
  width: 100%;
  margin: 0;
  padding: 25px 0 10px 10px;
  transition: color .35s ease-out;
  position: relative;
}

@media (width >= 1025px) {
  .module-textlist-head {
    padding-left: 20%;
  }
}

@media (width >= 1601px) {
  .module-textlist-head {
    padding-right: 20%;
  }
}

.dark .module-textlist-head {
  color: #fff;
}

@media (any-hover: hover) {
  .module-textlist-head:hover {
    color: #006aa6;
  }
}

.module-textlist-head.active {
  color: #006aa6;
}

.module-textlist--unordered .module-textlist-head:after {
  content: "";
  background: gray;
  width: 1px;
  height: 29px;
  transition: width .35s ease-out, height .35s ease-out, opacity .35s ease-out;
  position: absolute;
  top: 25px;
  left: 0;
}

@media (any-hover: hover) {
  .module-textlist--unordered .module-textlist-head:hover:after {
    background: #006aa6;
    width: 3px;
    height: 29px;
  }
}

.module-textlist--unordered .module-textlist-head.active:after {
  background: #006aa6;
  width: 3px;
  height: 29px;
}

@media (width >= 1025px) {
  .module-textlist--unordered .module-textlist-head:after {
    width: 10%;
    height: 1px;
    top: 39px;
    left: 0;
  }

  .module-textlist--unordered .module-textlist-head.active:after {
    background: #006aa6;
    width: 15%;
    height: 3px;
    top: 38px;
  }
}

@media (width >= 1025px) and (any-hover: hover) {
  .module-textlist--unordered .module-textlist-head:hover:after {
    background: #006aa6;
    width: 15%;
    height: 3px;
    top: 38px;
  }
}

.module-textlist--ordered .module-textlist-head:after {
  content: counters(number, ".", decimal-leading-zero);
  text-indent: -70px;
  letter-spacing: 2px;
  opacity: .35;
  background: #006aa6;
  width: 0;
  height: 3px;
  font-family: Roboto Mono, monospace;
  font-size: 3rem;
  font-weight: normal;
  line-height: 0;
  transition: width .35s ease-out, height .35s ease-out, opacity .35s ease-out, font-size .35s ease-out, line-height .35s ease-out;
  position: absolute;
  top: 36px;
  left: 70px;
}

@media (width <= 1024px) {
  .module-textlist--ordered .module-textlist-head {
    padding-left: 35px;
  }

  .module-textlist--ordered .module-textlist-head:after {
    left: 40px;
  }

  .module-textlist--ordered .module-textlist-head.active:after {
    font-size: 6rem;
    top: 56px;
  }
}

@media (width >= 1025px) and (any-hover: hover) {
  .module-textlist--ordered .module-textlist-head:hover:after {
    opacity: 1;
    width: calc(15% - 60px);
  }
}

@media (width >= 1025px) {
  .module-textlist--ordered .module-textlist-head.active:after {
    opacity: 1;
    width: calc(15% - 60px);
  }
}

@media (width <= 1024px) {
  .module-textlist--icons .module-textlist-head {
    padding-left: 35px;
  }

  .module-textlist--imgs .module-textlist-head {
    padding-left: 50px;
  }
}

.module-textlist-img {
  width: 45px;
  position: absolute;
  top: 25px;
  left: 0;
}

@media (width >= 1025px) {
  .module-textlist-img {
    width: 15%;
  }
}

@media (width >= 1601px) {
  .module-textlist-img {
    width: 15%;
  }
}

@media (width >= 1025px) {
  .module-textlist-img {
    top: 16px;
  }

  .module-textlist-img img {
    transform-origin: 0 0;
    filter: grayscale();
    width: auto;
    max-height: 50px;
    transition: transform .35s ease-out;
  }
}

@media (width >= 1025px) and (any-hover: hover) {
  .module-textlist-head:hover .module-textlist-img img {
    filter: none;
    transform: scale(1.15);
  }
}

@media (width >= 1025px) {
  .module-textlist-head.active .module-textlist-img img {
    filter: none;
    transform: scale(1.15);
  }
}

.module-textlist-icon {
  position: absolute;
  top: 25px;
  left: 0;
}

@media (width >= 1025px) {
  .module-textlist-icon {
    top: 16px;
  }

  .module-textlist-icon .material-icons {
    transform-origin: 0;
    font-size: 42px;
    line-height: 42px;
    transition: transform .35s ease-out;
  }
}

@media (width >= 1025px) and (any-hover: hover) {
  .module-textlist-head:hover .module-textlist-icon .material-icons {
    transform: scale(1.2);
  }
}

@media (width >= 1025px) {
  .module-textlist-head.active .module-textlist-icon .material-icons {
    transform: scale(1.2);
  }
}

.module-textlist-body {
  color: gray;
  transition: line-height .35s ease-out, opacity .5s ease-out;
  display: none;
  overflow: hidden;
}

@media (width >= 1025px) {
  .module-textlist-body {
    padding-left: 20%;
  }
}

@media (width >= 1601px) {
  .module-textlist-body {
    padding-right: 20%;
  }
}

.module-textlist--allactive .module-textlist-body {
  display: block;
}

.grey .module-textlist-body {
  color: #282d30;
}

.module-cards {
  position: relative;
  overflow: hidden;
}

.module-cards-wrapper {
  margin: 0 -25px;
  overflow: hidden;
}

@supports ((-ms-flex-wrap: wrap) or (flex-wrap: wrap)) {
  .module-cards-wrapper {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: flex-start;
    display: -ms-flexbox;
    display: flex;
  }

  .module-cards--equal .module-cards-wrapper {
    text-align: center;
    justify-content: space-around;
  }
}

@media (width <= 1024px) {
  .module-cards-wrapper {
    margin: 0 -13px;
  }

  @supports ((-ms-flex-wrap: wrap) or (flex-wrap: wrap)) {
    .module-cards--equal .module-cards-wrapper {
      justify-content: space-around;
    }
  }
}

@media (width <= 640px) {
  .module-cards-wrapper {
    margin: 0;
  }
}

.module-cards-element {
  float: left;
  width: calc(25% - 50px);
  margin: 25px;
  position: relative;
}

.module-cards:not(.module-cards--withoutcards) .module-cards-element {
  color: #fff;
  background-color: #006aa6;
  overflow: hidden;
}

.module-cards--equal .module-cards-element {
  width: calc(33.3333% - 50px);
}

@media (width <= 1600px) {
  .module-cards-element {
    width: calc(50% - 50px);
  }

  .module-cards--equal .module-cards-element {
    width: calc(33.3333% - 50px);
  }
}

@media (width <= 1024px) {
  .module-cards-element {
    width: calc(50% - 26px);
    margin: 13px;
  }

  .module-cards--big .module-cards-element {
    width: calc(100% - 26px);
  }

  .module-cards--equal .module-cards-element {
    width: calc(50% - 26px);
  }
}

@media (width <= 640px) {
  .module-cards-element {
    width: 100%;
    margin: 25px 0 0;
  }

  .module-cards--equal .module-cards-element {
    width: 100%;
  }
}

@media (width >= 1025px) {
  .module-cards--big .module-cards-element {
    width: calc(50% - 50px);
  }
}

@media (width >= 2001px) {
  .module-cards--equal .module-cards-element {
    width: calc(25% - 50px);
  }
}

@supports ((-ms-flex-wrap: wrap) or (flex-wrap: wrap)) {
  .module-cards-element {
    float: none;
  }
}

.module-cards-text {
  font-weight: initial;
  padding: 25px 0;
  transition: all .35s cubic-bezier(.57, .26, .28, 1);
}

.light .module-cards.module-cards--withoutcards .module-cards-text {
  color: gray;
}

.light .module-cards.module-cards--withoutcards .module-cards-text h3, .grey .module-cards.module-cards--withoutcards .module-cards-text {
  color: #282d30;
}

.color .module-cards.module-cards--withoutcards .module-cards-text {
  color: #fff;
}

.module-cards:not(.module-cards--withoutcards) .module-cards-text {
  color: #fff;
  padding: 25px;
}

.module-cards:not(.module-cards--withoutcards) .module-cards-element:hover .module-cards-text {
  padding-top: 15px;
  padding-bottom: 35px;
}

@media (any-hover: none) {
  .module-cards:not(.module-cards--withoutcards) .module-cards-text {
    padding-top: 15px;
    padding-bottom: 35px;
  }
}

@media (width <= 800px) {
  .module-cards:not(.module-cards--withoutcards) .module-cards-text h3 {
    word-break: break-all;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
}

.module-cards-img {
  transition: all .35s cubic-bezier(.57, .26, .28, 1);
  position: relative;
}

.module-cards-img img {
  display: block;
}

.module-cards:not(.module-cards--withoutcards) .module-cards-img {
  clip-path: polygon(0% 0%, 100% 0%, 100% 81.612%, 0% 100%);
}

.module-cards:not(.module-cards--withoutcards) .module-cards-img:after {
  content: "";
  padding-bottom: 100%;
  display: block;
}

.module-cards:not(.module-cards--withoutcards) .module-cards-img img {
  transform-origin: center;
  width: 100%;
  transition: all .35s cubic-bezier(.57, .26, .28, 1);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.module-cards:not(.module-cards--withoutcards) .module-cards-element:hover .module-cards-img {
  clip-path: polygon(0% 30%, 100% 0%, 100% 70%, 0% 100%);
}

.module-cards:not(.module-cards--withoutcards) .module-cards-element:hover .module-cards-img img {
  opacity: .75;
  transform: translateY(10px)scale(1.1);
}

@media (any-hover: none) {
  .module-cards:not(.module-cards--withoutcards) .module-cards-img {
    clip-path: polygon(0% 30%, 100% 0%, 100% 70%, 0% 100%);
  }

  .module-cards:not(.module-cards--withoutcards) .module-cards-img img {
    opacity: .75;
    transform: translateY(10px)scale(1.1);
  }
}

.module-cards-button {
  transform-origin: center;
  justify-content: center;
  align-items: center;
  width: 105%;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  left: -2.5%;
  transform: rotate(-16deg);
}

.module-cards-button:after {
  content: "";
  padding-bottom: 50%;
  display: block;
}

.module-cards-button .button, .module-cards-button .button:hover {
  color: #006aa6;
  opacity: 0;
  width: 100%;
  padding: 10px;
  transition: all .35s cubic-bezier(.57, .26, .28, 1);
  overflow: visible;
  transform: translateX(-100%);
}

.module-cards-element:hover .module-cards-button .button, .module-cards-element:hover .module-cards-button .button:hover {
  opacity: 1;
  transform: translateX(0);
}

@media (any-hover: none) {
  .module-cards-button .button, .module-cards-button .button:hover {
    opacity: 1;
    transform: translateX(0);
  }
}

.module-cards-button .button:before, .module-cards-button .button:hover:before {
  display: none;
}

.module-cards-button .button:after, .module-cards-button .button:hover:after {
  transform-origin: center;
  background: #fff;
  border: none;
  width: 100%;
  right: 0;
  transform: skewX(-16deg);
}

.module-cards-img + .module-cards-button {
  width: calc(100% - 50px);
  left: 25px;
}

.module-cards-img + .module-cards-button:after {
  padding-bottom: calc(100% + 50px);
}

.module-cards-img + .module-cards-button .button, .module-cards-img + .module-cards-button .button:hover {
  color: #fff;
  width: auto;
}

.module-cards-img + .module-cards-button .button:after, .module-cards-img + .module-cards-button .button:hover:after {
  background: #006aa6;
}

@media (any-hover: none) {
  .module-cards-button:first-child {
    width: 100%;
    padding: 10px;
    top: auto;
    bottom: 0;
    left: 0;
    transform: none;
  }

  .module-cards-button:first-child:after {
    padding-bottom: 0;
  }

  .module-cards-button:first-child .button:after, .module-cards-button:first-child .button:hover:after {
    transform: none;
  }
}

.module-cards.module-cards--withoutcards .module-cards-element .module-cards-img {
  max-width: 300px;
  margin: 0 auto;
  padding: 0 25px;
  transition: all .35s cubic-bezier(.57, .26, .28, 1) .1s;
}

.module-cards.module-cards--withoutcards .module-cards-element .module-cards-text {
  max-width: 300px;
  margin: 0 auto;
  transition: all .35s cubic-bezier(.57, .26, .28, 1) 50ms;
}

.module-cards.module-cards--withoutcards .module-cards-element:hover .module-cards-img {
  transition-delay: 0s;
  transform: translateY(-10px);
}

.module-cards.module-cards--withoutcards .module-cards-element:hover .module-cards-text {
  transition-delay: 50ms;
  transform: translateY(-10px);
}

.module-iframe {
  position: relative;
  overflow: hidden;
}

.module-iframe iframe {
  width: 100%;
  min-width: 100%;
}

.module-iframe-video {
  width: 100%;
  max-width: 70vh;
  margin: 0 auto;
  position: relative;
}

.module-iframe-video > div {
  width: 100%;
  padding-bottom: 56.25%;
}

.module-iframe-video iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.module-thumbnails {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-bottom: 0;
  display: -ms-flexbox;
  display: flex;
}

@media (width >= 1025px) {
  .module-thumbnails {
    width: 140%;
    margin-left: -20%;
  }
}

@media (width >= 1601px) {
  .module-thumbnails {
    padding-bottom: 25px;
  }
}

.fullpage--onepage .module-thumbnails {
  width: 100%;
  margin-left: 0;
  padding-top: 0;
}

.module-thumbnails .module-text-headline {
  text-align: center;
  width: 100%;
}

.module-thumbnail {
  text-align: center;
  width: 25%;
  padding: 0 4% 25px;
  transition: filter .35s ease-out;
}

.module-thumbnail img {
  max-width: 120px;
}

.module-thumbnail.module-thumbnail--grayscale {
  filter: grayscale();
}

.module-thumbnail.module-thumbnail--inverted {
  filter: grayscale() invert();
}

.module-thumbnail:hover {
  filter: unset;
}

@media (width >= 1025px) {
  .module-thumbnail {
    width: 33.3333%;
    padding: 0 5% 25px;
  }

  .fullpage--onepage .module-thumbnail {
    width: calc(12.5% - 10px);
    padding: 0 1%;
  }
}

@media (width >= 1601px) {
  .module-thumbnail {
    width: 14.2857%;
    padding: 0 1% 25px;
  }

  .fullpage--onepage .module-thumbnail {
    width: calc(12.5% - 10px);
    padding: 0 1%;
  }
}

@media (width <= 640px) {
  .module-thumbnail {
    width: 33.3333%;
    min-width: 100px;
  }

  .module-thumbnail img {
    max-width: 90px;
    margin: 10px 0;
  }
}

.module-button {
  margin: 0;
  padding: 0 0 25px;
}

.module-button--centered {
  text-align: center;
}

@media (width >= 1601px) {
  .module-button--centered {
    width: 80%;
  }
}

@media (width >= 1025px) {
  .module-button--multiple .button {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.module-socialmedia {
  color: gray;
  width: 100%;
  padding: 25px 0;
  position: relative;
  overflow: hidden;
}

.color .module-socialmedia {
  color: #fff;
}

.color .module-socialmedia:after {
  background: #282d30;
}

.grey .module-socialmedia {
  color: #282d30;
}

@media (width >= 1025px) {
  .module-socialmedia {
    padding-left: 20%;
  }

  .module-socialmedia:after {
    content: "";
    background: gray;
    width: 15%;
    height: 1px;
    position: absolute;
    top: 37px;
    left: 0;
  }
}

@media (width >= 1601px) {
  .module-socialmedia {
    padding-right: 20%;
  }
}

.module-socialmedia--plain .module-socialmedia {
  padding-left: 0;
}

.module-socialmedia--plain .module-socialmedia:after, .module-socialmedia--plainextended .module-socialmedia:after {
  display: none;
}

.module-socialmedia-icon {
  color: #282d30;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  transition: color .35s ease-out;
  display: inline-block;
}

.module-socialmedia-icon img, .module-socialmedia-icon svg {
  width: 100%;
  height: 100%;
  display: block;
}

.dark .module-socialmedia-icon {
  color: #fff;
}

.dark .module-socialmedia-icon:hover {
  color: #006aa6;
}

.grey .module-socialmedia-icon {
  color: #fff;
}

.grey .module-socialmedia-icon:hover {
  color: #006aa6;
}

.color .module-socialmedia-icon {
  color: #fff;
}

.color .module-socialmedia-icon:hover {
  color: #282d30;
}

@media (width <= 1024px) {
  .module-socialmedia-icon {
    width: 20px;
    height: 20px;
  }
}

.wmBox_overlay {
  z-index: 999;
  background: #ffffff80;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.wmBox_centerWrap {
  width: 100%;
  height: 100%;
  position: absolute;
}

.wmBox_centerer {
  background: #fff;
  width: calc(100% - 100px);
  max-width: 800px;
  height: calc(100% - 100px);
  margin: 50px auto 0;
  padding: 25px;
  position: relative;
  box-shadow: 0 0 5px #000;
}

@media (width <= 1024px) {
  .wmBox_centerer {
    width: calc(100% - 50px);
    height: calc(100% - 50px);
    margin: 25px auto 0;
  }
}

@media (width <= 640px) {
  .wmBox_centerer {
    width: 100%;
    height: 100%;
    margin: 0;
  }
}

.wmBox_contentWrap {
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.wmBox_scaleWrap {
  width: 100%;
  height: 100%;
  position: relative;
}

@media (hover: none) and (pointer: coarse) {
  .wmBox_scaleWrap {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
  }
}

.wmBox_centerer iframe {
  background-color: #fff;
  border: 0;
  outline: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  overflow-y: auto;
}

.wmBox_closeBtn {
  z-index: 999;
  position: absolute;
  top: 25px;
  right: 25px;
}

@media (width <= 640px) {
  .wmBox_closeBtn {
    top: 20px;
    right: 20px;
  }
}

.wmBox_closeBtn p {
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  background: #006aa6;
  border: 1px solid #006aa6;
  min-width: 30px;
  margin: 0;
  padding: 0;
  font-family: Roboto Mono, monospace;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  transition: all .35s ease-out;
  display: inline-block;
}

@media (hover: hover) and (pointer: fine) {
  .wmBox_closeBtn p:hover {
    color: #282d30;
    background: #fff;
    border-color: #282d30;
  }
}

.popup {
  z-index: 9999;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  transform: translateX(-100%);
}

.popup.popup--opened {
  opacity: 1;
  pointer-events: all;
  animation-name: popup-fadeIn;
  animation-duration: .5s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  transform: translateX(0);
}

.popup.popup--closed {
  animation-name: popup-fadeOut;
  animation-duration: .5s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
}

@keyframes popup-fadeIn {
  from {
    opacity: 0;
    transform: translateX(0);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes popup-fadeOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(0);
  }
}

.popup-background {
  opacity: .75;
  background: #282d30;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.popup-outerwrapper {
  -ms-flex-flow: column;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 660px;
  height: 100%;
  margin: 0 auto;
  padding: 20px;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.popup-innerwrapper {
  background: #fff;
  width: 100%;
  margin: 0 auto;
  transition: transform .35s ease-out;
  position: relative;
  overflow: auto;
  transform: scale(.75);
}

.popup--opened .popup-innerwrapper {
  transform: scale(1);
}

.popup-closebutton {
  cursor: pointer;
  z-index: 2;
  color: #282d30;
  background: #fff;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 0;
  right: 0;
}

.popup-closebutton:before {
  content: "";
  transform-origin: 100% 100%;
  background: #fff;
  position: absolute;
  inset: 0;
  transform: skewY(-7.5deg);
}

.popup-closebutton i {
  position: relative;
}

.popup-opened > nav, .popup-opened > main {
  filter: blur(4px);
}

.popup-image {
  clip-path: polygon(0% 0%, 100% 0%, 100% 73.8%, 0% 100%);
  width: 100%;
  position: relative;
  overflow: hidden;
}

.popup-image:after {
  content: "";
  transform-origin: 0 0;
  background: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  transform: skewY(-7.5deg);
}

.popup-image img {
  display: block;
}

.popup-text {
  padding: 25px;
}

.creator {
  width: 100%;
}

.creator-wrapper {
  text-align: left;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 0 25px 50px;
}

@media (width >= 1601px) {
  .creator-wrapper {
    max-width: 850px;
    padding: 0 0 100px;
  }
}

@media (width <= 640px) {
  .creator-wrapper {
    padding: 0 0 50px;
  }
}

.creator-bonus-wrapper {
  position: relative;
}

.creator-bonus-button {
  display: none;
}

.creator-bonus {
  z-index: 2;
  background: #fff;
  border: 1px solid #282d30;
  position: relative;
}

.creator-bonus .creator-element-info {
  display: none;
}

.creator-heading + .creator-bonus-wrapper .creator-bonus:not(.fixed) {
  border-top: none;
}

.creator-bonus:not(.current):not(.fixed) {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.creator-bonus.current {
  z-index: 3;
}

.creator-bonus.fixed {
  z-index: 3;
  border: none;
  border-top: 1px solid #282d30;
  width: 100%;
  margin-bottom: 0;
  transition: transform .4s ease-out 1s, background .4s ease-out;
  position: fixed;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
}

.creator-bonus.fixed .creator-element-info {
  display: inline-block;
}

.creator-bonus.fixed .creator-bonus-button {
  border-top: 1px solid #282d30;
  display: block;
}

.creator-bonus.fixed .creator-bonus-button .button {
  display: block;
}

.creator-bonus.fixed.current {
  z-index: 4;
  transform: translateY(0);
}

.creator-element {
  max-width: 850px;
  line-height: 30px;
  transition: all .4s ease-out;
  display: -ms-flexbox;
  display: flex;
}

@media (width >= 641px) {
  .creator-element:nth-of-type(2n) {
    background: #ddd;
  }
}

@media (width <= 640px) {
  .creator-element {
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.creator-element s {
  color: gray;
  text-decoration: none;
  display: inline;
}

.creator-bonus .creator-element {
  background: none;
  margin: 0 auto;
}

.creator-bonus.active .creator-element {
  color: #fff;
  opacity: .75;
  background: #006aa6;
}

.creator-bonus.active .creator-element s {
  display: none;
}

.creator-progress {
  background: #ddd;
  border-bottom: 1px solid #282d30;
  width: 100%;
  max-width: 850px;
  height: 25px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.creator-progress:before {
  content: "";
  width: var(--progress);
  transform-origin: 0 100%;
  background: #006aa6;
  height: 25px;
  transition: all .4s ease-out;
  position: absolute;
  bottom: 0;
  left: -25px;
  transform: skewX(-45deg);
}

.creator-progress:after {
  text-transform: uppercase;
  letter-spacing: 2px;
  content: "Gesamtsumme " attr(data-current) "€";
  color: #fff;
  text-shadow: 0 0 20px #006aa6;
  width: 100%;
  height: 25px;
  padding: 5px;
  font-family: Roboto Mono, monospace;
  font-size: 1rem;
  font-weight: normal;
  line-height: 15px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.creator-element-title {
  width: 50%;
  margin: 0;
  padding: 5px;
}

@media (width <= 640px) {
  .creator-element-title {
    width: 100%;
  }
}

.creator-element-subtitle {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #006aa6;
  padding-top: 5px;
  font-family: Roboto Mono, monospace;
  font-size: 13px;
  font-weight: normal;
  line-height: 20px;
  display: block;
}

.creator-element-data {
  width: 50%;
  margin: 0;
}

.creator-element-data table {
  width: 100%;
  height: 100%;
  margin: 0;
}

@media (width <= 640px) {
  .creator-element-data {
    background: #ddd;
    width: 100%;
  }

  .creator-bonus.fixed .creator-element-data {
    display: none;
  }
}

@media (width <= 1024px) {
  .creator-bonus .creator-element-data td:first-child {
    text-align: left;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 0;
    line-height: 1.4;
    display: block;
  }

  .creator-bonus .creator-element-data td:last-child {
    text-align: left;
    width: 100%;
    padding-top: 0;
    padding-bottom: 5px;
    line-height: 1.4;
    display: block;
  }
}

.creator-element-info {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
  text-align: center;
  text-transform: none;
  background-color: #006aa6;
  width: 20px;
  height: 20px;
  padding-left: 2px;
  font-family: Roboto Mono, monospace;
  font-size: 1rem;
  font-weight: normal;
  line-height: 20px;
  display: inline-block;
  position: relative;
}

.creator-element-info:hover {
  box-shadow: 0 0 3px 1px #006aa6;
}

.creator-element-info:before {
  content: "";
  transform-origin: 100% 100%;
  background: #006aa6;
  position: absolute;
  inset: 0;
  transform: skewY(-7.5deg);
}

.creator-element-info span {
  position: relative;
}

.creator-input {
  text-transform: uppercase;
  letter-spacing: 2px;
  border: 4px solid #006aa6;
  width: 54px;
  height: 30px;
  padding: 0 0 0 4px;
  font-family: Roboto Mono, monospace;
  font-size: 1rem;
  font-weight: bold;
  line-height: 30px;
}

.creator-number {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: Roboto Mono, monospace;
  font-size: 1rem;
  font-weight: bold;
}

.creator-switch {
  width: 54px;
  height: 30px;
  display: inline-block;
  position: relative;
}

.creator-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.creator-switch .creator-switch-slider {
  cursor: pointer;
  background-color: #ddd;
  border: 4px solid #bbb;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.creator-switch .creator-switch-slider:before {
  text-transform: uppercase;
  letter-spacing: 2px;
  content: "0";
  text-align: center;
  color: #ddd;
  background-color: #fff;
  width: 22px;
  height: 22px;
  font-family: Roboto Mono, monospace;
  font-size: 1rem;
  font-weight: bold;
  line-height: 22px;
  transition: all .4s;
  position: absolute;
  bottom: 0;
  left: 0;
}

.creator-switch .creator-switch-slider.shake {
  backface-visibility: hidden;
  perspective: 1000px;
  animation: .82s cubic-bezier(.36, .07, .19, .97) both shake;
  transform: translate3d(0, 0, 0);
}

.creator-switch input:checked + .creator-switch-slider {
  background-color: #006aa6;
  border: 4px solid #006aa6;
}

.creator-switch input:focus + .creator-switch-slider {
  box-shadow: 0 0 1px #006aa6;
}

.creator-switch input:invalid + .creator-switch-slider {
  outline: 2px solid red;
}

.creator-switch input:invalid + .creator-switch-slider:after {
  content: "required";
  color: red;
  text-align: center;
  width: 100%;
  font-size: 13px;
  line-height: 13px;
  position: absolute;
  bottom: 5px;
  left: 0;
}

.creator-switch input:checked + .creator-switch-slider:before {
  content: "1";
  color: #006aa6;
  transform: translateX(22px);
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.creator-radio {
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
  display: inline-block;
  position: relative;
}

.creator-radio input {
  opacity: 0;
  width: 0;
  height: 0;
}

.creator-radio .creator-radio-button {
  cursor: pointer;
  background-color: #ddd;
  border: 4px solid #bbb;
  border-radius: 50%;
  transition: all .35s ease-in;
  position: absolute;
  inset: 0;
}

.creator-radio input:checked + .creator-radio-button {
  background-color: #69a700;
  border: 4px solid #fff;
}

.hover .creator-radio .creator-radio-button {
  border: 4px solid #006aa6;
}

.creator-heading {
  border-top: 1px solid #282d30;
  border-bottom: 1px solid #282d30;
  max-width: 850px;
  transition: all .4s ease-out;
  display: -ms-flexbox;
  display: flex;
}

@media (width <= 640px) {
  .creator-heading {
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.creator-heading + .creator-heading, .creator-bonus-wrapper + .creator-heading {
  border-top: none;
}

.creator-heading-title {
  text-transform: uppercase;
  letter-spacing: 2px;
  width: 50%;
  padding: 25px 0;
  font-family: Roboto Mono, monospace;
  font-size: 24px;
  font-weight: bold;
  line-height: 24px;
}

.creator-heading-title span:nth-of-type(2) {
  color: #006aa6;
}

@media (width <= 640px) {
  .creator-heading-title {
    padding: 15px 5px;
  }
}

.creator-heading-data {
  align-items: center;
  width: 50%;
  margin: 0;
  font-weight: bold;
  display: -ms-flexbox;
  display: flex;
}

.creator-heading-data table {
  width: 100%;
  height: 100%;
  margin: 0;
}

@media (width <= 640px) {
  .creator-heading-data {
    width: 100%;
  }

  .creator-heading-data table {
    border-top: 1px solid #282d30;
    height: 30px;
    line-height: 30px;
  }

  .creator-heading-data p {
    padding: 0 5px 15px;
  }
}

.creator-heading-subtitle {
  color: #006aa6;
  width: 100%;
  padding: 10px 0;
}

.creator-submit-wrapper {
  text-align: center;
  margin-top: 50px;
}

[data-activepackage="true"] {
  color: #fff;
  background: #006aa6;
}

[data-activepackage="false"] {
  cursor: pointer;
  transition: all .35s ease-in;
}

[data-activepackage="false"].hover {
  color: #fff;
  background: #69a700;
}

.creator-servicebox {
  z-index: 5;
  background: #fff;
  justify-content: center;
  width: 100%;
  padding: 5px 0;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0 -3px 3px #00000080;
}

.creator-servicebox > * {
  width: 100%;
}

.doc {
  width: 100%;
  max-width: calc(900px + 33.3333%);
  margin: 0 auto;
  padding: 0 40px 50px calc(33.3333% + 40px);
  position: relative;
}

@media (width <= 640px) {
  .doc {
    padding: 50px 20px;
  }
}

@media (width >= 2001px) {
  .doc {
    max-width: 1500px;
    padding: 0 40px 50px 640px;
  }
}

.doc .logo {
  z-index: 9;
  width: 150px;
  height: 30px;
  display: none;
  position: fixed;
  top: 20px;
  left: 20px;
}

@media (width <= 640px) {
  .doc .logo {
    display: block;
  }
}

.doc .logo .logo-primcolor {
  fill: #006aa6;
}

.doc .logo .logo-seccolor {
  fill: #fff;
}

.doc figure {
  text-align: center;
  margin: 50px 0;
}

@media (width <= 1024px) {
  .doc figure {
    margin: 25px 0;
  }
}

.doc img {
  max-width: auto;
  width: auto;
  max-height: calc(80vh - 100px);
  display: inline-block;
  box-shadow: 0 0 3px #00000080;
}

@media (width <= 1024px) {
  .doc img {
    max-height: calc(80vh - 70px);
  }
}

.doc pre {
  background: #ddd;
  width: 100%;
  margin: 1em 0;
  padding: 8px;
  overflow-x: auto;
}

.doc code {
  color: #006aa6;
  background: #ddd;
  border-radius: 3px;
  width: 100%;
  padding: 1px 4px;
  font-family: Roboto Mono, monospace;
  font-size: 16px;
}

.doc .table-wrapper {
  width: 100%;
  margin: 1em 0;
  overflow-x: auto;
}

.doc table {
  border-collapse: collapse;
  border: 1px solid #ddd;
  width: 100%;
}

.doc th, .doc td {
  border: 1px solid #ddd;
  padding: 8px;
  line-height: 22px;
}

.doc th {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
  text-align: left;
  background: #006aa6;
  font-family: Roboto Mono, monospace;
  font-size: 13px;
  font-weight: normal;
}

.doc blockquote {
  color: #fff;
  background: #006aa6;
  margin: 1em 0;
  padding: 25px;
}

.doc h4 {
  margin-top: 2em;
}

.doc h4 + blockquote {
  margin-top: -.875rem;
}

.video {
  background: #00000012;
  border: 10px solid #006aa6;
  width: 100%;
  max-width: calc(142.222vh - 177.778px);
  margin: 0 auto;
  position: relative;
}

.container-wrapper--color .video {
  background: #ffffff80;
  border-color: #fff;
}

@media (width <= 1024px) {
  .video {
    max-width: calc(142.222vh - 124.444px);
  }
}

.video-wrapper {
  pointer-events: none;
  z-index: 2;
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
}

.video-wrapper iframe {
  pointer-events: all;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.video-consent {
  text-align: center;
  color: #000;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.video-consent > div {
  max-height: 100%;
  margin: auto;
  padding: 50px;
  overflow-y: auto;
}

@media (width <= 640px) {
  .video-consent > div {
    padding: 25px;
  }
}

.doc-nav {
  z-index: 10;
  color: #fff;
  background-color: #006aa6;
  width: 33%;
  height: 100%;
  padding: 25px;
  transition: all .35s ease-out;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
}

@media (width >= 2001px) {
  .doc-nav {
    width: 600px;
    padding: 50px;
  }
}

@media (width <= 640px) {
  .doc-nav {
    width: calc(100% - 40px);
    padding-bottom: 80px;
    transform: translateX(-100%);
  }

  .doc-nav.doc-nav--opened {
    transform: translateX(0);
  }
}

.doc-nav .logo {
  width: 150px;
  height: 30px;
  display: block;
}

.doc-nav .logo .logo-primcolor {
  fill: #282d30;
}

.doc-nav .logo .logo-seccolor {
  fill: #fff;
}

.doc-nav ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.doc-nav ul li {
  padding: 5px 0;
}

.doc-nav ul li span {
  color: #282d30;
  font-family: Roboto Mono, monospace;
  font-size: 13px;
  font-weight: bold;
}

.doc-nav ul li a {
  color: #fff;
  font-weight: normal;
  text-decoration: none;
}

.doc-nav ul li a:hover {
  text-decoration: underline;
}

.doc-nav ul li a:hover span {
  text-decoration: none;
}

.doc-nav ul ul {
  padding-left: 25px;
}

.doc-nav > div > ul > li {
  padding-bottom: 15px;
}

.doc-nav > div > ul > li:last-child {
  padding-bottom: 0;
}

.doc-nav-icon {
  z-index: 10;
  cursor: pointer;
  color: #fff;
  background-color: #006aa6;
  padding: 10px 20px;
  font-weight: bold;
  transition: all .35s ease-out;
  position: fixed;
  bottom: 20px;
  left: 0;
}

.doc-nav-icon > * {
  vertical-align: middle;
}

@media (width >= 641px) {
  .doc-nav-icon {
    display: none;
  }
}

.doc-nav-icon .material-icons:first-child {
  display: none;
}

.doc-nav--opened + .doc-nav-icon {
  color: #282d30;
  background-color: #fff;
  border: 1px solid #282d30;
}

.doc-nav--opened + .doc-nav-icon .material-icons:first-child {
  display: inline;
}

.doc-nav--opened + .doc-nav-icon .material-icons:nth-child(2) {
  display: none;
}

.doc-content {
  padding-top: 100px;
}

.doc-content[data-depth="2"] {
  padding-top: 50px;
}

.doc-content[data-depth="3"], .doc-content[data-depth="4"], .doc-content[data-depth="5"] {
  padding-top: 25px;
}

@media (width <= 1024px) {
  .doc-content {
    padding-top: 70px;
  }

  .doc-content[data-depth="2"] {
    padding-top: 25px;
  }
}

@media (width <= 640px) {
  .doc-content {
    padding-top: 50px;
  }

  .doc-content[data-depth="2"] {
    padding-top: 25px;
  }
}

.doc-content ul {
  padding-left: 2em;
  list-style-type: none;
}

.doc-content ul li:before {
  content: "•";
  color: #006aa6;
  width: 1em;
  margin-left: -1em;
  font-size: 1.25em;
  font-weight: bold;
  line-height: 1.12em;
  display: inline-block;
}

.doc-content h4 {
  font-size: 1rem;
}

.u-clearfix:after {
  content: "" !important;
  clear: both !important;
  display: block !important;
}

.u-hidden-visually {
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.u-hidden {
  display: none !important;
}

.u-flex-vcenter {
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  display: -ms-flexbox;
  display: flex;
}

.u-flex-hcenter {
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.u-flex-stretch {
  align-items: stretch;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

.u-text-justify {
  text-align: justify;
}

@media (width <= 1024px) {
  .u-text-justify {
    text-align: left;
  }
}

.u-nomargin {
  margin: 0 !important;
}

.u-nomargin-top {
  margin-top: 0 !important;
}

.u-nomargin-bottom {
  margin-bottom: 0 !important;
}

.u-nomargin-left {
  margin-left: 0 !important;
}

.u-nomargin-right {
  margin-right: 0 !important;
}

.u-nopadding {
  padding: 0 !important;
}

.u-nopadding-top {
  padding-top: 0 !important;
}

.u-nopadding-bottom {
  padding-bottom: 0 !important;
}

.u-nopadding-left {
  padding-left: 0 !important;
}

.u-nopadding-right {
  padding-right: 0 !important;
}

.u-video {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
}

.u-video iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width >= 0) and (width <= 420px) {
  .u-hide-xsmall {
    display: none;
  }
}

@media (width <= 420px) {
  .u-hide-xsmall-down {
    display: none;
  }
}

@media (width >= 421px) {
  .u-hide-xsmall-up {
    display: none;
  }
}

@media (width >= 420px) and (width <= 640px) {
  .u-hide-small {
    display: none;
  }
}

@media (width <= 640px) {
  .u-hide-small-down {
    display: none;
  }
}

@media (width >= 641px) {
  .u-hide-small-up {
    display: none;
  }
}

@media (width >= 640px) and (width <= 1024px) {
  .u-hide-medium {
    display: none;
  }
}

@media (width <= 1024px) {
  .u-hide-medium-down {
    display: none;
  }
}

@media (width >= 1025px) {
  .u-hide-medium-up {
    display: none;
  }
}

@media (width >= 1024px) and (width <= 1600px) {
  .u-hide-large {
    display: none;
  }
}

@media (width <= 1600px) {
  .u-hide-large-down {
    display: none;
  }
}

@media (width >= 1601px) {
  .u-hide-large-up {
    display: none;
  }
}

@media (width >= 1600px) and (width <= 2000px) {
  .u-hide-xlarge {
    display: none;
  }
}

@media (width <= 2000px) {
  .u-hide-xlarge-down {
    display: none;
  }
}

@media (width >= 2001px) {
  .u-hide-xlarge-up {
    display: none;
  }
}

.u-color-corporate {
  color: #006aa6;
}

.u-bgcolor-corporate {
  background-color: #006aa6;
}

.u-color-darkgrey {
  color: #282d30;
}

.u-bgcolor-darkgrey {
  background-color: #282d30;
}

.u-color-grey {
  color: gray;
}

.u-bgcolor-grey {
  background-color: gray;
}

.u-color-middlegrey {
  color: #bbb;
}

.u-bgcolor-middlegrey {
  background-color: #bbb;
}

.u-color-lightgrey {
  color: #ddd;
}

.u-bgcolor-lightgrey {
  background-color: #ddd;
}

.u-color-white {
  color: #fff;
}

.u-bgcolor-white {
  background-color: #fff;
}

.u-color-black {
  color: #000;
}

.u-bgcolor-black {
  background-color: #000;
}

.u-color-green {
  color: #69a700;
}

.u-bgcolor-green {
  background-color: #69a700;
}

.u-color-red {
  color: #a72b00;
}

.u-bgcolor-red {
  background-color: #a72b00;
}

.u-color-orange {
  color: #a7a700;
}

.u-bgcolor-orange {
  background-color: #a7a700;
}

.u-blend {
  mix-blend-mode: difference;
}

.u-normal-weight {
  font-weight: normal;
}

.u-bold-weight {
  font-weight: bold;
}
/*# sourceMappingURL=app.css.map */
